import React, { Fragment } from 'react';
import { CreateAgreementForm } from './CreateAgreementForm';
import { PageHeader } from '../PageHeader';

export class CreateAgreementPage extends React.Component {

    render() {
        return (
            <Fragment>
                <PageHeader title="Crear acuerdo de cliente" text="Esta página permite crear un acuerdo de cliente" />
                <p>
                    <CreateAgreementForm auth={this.props.auth} handlerLoading={this.props.handlerLoading} setStatus={this.props.setStatus} history={this.props.history}></CreateAgreementForm>
                </p>
            </Fragment>
        );
    }
}