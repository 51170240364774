import React, { Fragment } from 'react';
import CreateAddonStepper from './CreateAddonStepper'
import { matchPath } from 'react-router';
import { PageHeader } from '../PageHeader';

export class UpdateAddOnPage extends React.Component {

    getParams = pathname => {
        const matchProfile = matchPath(pathname, {
            path: `/addons/update/:addonUuid`,
        });
        return (matchProfile && matchProfile.params) || {};
    };

    render() {
        var params = this.getParams(this.props.history.location.pathname);
        return (
            <Fragment>
                <PageHeader title="Modificar AddOn" text="Esta página permite modificar un AddOn" />
                <CreateAddonStepper uuid={params.addonUuid} history={this.props.history} auth={this.props.auth} handlerLoading={this.props.handlerLoading} setStatus={this.props.setStatus}>}</CreateAddonStepper>
            </Fragment>
        );
    }
}