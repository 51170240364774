import React, { Fragment } from 'react';
import { CreateAttributeTypeForm } from './CreateAttributeTypeForm'
import { PageHeader } from '../PageHeader';

export class AttributeTypeAddPage extends React.Component {

    render() {

        return (
            <Fragment>
            <PageHeader title="Crear nueva lista de valores" text="Esta página permite crear una lista de valores" />
                <CreateAttributeTypeForm auth={this.props.auth} history={this.props.history} uuid="" handlerLoading={this.props.handlerLoading} setStatus={this.props.setStatus}></CreateAttributeTypeForm>
            </Fragment>
        );
    }
}