import React, { Fragment } from 'react';
import CreateAddonStepper from './CreateAddonStepper';
import { PageHeader } from '../PageHeader';

export class CreateAddOnPage extends React.Component {

    render() {
        return (
            <Fragment>
                <PageHeader title="Crear nuevo AddOn" text="Esta página permite crear un nuevo AddOn" />   
                <CreateAddonStepper auth={this.props.auth} history={this.props.history} handlerLoading={this.props.handlerLoading} setStatus={this.props.setStatus}></CreateAddonStepper>
            </Fragment>
        );
    }
}