import React, { Fragment } from 'react';
import { CreateLegalNewsForm } from './CreateLegalNewsForm';
import { PageHeader } from '../PageHeader';

export class CreateLegalNewsPage extends React.Component {

    render() {
        return (
            <Fragment>
                <PageHeader title="Crear noticia legal" text="Esta página permite crear una noticia legal" />
                <p>
                    <CreateLegalNewsForm auth={this.props.auth} handlerLoading={this.props.handlerLoading} setStatus={this.props.setStatus} history={this.props.history}></CreateLegalNewsForm>
                </p>
            </Fragment>
        );
    }
}