import React, { Fragment } from 'react';
import { CreateLegalNewsForm } from './CreateLegalNewsForm';
import { matchPath } from 'react-router';
import { PageHeader } from '../PageHeader';

export class UpdateLegalNewsPage extends React.Component {

    getParams = pathname => {
        const matchProfile = matchPath(pathname, {
            path: `/legalnews/update/:legalNewsUuid`
        });
        return (matchProfile && matchProfile.params) || {};
    };

    render() {
        var params = this.getParams(this.props.history.location.pathname);
        return (
            <Fragment>
                <PageHeader title="Modificar noticia legal" text="Esta página permite modificar una noticia legal" />
                <p>
                    <CreateLegalNewsForm uuid={params.legalNewsUuid} auth={this.props.auth} handlerLoading={this.props.handlerLoading} setStatus={this.props.setStatus} history={this.props.history}></CreateLegalNewsForm>
                </p>
            </Fragment>
        );
    }
}