import React, { Fragment } from 'react';
import handleResponse from '../.././functions/handleResponse'
import handleDiagnosis from '../.././functions/handleDiagnosis'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '../Button';
import Grid from '@material-ui/core/Grid';
import { formatQuery } from "react-querybuilder";
import SelectAutoComplete from '../../functions/SelectAutoComplete';
import { RulesQueryBuilder } from '.././RulesQueryBuilder';
import * as queryBuilderFunctions from '.././RulesQueryBuilder';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { CreateDialog } from '../Data/CreateDialog';
import AddIcon from '@material-ui/icons/Add';

const imputTextStyle = {
    width: '90%'
};

const labelStyle = {
    marginTop: 15
};

export class CreateLegalNewsForm extends React.Component {
    constructor(props) {
        super(props);
        this.fetchData = this.fetchData.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onAdded = this.onAdded.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.onSelectCollection = this.onSelectCollection.bind(this);
        this.handleQueryChange = this.handleQueryChange.bind(true);
        this.handleOpen = this.handleOpen.bind(true);
        this.handleClose = this.handleClose.bind(true);
        this.onSelectTipoLegalNews = this.onSelectTipoLegalNews.bind(this);
        this.onSelectAffectedMatters = this.onSelectAffectedMatters.bind(this);

        this.state = {
            legalnews: {
                uuid: '',
                title: '',
                type: '',
                affected_matter: '',
                description: '',
                url: '',
                collection_uuid: '',
                legalnews_rule: '',
                legalnews_rule_query: '',
                valid_from: new Date(new Date().setSeconds(0,0)),
                valid_to: new Date(new Date().setSeconds(0,0)),
                query: null
            },
            collections: [],
            attributesList: [],
            attributeTypesList: [],
            tiposLegalNewsList: [
                { value: "", label: "--[ninguno]--" },
                { value: "Cambio legislativo", label: "Cambio legislativo" },
                { value: "Informativo", label: "Informativo" },
                { value: "Novedad", label: "Novedad"}
            ],
            affectedMattersList: [
                { value: "", label: "--[ninguno]--" },
                { value: "Contabilidad", label: "Contabilidad" },
                { value: "Cret@", label: "Cret@" },
                { value: "Empresa-Entorno", label: "Empresa-Entorno" },
                { value: "Estimaciones", label: "Estimaciones" },
                { value: "IVA/IGIC", label: "IVA/IGIC" },
                { value: "LOPD", label: "LOPD" },
                { value: "Modelos oficiales", label: "Modelos oficiales" },
                { value: "Certificación software", label: "Certificación software" },
                { value: "Cuentas anuales", label: "Cuentas anuales" },
                { value: "Datos censales", label: "Datos censales" },
                { value: "DELT@", label: "DELT@" },
                { value: "INSS", label: "INSS" },
                { value: "Protección de Datos", label: "Protección de Datos" },
                { value: "PSD2", label: "PSD2" },
                { value: "SEPE", label: "SEPE" },
                { value: "SII", label: "SII" },
                { value: "SLD", label: "SLD" },
                { value: "Afiliación", label: "Afiliación" }
            ],
            open: false
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        this.props.handlerLoading(true);
        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 3;

        fetch("/api/collections",
            {
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, false))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.setCollectionsData(response))
            .catch(error => console.log(error));
    }

    setCollectionsData = (products) => {
        var collectionsList = products.map(product => ({
            value: product.uuid,
            label: product.name
        }));
        this.setState({ collections: collectionsList });

        this.fetchAttributes();
    }

    fetchAttributes() {
        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 3;

        fetch("/api/attributes?scope=recommendation",
            {
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, false))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.setAttributesData(response))
            .catch(error => console.log(error));
    }

    setAttributesData = (attributes) => {

        var attributesList = attributes.map(attribute => ({
            name: attribute.name,
            label: attribute.name,
            id: attribute.uuid,
            attribute_type_uuid: attribute.attribute_type_uuid
        }));

        this.setState({ attributesList: attributesList });

        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 3;

        fetch("/api/attributetypes",
            {
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, false))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.setAttributeTypesData(response))
            .catch(error => console.log(error));
    }

    setAttributeTypesData = (attributeTypes) => {
        this.setState({ attributeTypesList: attributeTypes });
        this.fetchLegalNewsData();
    }

    fetchLegalNewsData = () => {
        if (this.props.uuid) {
            const accessToken = this.props.auth.getAccessToken();
            var setStatusOn200 = 3;

            fetch("/api/legalnews/" + this.props.uuid,
                {
                    headers: new Headers({
                        "Accept": "application/json",
                        "Authorization": `Bearer ${accessToken}`
                    })
                })
                .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, true))
                .then(response => response.json())
                .then(response => response.diagnosis
                    ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading)
                    : this.setData(response))
                .catch(error => console.log(error));
        }
        else
            this.props.handlerLoading(false);
    }

    setData = (legalnews) => {
        legalnews.valid_from = legalnews.valid_from != null ? new Date(new Date(legalnews.valid_from).setSeconds(0,0)) : null;
        legalnews.valid_to = legalnews.valid_to != null ? new Date(new Date(legalnews.valid_to).setSeconds(0,0)) : null;

        if (legalnews.legalnews_rule_query && legalnews.legalnews_rule_query !== null && legalnews.legalnews_rule_query !== "") {
            legalnews.query = JSON.parse(legalnews.legalnews_rule_query);
        } else {
            legalnews.query = null;
        }

        if (!legalnews.legalnews_rule) {
            legalnews.legalnews_rule = "";
        }

        if (!legalnews.url) {
            legalnews.url = "";
        }

        this.setState({ legalnews: legalnews });
    }

    handleSubmit = (event) => {
        this.props.handlerLoading(true);
        const accessToken = this.props.auth.getAccessToken();
        const self = this;

        if (!this.isValidDate(self.state.legalnews.valid_from, true) ||
            !this.isValidDate(self.state.legalnews.valid_to, true)) {
            this.props.handlerLoading(false);
            return false;
        }

        if (self.state.legalnews.collection_uuid === '') {
            this.props.handlerLoading(false);
            return false;
        }

        if (self.state.legalnews.legalnews_rule) {
            if (self.state.legalnews.legalnews_rule === "()") {
                let legalnews = self.state.legalnews;
                legalnews.legalnews_rule = "";
                legalnews.legalnews_rule_query = null;
                this.setState({ legalnews: legalnews });
            }
            else if (self.state.legalnews.legalnews_rule.includes("()")) {
                this.props.setStatus("error", 'No puede haber grupos sin reglas.');
                this.props.handlerLoading(false);
                return false;
            }
            else if (self.state.legalnews.legalnews_rule.includes(" \"\"") || self.state.legalnews.legalnews_rule.includes("== &&") || self.state.legalnews.legalnews_rule.includes("== ||") || self.state.legalnews.legalnews_rule.includes("==)")) {
                this.props.setStatus("error", 'Todas las reglas deben tener un valor.');
                this.props.handlerLoading(false);
                return false;
            }
        }

        var method = 'POST';
        var url = '/api/legalnews';
        var setStatusOn200 = 0;

        if (self.state.legalnews.uuid && self.state.legalnews.uuid !== '' && self.state.legalnews.uuid !== null) {
            method = 'PUT';
            url = '/api/legalnews/' + self.state.legalnews.uuid;
            setStatusOn200 = 1;
        }

        fetch(url,
            {
                method: method,
                headers: new Headers({
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                }),
                body: JSON.stringify(self.state.legalnews)
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, false))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : (this.onAdded(response), this.props.handlerLoading(false)))
            .catch(error => console.log(error));
    }

    isValidDate = (d, validateNull) => {
        if (!validateNull) {
            return d == null || (d instanceof Date && !isNaN(d));
        } else {
            return d instanceof Date && !isNaN(d);
        }
    }

    onAdded = (legalnews) => {
        this.props.history.push('/legalnews');
    }

    handleChange = (event) => {
        let legalnews = this.state.legalnews;
        var newValue = event.target.value;
        legalnews[event.target.name] = newValue;
        this.setState({ legalnews: legalnews });
    }

    handleDateChange = (date, field) => {
        let legalnews = this.state.legalnews;
        var newValue = date == null ? null : date._d;

        if (newValue === null)
            this.setState({ [field + '_error']: 'Este campo es obligatorio' });
        else if (!this.isValidDate(newValue, false))
            this.setState({ [field + '_error']: 'Formato de fecha no válido' });
        else
            this.setState({ [field + '_error']: '' });

        legalnews[field] = newValue;
        this.setState({ legalnews: legalnews });
}

    handleQueryChange = (query) => {
        let legalnews = this.state.legalnews;

        legalnews.query = query;

        const valueProcessor = queryBuilderFunctions.default(this.state.attributeTypesList, this.state.attributesList);

        var formatedQuery = this.state.legalnews.query != null ? formatQuery(this.state.legalnews.query, { format: 'sql', valueProcessor }) : '';
        var formatedQueryRaw = this.state.legalnews.query != null ? formatQuery(this.state.legalnews.query, { format: 'JSON' }) : '';

        legalnews.legalnews_rule = formatedQuery;
        legalnews.legalnews_rule_query = formatedQueryRaw;
        this.setState({ legalnews: legalnews });
    };

    onSelectCollection(collectionUuid) {
        var legalnews = this.state.legalnews;
        legalnews.collection_uuid = collectionUuid;
        this.setState({ legalnews: legalnews });
    }

    handleOpen = () => {
        this.setState({ open: true });
    }

    handleClose = (response) => {

        if (response) {
            var newCollection =
            {
                value: response.uuid,
                label: response.name
            }
            let collections = this.state.collections;
            let legalnews = this.state.legalnews;
            collections.push(newCollection);
            legalnews.collection_uuid = response.uuid;
            this.setState({ collections: collections, legalnews: legalnews });
        }
        this.setState({ open: false });
    }

    onSelectTipoLegalNews(tipoLegalNews) {
        var legalNews = this.state.legalnews;
        legalNews.type = tipoLegalNews;
        this.setState({ legalnews: legalNews });
    }

    onSelectAffectedMatters(affectedMatter) {
        var legalNews = this.state.legalnews; 
        legalNews.affected_matter = affectedMatter;
        this.setState({ legalnews: legalNews });
    }

    render() {
        return (
            <Fragment>
                <div className="content">
                    <CreateDialog
                        open={this.state.open}
                        handleClose={this.handleClose}
                        handleOnAdded={this.handleClose}
                        entity={{ name: 'colección', type: 'collections' }}
                        handlerLoading={this.props.handlerLoading}
                        setStatus={this.props.setStatus}
                        auth={this.props.auth}
                    >
                    </CreateDialog>
                    <ValidatorForm
                        ref="form"
                        onSubmit={this.handleSubmit}
                        onError={errors => console.log(errors)}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={2} wrap="nowrap">
                                <label style={labelStyle}>Título</label>
                            </Grid>
                            <Grid item xs={10}>
                                <TextValidator
                                    style={imputTextStyle}
                                    margin="dense"
                                    variant="outlined"
                                    label="Título"
                                    name="title"
                                    value={this.state.legalnews.title}
                                    onChange={this.handleChange}
                                    validators={['required', 'maxStringLength: 128', 'trim']}
                                    errorMessages={['Este campo es obligatorio', 'La longitud máxima de este campo es de 128 caracteres', 'Este campo es obligatorio']}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <label style={labelStyle}>Tipo</label>
                            </Grid>
                            <Grid item xs={5}>
                                <SelectAutoComplete
                                    uuid={this.state.legalnews.type}
                                    suggestions={this.state.tiposLegalNewsList}
                                    onSelectSuggestion={this.onSelectTipoLegalNews}
                                    placeholder="Tipo"
                                    label="Tipo"
                                    allowBlankOptions={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <label style={labelStyle}>Materias afectadas</label>
                            </Grid>
                            <Grid item xs={5}>
                                <SelectAutoComplete
                                    uuid={this.state.legalnews.affected_matter}
                                    suggestions={this.state.affectedMattersList}
                                    onSelectSuggestion={this.onSelectAffectedMatters}
                                    placeholder="Materias afectadas"
                                    label="Materias afectadas"
                                    allowBlankOptions={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <label style={labelStyle}>Descripción</label>
                            </Grid>
                            <Grid item xs={10}>
                                <TextValidator
                                    style={imputTextStyle}
                                    margin="dense"
                                    variant="outlined"
                                    label="Descripción"
                                    name="description"
                                    value={this.state.legalnews.description}
                                    onChange={this.handleChange}
                                    validators={['required', 'maxStringLength: 256', 'trim']}
                                    errorMessages={['Este campo es obligatorio', 'La longitud máxima de este campo es de 256 caracteres', 'Este campo es obligatorio']}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <label style={labelStyle}>Url</label>
                            </Grid>
                            <Grid item xs={10}>
                                <TextValidator
                                    style={imputTextStyle}
                                    margin="dense"
                                    variant="outlined"
                                    label="Url"
                                    name="url"
                                    value={this.state.legalnews.url}
                                    onChange={this.handleChange}
                                    validators={['maxStringLength: 512']}
                                    errorMessages={['La longitud máxima de este campo es de 512 caracteres']}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} style={{ paddingBottom: '25px' }}>
                            <Grid item xs={2}>
                                <label style={labelStyle}>Colección</label>
                            </Grid>
                            <Grid item xs={5}>
                                <SelectAutoComplete
                                    uuid={this.state.legalnews.collection_uuid}
                                    suggestions={this.state.collections}
                                    onSelectSuggestion={this.onSelectCollection}
                                    placeholder="Colección"
                                    label="Colección" />
                            </Grid>
                            <Grid item xs={1}>
                                <Button onClick={this.handleOpen} variant="contained" className="selectbutton">
                                    <AddIcon />
                                </Button>
                            </Grid>
                        </Grid>
                        <Fragment>
                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <label style={labelStyle}>Valida desde: </label>
                                </Grid>
                                <Grid item xs={10}>
                                    <KeyboardDateTimePicker
                                        variant="outlined"
                                        ampm={false}
                                        label="Válido desde"
                                        value={this.state.legalnews.valid_from}
                                        onChange={date => this.handleDateChange(date, "valid_from")}
                                        format="DD/MM/YYYY HH:mm"
                                        error={this.state.valid_from_error}
                                        helperText={this.state.valid_from_error !== '' ? this.state.valid_from_error : ''}
                                        invalidDateMessage="Formato de fecha no válido"

                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <label style={labelStyle}>Valida hasta: </label>
                                </Grid>
                                <Grid item xs={10}>
                                    <KeyboardDateTimePicker
                                        variant="outlined"
                                        ampm={false}
                                        label="Válido hasta"
                                        value={this.state.legalnews.valid_to}
                                        onChange={date => this.handleDateChange(date, "valid_to")}
                                        format="DD/MM/YYYY HH:mm"
                                        error={this.state.valid_to_error}
                                        helperText={this.state.valid_to_error !== '' ? this.state.valid_to_error : ''}
                                        invalidDateMessage="Formato de fecha no válido"

                                    />
                                </Grid>
                            </Grid>
                            <Grid className="rules-title" container spacing={1}>
                                <Grid item xs={12} wrap="nowrap">
                                    <h5><strong>Reglas para mostrar la Noticia Legal</strong>: aplicadas sobre los Atributos de datos del producto</h5>
                                    Si no se define ninguna regla la noticia legal no se mostrará en el cliente
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={11}>
                                    <RulesQueryBuilder
                                        query={this.state.legalnews.query}
                                        onQueryChange={this.handleQueryChange}
                                        fields={this.state.attributesList}
                                        fieldTypes={this.state.attributeTypesList}
                                    />
                                </Grid>
                            </Grid>
                        </Fragment>
                        <Fragment>
                            <Grid container spacing={1}>
                                <Grid item xs={11} className="text-right">
                                    <Button type="submit" variant="contained" color="primary"> Guardar</Button>
                                </Grid>
                            </Grid>
                        </Fragment >
                    </ValidatorForm>
                </div>
            </Fragment >
        );
    }
}