import React, { Fragment } from 'react';
import { CreateSageNewsForm } from './CreateSageNewsForm';
import { matchPath } from 'react-router';
import { PageHeader } from '../PageHeader';

export class UpdateSageNewsPage extends React.Component {

    getParams = pathname => {
        const matchProfile = matchPath(pathname, {
            path: `/sagenews/update/:sageNewsUuid`
        });
        return (matchProfile && matchProfile.params) || {};
    };

    render() {
        var params = this.getParams(this.props.history.location.pathname);
        return (
            <Fragment>
                <PageHeader title="Modificar noticia sage contigo" text="Esta página permite modificar una noticia sage contigo" />
                <p>
                    <CreateSageNewsForm uuid={params.sageNewsUuid} auth={this.props.auth} handlerLoading={this.props.handlerLoading} setStatus={this.props.setStatus} history={this.props.history}></CreateSageNewsForm>
                </p>
            </Fragment>
        );
    }
}