import React, { Fragment } from 'react';
import { CreateSageNewsForm } from './CreateSageNewsForm';
import { PageHeader } from '../PageHeader';

export class CreateSageNewsPage extends React.Component {

    render() {
        return (
            <Fragment>
                <PageHeader title="Crear noticia sage contigo" text="Esta página permite crear una noticia sage contigo" />
                <p>
                    <CreateSageNewsForm auth={this.props.auth} handlerLoading={this.props.handlerLoading} setStatus={this.props.setStatus} history={this.props.history}></CreateSageNewsForm>
                </p>
            </Fragment>
        );
    }
}