import React, { Fragment } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import Button from '../Button';
import handleResponse from '../.././functions/handleResponse';
import handleDiagnosis from '../.././functions/handleDiagnosis';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { PageHeader } from '../PageHeader';

export class AddOnsListPage extends React.Component {
    constructor(context) {
        super();
        this.state = {
            addonsList: [],
            open: false,
            cloneAddon: {
                uuid: '',
                name: ''
            }
        };
        this.context = context;
        this.fetchData = this.fetchData.bind(this);
        this.onTableRowClick = this.onTableRowClick.bind(this);
        this.handleCopy = this.handleCopy.bind(this);
        this.clone = this.clone.bind(this);
        this.handleClose = this.handleClose.bind(true);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        this.props.handlerLoading(true);
        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 3;

        fetch("/api/addons",
            {
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, true))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.setData(response))
            .catch(error => console.log(error));
    }

    setData(addons) {
        this.setState({ addonsList: addons });
    }

    onTableRowClick(event, rowData) {
        this.props.history.push('/addons/update/' + rowData.uuid);
    }

    renderCheckColum(isCheck) {
        if (isCheck) {
            return <Icon color="primary" style={{ width: '20px' }}>done</Icon>
        }
    }

    handleCopy = (uuid, name) => {
        var cloneAddon = {
            uuid: uuid,
            name: name + ' - Copia'
        }
        this.setState({ cloneAddon: cloneAddon, open: true });
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    handleChange = (event) => {
        let newValue = event.target.value;
        let cloneAddon = this.state.cloneAddon;
        cloneAddon[event.target.name] = newValue;
        this.setState({ cloneAddon: cloneAddon });
    }

    clone = () => {
        this.props.handlerLoading(true);
        this.handleClose();
        const accessToken = this.props.auth.getAccessToken();
        const self = this;
        var setStatusOn200 = 0;

        fetch('/api/addons/' + self.state.cloneAddon.uuid + '/clone',
            {
                method: 'POST',
                headers: new Headers({
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                }),
                body: JSON.stringify(self.state.cloneAddon)
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, true))
            .then(response => !response.ok ? response.json() : '')
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.fetchData())
            .catch(error => console.log(error));
    }    

    render() {        
        return (
            <Fragment>
                <PageHeader title="AddOns" text="Esta página muestra los AddOns creados" />
                <div className="content">
                    <div className="row">
                        <div className="col col-12">
                            <MaterialTable
                                onRowClick={this.onTableRowClick}
                                title=""
                                columns={[
                                    { title: 'Sage Lic.', field: 'sage_licensing_module' },
                                    { title: 'Nombre', field: 'name' },
                                    { title: 'Categoria', field: 'category_name' },
                                    { title: 'Colección', field: 'collection_name' },
                                    { title: 'Promoción', field: 'in_promotion', searchable: false, render: rowData => this.renderCheckColum(rowData.in_promotion), headerStyle: { width: 110, maxWidth: 115 } },
                                    { title: 'Novedad', field: 'is_new', searchable: false, render: rowData => this.renderCheckColum(rowData.is_new), headerStyle: { width: 100, maxWidth: 105 } },
                                    { title: 'Activo', field: 'is_active', searchable: false, render: rowData => this.renderCheckColum(rowData.is_active), headerStyle: { width: 80, maxWidth: 85 } }
                                ]}
                                data={this.state.addonsList}
                                options={{
                                    sorting: true,
                                    pageSize: 20,
                                    pageSizeOptions: [10, 20, 50, 100],
                                    padding: 'dense',
                                    emptyRowsWhenPaging: false,
                                    actionsColumnIndex: -1,
                                    searchFieldAlignment: "left",
                                    actionsCellStyle: { borderRight: '0px', borderBottomStyle: 'none' },
                                    headerStyle: {
                                        fontWeight: 'bold'
                                    },
                                    cellStyle: { borderRight: '0px', borderBottomStyle: 'none' }
                                }}
                                actions={[                                    
                                    rowData => ({
                                        icon: 'file_copy',
                                        tooltip: 'Copiar AddOn',
                                        onClick: (_event, row) => this.handleCopy(row.uuid, row.name),                                        
                                    })
                                ]}
                                localization={{
                                    header: {
                                        actions: "Acciones"
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'No hay datos que mostrar'
                                    },
                                    toolbar: {
                                        searchTooltip: 'Buscar',
                                        searchPlaceholder: 'Buscar'
                                    },
                                    pagination: {
                                        labelRowsSelect: 'filas',
                                        labelDisplayedRows: ' {from}-{to} de {count}',
                                        firstTooltip: 'Primera',
                                        previousTooltip: 'Anterior',
                                        nextTooltip: 'Siguiente',
                                        lastTooltip: 'Última'
                                    }
                                }}
                                components={{
                                    Toolbar: props => (
                                        <div className="row">
                                            <div className="col col-1-auto">
                                                <MTableToolbar {...props} style={{ paddingLeft: 0 }} />
                                            </div>
                                            <div style={{ marginTop: 12, marginRight: 17 }}>
                                                <Button variant="contained" color="primary" onClick={() => { this.props.history.push('/addons/add'); }}>
                                                    Añadir
                                                </Button>
                                            </div>
                                        </div>
                                    ),
                                }}
                            />
                        </div>
                    </div>
                </div>
                <Dialog
                    open={this.state.open}
                    handleClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    maxWidth="md"
                    fullWidth={true}>
                    <ValidatorForm
                        ref="form"
                        onSubmit={this.clone}
                        onError={errors => console.log(errors)}
                    >
                        <DialogContent>
                            <DialogContentText>
                                Establezca un nombre para el nuevo AddOn
                            </DialogContentText>
                            <TextValidator
                                margin="dense"
                                variant="outlined"
                                label="Nombre"
                                name="name"
                                fullWidth={true}
                                value={this.state.cloneAddon.name}
                                onChange={this.handleChange}
                                validators={['required', 'maxStringLength: 64', 'trim']}
                                errorMessages={['Este campo es obligatorio', 'La longitud máxima de este campo es de 64 caracteres', 'Este campo es obligatorio']}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button type="submit" variant="contained" color="primary">
                                Aceptar
                        </Button>
                            <Button onClick={this.handleClose} variant="contained">
                                Cancelar
                        </Button>
                        </DialogActions>
                    </ValidatorForm>
                </Dialog>
            </Fragment>
        )
    }
}