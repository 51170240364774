import React, { Fragment } from 'react';
import { CreateSimulationForm } from './CreateSimulationForm';
import { matchPath } from 'react-router';
import { PageHeader } from '../PageHeader';

export class UpdateSimulationPage extends React.Component {

    getParams = pathname => {
        const matchProfile = matchPath(pathname, {
            path: `/simulations/update/:simulationUuid`
        });
        return (matchProfile && matchProfile.params) || {};
    };

    render() {
        var params = this.getParams(this.props.history.location.pathname);
        return (
            <Fragment>
                <PageHeader title="Modificar simulación" text="Esta página permite modificar una simulación" />
                <p>
                    <CreateSimulationForm uuid={params.simulationUuid} auth={this.props.auth} handlerLoading={this.props.handlerLoading} setStatus={this.props.setStatus} history={this.props.history}></CreateSimulationForm>
                </p>
            </Fragment>
        );
    }
}