import React, { Fragment } from 'react';
import { EmptyCacheForm } from './EmptyCacheForm';
import { PageHeader } from '../PageHeader';

export class EmptyCachePage extends React.Component {

    render() {
        return (
            <Fragment>
                <PageHeader title="Gesti&oacute;n de cach&eacute;" text="Esta p&aacute;gina permite borrar la cach&eacute;" />
                <p>
                    <EmptyCacheForm auth={this.props.auth} handlerLoading={this.props.handlerLoading} setStatus={this.props.setStatus} history={this.props.history}></EmptyCacheForm>
                </p>
            </Fragment>
        );
    }
}