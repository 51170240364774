import React, { Component } from 'react';
import inOfferImage from '../images/on-sale_icon.png';

export class Card extends Component {
    render() {
        return (<div className="card">
            {this.props.isPromoted && (
                <div className="card-promoted"><img src={inOfferImage} alt="en oferta" height="70" /></div>
            )}
            <div className="embed-responsive embed-responsive-4by3">
                <img src={this.props.imagePath} className="card-img-top embed-responsive-item" alt="..."></img>
            </div>
            <div className="card-body">
                <h5 className="card-title">{this.props.name}
                    {this.props.isNew && (
                        <label className="card-new">&nbsp;Novedad</label>
                    )}
                </h5>

                <h6 className="card-subtitle mb-2 text-muted">{this.props.category}</h6>
                <p className="card-text"><div dangerouslySetInnerHTML={{ __html: decodeURIComponent(this.props.description) }} /></p>
            </div>
            <div className="card-footer">
                <a className="btn btn-primary" href={this.props.url} role="button">Más información</a>
            </div>

        </div>);
    }
}