import React, { Fragment } from 'react';
import handleResponse from '../.././functions/handleResponse'
import handleDiagnosis from '../.././functions/handleDiagnosis'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '../Button';
import Grid from '@material-ui/core/Grid';
import SelectAutoComplete from '../../functions/SelectAutoComplete';

const imputTextStyle = {
    width: '90%'
};

const labelStyle = {
    marginTop: 15
};

export class CreateAttributeForm extends React.Component {

    constructor(props) {
        super(props);
        this.fetchSelectsData = this.fetchSelectsData.bind(this);
        this.setAttributeTypesData = this.setAttributeTypesData.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onAdded = this.onAdded.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onSelectAttributeType = this.onSelectAttributeType.bind(this);
        this.onSelectScope = this.onSelectScope.bind(this);

        this.state = {
            attribute: {
                uuid: '',
                name: '',
                scope: 'segmentation',
                attribute_type: '',
                attribute_type_uuid: ''
            },
            attributeTypesList: [],
            scopesList: [{ value: "segmentation", label: "Fichero de licencia" }, { value: "recommendation", label: "Datos de la aplicación" }]
        }
    }

    componentDidMount() {
        this.fetchSelectsData();
    }

    fetchSelectsData() {
        this.props.handlerLoading(true);
        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 3;

        fetch("/api/attributetypes",
            {
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, true))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.setAttributeTypesData(response))
            .catch(error => console.log(error));
    }

    setAttributeTypesData = (attributeTypesData) => {
        var attributeTypesList = attributeTypesData.map(attributeType => ({
            value: attributeType.uuid,
            label: attributeType.name
        }));
        this.setState({ attributeTypesList: attributeTypesList });
    }

    handleSubmit = (event) => {
        this.props.handlerLoading(true);
        const accessToken = this.props.auth.getAccessToken();
        const self = this;

        if (self.state.attribute.attribute_type_uuid === '') {
            this.props.handlerLoading(false);
            return false;
        }

        var setStatusOn200 = 0;

        fetch('/api/attributes',
            {
                method: 'POST',
                headers: new Headers({
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                }),
                body: JSON.stringify(self.state.attribute)
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, true))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.onAdded(response))
            .catch(error => console.log(error));
    }

    onAdded = (attribute) => {
        this.props.history.push('/attributes');
    }

    handleChange = (event) => {
        let attribute = this.state.attribute;
        let newValue = event.target.value;

        attribute[event.target.name] = newValue;
        this.setState({ attribute: attribute });
    }

    onSelectScope(scope) {
        var attribute = this.state.attribute;
        attribute.scope = scope;
        this.setState({ attribute: attribute });
    }

    onSelectAttributeType(attributeTypeUuid) {
        var attribute = this.state.attribute;
        attribute.attribute_type_uuid = attributeTypeUuid;
        this.setState({ attribute: attribute });
    }

    render() {
        return (
            <Fragment>
                <div className="content">
                    <ValidatorForm
                        ref="form"
                        onSubmit={this.handleSubmit}
                        onError={errors => console.log(errors)}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={2} wrap="nowrap">
                                <label style={labelStyle}>Nombre</label>
                            </Grid>
                            <Grid item xs={10}>
                                <TextValidator
                                    style={imputTextStyle}
                                    margin="dense"
                                    variant="outlined"
                                    label="Nombre"
                                    name="name"
                                    value={this.state.attribute.name}
                                    onChange={this.handleChange}
                                    validators={['required', 'maxStringLength: 64', 'trim', 'matchRegexp:^[a-zA-Z0-9]*$']}
                                    errorMessages={['Este campo es obligatorio', 'La longitud máxima de este campo es de 64 caracteres', 'Este campo es obligatorio', 'Solo están permitidos caracteres alfanuméricos']}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} style={{ paddingBottom: '25px' }}>
                            <Grid item xs={2}>
                                <label style={labelStyle}>Origen</label>
                            </Grid>
                            <Grid item xs={5}>
                                <SelectAutoComplete
                                    uuid={this.state.attribute.scope}
                                    suggestions={this.state.scopesList}
                                    placeholder="Selecciona un scope"
                                    name="scope"
                                    onSelectSuggestion={this.onSelectScope}
                                    label="Origen" />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} style={{ paddingBottom: '25px' }}>
                            <Grid item xs={2}>
                                <label style={labelStyle}>Tipo de atributo</label>
                            </Grid>
                            <Grid item xs={5}>
                                <SelectAutoComplete uuid={this.state.attribute.attribute_type_uuid} suggestions={this.state.attributeTypesList} onSelectSuggestion={this.onSelectAttributeType} error="true" placeholder="Selecciona un tipo de atributo" label="Tipo de atributo" />
                            </Grid>
                        </Grid>
                        <Fragment>
                            <Grid container spacing={1}>
                                <Grid item xs={10}>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button type="submit" variant="contained" color="primary"> Guardar</Button>
                                </Grid>
                            </Grid>
                        </Fragment >
                    </ValidatorForm>
                </div>
            </Fragment >
        );
    }

}