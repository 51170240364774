import React, { Fragment } from 'react';
import { CreateScreenForm } from './CreateScreenForm';
import { PageHeader } from '../PageHeader';

export class CreateScreenPage extends React.Component {

    render() {
        return (
            <Fragment>
                <PageHeader title="Crear pantalla cero" text="Esta página permite crear una pantalla cero" />
                <p>
                    <CreateScreenForm auth={this.props.auth} handlerLoading={this.props.handlerLoading} setStatus={this.props.setStatus} history={this.props.history}></CreateScreenForm>
                </p>
            </Fragment>
        );
    }
}