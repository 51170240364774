import React, { Fragment } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import Button from '../Button';
import Icon from '@material-ui/core/Icon';
import moment from 'moment';
import handleResponse from '../.././functions/handleResponse';
import handleDiagnosis from '../.././functions/handleDiagnosis';
import { PageHeader } from '../PageHeader';

export class InstallationsListPage extends React.Component {
	constructor(context) {
        super();
        this.state = {
            installationsList: []
        };
        this.context = context;
        this.fetchData = this.fetchData.bind(this);
        this.onTableRowClick = this.onTableRowClick.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        this.props.handlerLoading(true);
        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 3;

        fetch("/api/installations",
            {
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, true))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.setData(response))
            .catch(error => console.log(error));
    }

    setData(installations) {
        this.setState({ installationsList: installations });
    }

    onTableRowClick(event, rowData) {
        this.props.history.push('/installations/detail/' + rowData.installation_id);
    }

    renderCheckColumn(isCheck) {
        if (isCheck) {
            return <Icon color="primary" style={{ width: '20px' }}>done</Icon>
        }
    }

    render() {
        return (
            <Fragment>
                <PageHeader title="Instalaciones" text="Esta página muestra las instalaciones" />
                <div className="content">
                    <div className="row">
                        <div className="col col-12">
                            <MaterialTable
                                onRowClick={this.onTableRowClick}
                                title=""
                                columns={[
                                    { title: 'Installation Id', field: 'installation_id' },
                                    { title: 'Consent Id', field: 'consent_id' },
                                    { title: 'Colección', field: 'collection_name' },
                                    {   title: 'Acuerdo firmado',
                                        field: 'agreement_signed',
                                        searchable: false,
                                        render: rowData => this.renderCheckColumn(rowData.agreement_signed)
                                    },
                                    {
                                        title: 'Fecha creación',
                                        field: 'created_at',
                                        render: rowData => moment(rowData.created_at).local().format('DD-MM-YYYY HH:mm'),
                                        cellStyle: { width: "13%", borderRight: '0px', borderBottomStyle: 'none' }
                                    },
                                    {
                                        title: 'Fecha actualización',
                                        field: 'updated_at',
                                        render: rowData => moment(rowData.updated_at).local().format('DD-MM-YYYY HH:mm'),
                                        cellStyle: { width: "16%", borderRight: '0px', borderBottomStyle: 'none' }
                                    }
                                ]}
                                data={this.state.installationsList}
                                options={{
                                    sorting: true,
                                    pageSize: 20,
                                    pageSizeOptions: [10, 20, 50, 100],
                                    padding: 'dense',
                                    emptyRowsWhenPaging: false,
                                    actionsColumnIndex: -1,
                                    searchFieldAlignment: "left",
                                    actionsCellStyle: { borderRight: '0px', borderBottomStyle: 'none' },
                                    headerStyle: {
                                        fontWeight: 'bold'
                                    },
                                    cellStyle: { borderRight: '0px', borderBottomStyle: 'none' }
                                }}
                                localization={{
                                    header: {
                                        actions: "Acciones"
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'No hay datos que mostrar'
                                    },
                                    toolbar: {
                                        searchTooltip: 'Buscar',
                                        searchPlaceholder: 'Buscar'
                                    },
                                    pagination: {
                                        labelRowsSelect: 'filas',
                                        labelDisplayedRows: ' {from}-{to} de {count}',
                                        firstTooltip: 'Primera',
                                        previousTooltip: 'Anterior',
                                        nextTooltip: 'Siguiente',
                                        lastTooltip: 'Última'
                                    }
                                }}
                                components={{
                                    Toolbar: props => (
                                        <div className="row">
                                            <div className="col col-1-auto">
                                                <MTableToolbar {...props} style={{ paddingLeft: 0 }} />
                                            </div>
                                            <div style={{ marginTop: 12, marginRight: 17, display: 'none' }}>
                                                <Button variant="contained" color="primary" onClick={() => { this.props.history.push('/installations/add'); }}>
                                                    Añadir
                                                </Button>
                                            </div>
                                        </div>
                                    ),
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}