import React, { Fragment } from 'react';
import { CreateAttributeForm } from './CreateAttributeForm';
import { PageHeader } from '../PageHeader';

export class CreateAttributePage extends React.Component {

    render() {
        return (
            <Fragment>
                <PageHeader title="Crear atributo" text="Esta página permite crear un atributo" />
                <p>
                    <CreateAttributeForm auth={this.props.auth} handlerLoading={this.props.handlerLoading} setStatus={this.props.setStatus} history={this.props.history}></CreateAttributeForm>
                </p>
            </Fragment>
        );
    }
}