import React, { Fragment } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import Button from '../Button';
import handleResponse from '../.././functions/handleResponse';
import handleDiagnosis from '../.././functions/handleDiagnosis';
import { ConfirmDialog } from '.././ConfirmDialog';
import Icon from '@material-ui/core/Icon';
import { PageHeader } from '../PageHeader';
import moment from 'moment';

export class RecommendationsListPage extends React.Component {
    constructor(context) {
        super();

        this.state = {
            recommendationsList: [], delete_uuid: '', open: false
        };
        this.context = context;
        this.fetchData = this.fetchData.bind(this);
        this.setData = this.setData.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.delete = this.delete.bind(this);
        this.onTableRowClick = this.onTableRowClick.bind(this);
        this.handleClose = this.handleClose.bind(true);
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        this.props.handlerLoading(true);
        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 3;

        fetch("/api/recommendations",
            {
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, true))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.setData(response))
            .catch(error => console.log(error));
    }

    setData(recommendations) {
        this.setState({ recommendationsList: recommendations });
    }

    handleDelete = (uuid) => {
        this.setState({ delete_uuid: uuid, open: true });
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    delete = (uuid) => {
        this.props.handlerLoading(true);
        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 2;

        fetch('/api/recommendations/' + uuid,
            {
                method: 'DELETE',
                headers: new Headers({
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, true))
            .then(response => !response.ok ? response.json() : '')
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.fetchData(response))
            .catch(error => console.log(error));
    }

    renderCheckColum(isCheck) {
        if (isCheck) {
            return <Icon color="primary" style={{ width: '20px' }}>done</Icon>
        }
    }

    onTableRowClick(event, rowData) {
        this.props.history.push('/recommendations/update/' + rowData.uuid);
    }

    render() {
        return (
            <Fragment>
                <PageHeader title="Recomendaciones" text="Esta página muestra las recomendaciones creadas" />
                <div className="content">
                    <div class="row">
                        <div class="col col-12">
                            <MaterialTable
                                onRowClick={this.onTableRowClick}
                                title=""
                                columns={[
                                    {
                                        title: 'Título', field: 'title'
                                    },
                                    { title: 'Activo', field: 'is_active', searchable: false, render: rowData => this.renderCheckColum(rowData.is_active), headerStyle: { width: 80, maxWidth: 80 } },
                                    {
                                        title: 'Válido hasta',
                                        field: 'valid_to',
                                        render: rowData => moment(rowData.valid_to).local().format('DD-MM-YYYY HH:mm'),
                                        cellStyle: { width: "12%", borderRight: '0px', borderBottomStyle: 'none' }
                                    }
                                ]}
                                data={this.state.recommendationsList}
                                options={{
                                    sorting: true,
                                    pageSize: 20,
                                    pageSizeOptions: [10, 20, 50, 100],
                                    padding: 'dense',
                                    emptyRowsWhenPaging: false,
                                    actionsColumnIndex: -1,
                                    searchFieldAlignment: "left",
                                    actionsCellStyle: { borderRight: '0px', borderBottomStyle: 'none' },
                                    headerStyle: {
                                        fontWeight: 'bold'
                                    },
                                    cellStyle: { borderRight: '0px', borderBottomStyle: 'none' }
                                }}
                                actions={[
                                    rowData => ({
                                        icon: 'delete',
                                        tooltip: 'Borrar recomendación',
                                        onClick: (event, rowData) => this.handleDelete(rowData.uuid)
                                    })
                                ]}
                                localization={{
                                    header: {
                                        actions: 'Acciones'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'No hay datos que mostrar'
                                    },
                                    toolbar: {
                                        searchTooltip: 'Buscar',
                                        searchPlaceholder: 'Buscar'
                                    },
                                    pagination: {
                                        labelRowsSelect: 'filas',
                                        labelDisplayedRows: ' {from}-{to} de {count}',
                                        firstTooltip: 'Primera',
                                        previousTooltip: 'Anterior',
                                        nextTooltip: 'Siguiente',
                                        lastTooltip: 'Última'
                                    }
                                }}
                                components={{
                                    Toolbar: props => (
                                        <div className="row">
                                            <div className="col col-1-auto">
                                                <MTableToolbar {...props} style={{ paddingLeft: 0 }} />
                                            </div>
                                            <div style={{ marginTop: 12, marginRight: 17 }}>
                                                <Button variant="contained" color="primary" onClick={() => { this.props.history.push('/recommendations/add'); }}>
                                                    Añadir
                                            </Button>
                                            </div>
                                        </div>
                                    ),
                                }}
                            />
                        </div>
                    </div>
                </div>
                <ConfirmDialog
                    open={this.state.open}
                    onAgree={() => this.delete(this.state.delete_uuid)}
                    title="¿Deseas continuar?"
                    text="Se va a eliminar la recomendación. Esta acción es irreversible ¿deseas continuar?"
                    handleClose={this.handleClose}
                />
            </Fragment>
        )
    }
}