import React, { Fragment } from 'react';
import handleResponse from '../.././functions/handleResponse'
import handleDiagnosis from '../.././functions/handleDiagnosis'
import { ValidatorForm } from 'react-material-ui-form-validator';
import Button from '../Button';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const labelStyle = {
    marginTop: 15
};

export class EmptyCacheForm extends React.Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeAddons = this.handleChangeAddons.bind(this);
        this.handleChangeLegalNews = this.handleChangeLegalNews.bind(this);
        this.handleChangeScreens = this.handleChangeScreens.bind(this);
        this.handleChangeRecommendations = this.handleChangeRecommendations.bind(this);
        this.handleChangeSageNews = this.handleChangeSageNews.bind(this);

        this.state = {
            addonsCache: false,
            legalNewsCache: false,
            screensCache: false,
            recommendationsCache: false,
            sageNewsCache: false,
            clearCache: {
                clear_addons: false,
                clear_legalnews: false,
                clear_screens: false,
                clear_recommendations: false,
                clear_sagenews: false
            },
        }
    }

    componentDidMount() {

    }

    handleSubmit = (event) => {
        this.props.handlerLoading(true);

        var hasCheckedAndOption = this.state.addonsCache === true || this.state.legalNewsCache === true || this.state.recommendationsCache === true
            || this.state.screensCache === true || this.state.sageNewsCache === true;

        if (!hasCheckedAndOption) {
            this.props.setStatus("error", 'Es necesario seleccionar alguna casilla');
            this.props.handlerLoading(false);
            return false;
        }

        const accessToken = this.props.auth.getAccessToken();

        var clearCache = this.state.clearCache;

        clearCache.clear_addons = this.state.addonsCache;
        clearCache.clear_legalnews = this.state.legalNewsCache;
        clearCache.clear_screens = this.state.screensCache
        clearCache.clear_recommendations = this.state.recommendationsCache;
        clearCache.clear_sagenews = this.state.sageNewsCache;

        var method = 'POST';
        var url = '/api/cache';
        var setStatusOn200 = 2;

        fetch(url,
            {
                method: method,
                headers: new Headers({
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                }),
                body: JSON.stringify(clearCache)
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, true))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : null)
            .catch(error => console.log(error));
    }

    handleChangeAddons = (event) => {
        var newValue;
        if (event.target.name === "enabled") {
            newValue = event.target.checked;
        } else {
            newValue = event.target.value;
        }

        this.setState({ addonsCache: newValue });
    }

    handleChangeLegalNews = (event) => {
        var newValue;
        if (event.target.name === "enabled") {
            newValue = event.target.checked;
        } else {
            newValue = event.target.value;
        }

        this.setState({ legalNewsCache: newValue });
    }

    handleChangeScreens = (event) => {
        var newValue;
        if (event.target.name === "enabled") {
            newValue = event.target.checked;
        } else {
            newValue = event.target.value;
        }

        this.setState({ screensCache: newValue });
    }

    handleChangeRecommendations = (event) => {
        var newValue;
        if (event.target.name === "enabled") {
            newValue = event.target.checked;
        } else {
            newValue = event.target.value;
        }

        this.setState({ recommendationsCache: newValue });
    }

    handleChangeSageNews = (event) => {
        var newValue;
        if (event.target.name === "enabled") {
            newValue = event.target.checked;
        } else {
            newValue = event.target.value;
        }

        this.setState({ sageNewsCache: newValue });
    }

    render() {
        return (
            <Fragment>
                <div className="content">
                    <ValidatorForm
                        ref="form"
                        onSubmit={this.handleSubmit}
                        onError={errors => console.log(errors)}
                    >
                        <label style={labelStyle}>Selecciona las casillas correspondientes a las operaciones sobre las que quieres borrar la informaci&oacute;n almacenada en la cach&eacute;</label>
                        <Grid container spacing={1} style={{ paddingLeft: '20px' }}>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.addonsCache}
                                                onChange={this.handleChangeAddons}
                                                value="enabled"
                                                name="enabled"
                                                color="primary"
                                            />
                                        }
                                        label="Vaciar cach&eacute; de M&oacute;dulos"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} style={{ paddingLeft: '20px' }}>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.recommendationsCache}
                                                onChange={this.handleChangeRecommendations}
                                                value="enabled"
                                                name="enabled"
                                                color="primary"
                                            />
                                        }
                                        label="Vaciar cach&eacute; de Recomendaciones"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} style={{ paddingLeft: '20px' }}>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.legalNewsCache}
                                                onChange={this.handleChangeLegalNews}
                                                value="enabled"
                                                name="enabled"
                                                color="primary"
                                            />
                                        }
                                        label="Vaciar cach&eacute; de Noticias legales"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} style={{ paddingLeft: '20px' }}>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.screensCache}
                                                onChange={this.handleChangeScreens}
                                                value="enabled"
                                                name="enabled"
                                                color="primary"
                                            />
                                        }
                                        label="Vaciar cach&eacute; de Pantallas Cero"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} style={{ paddingLeft: '20px' }}>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.sageNewsCache}
                                                onChange={this.handleChangeSageNews}
                                                value="enabled"
                                                name="enabled"
                                                color="primary"
                                            />
                                        }
                                        label="Vaciar cach&eacute; de Sage Contigo"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Fragment>
                            <Grid container spacing={1}>
                                <Grid item xs={11}>
                                </Grid>
                                <Grid item xs="auto">
                                    <Button type="submit" variant="contained" color="primary"> Vaciar</Button>
                                </Grid>
                            </Grid>
                        </Fragment >
                    </ValidatorForm>
                </div>
            </Fragment >
        );
    }

}