import React, { Fragment } from 'react';
import MaterialTable from 'material-table';
import Button from '../Button';
import handleResponse from '../.././functions/handleResponse';
import handleDiagnosis from '../.././functions/handleDiagnosis';
import Icon from '@material-ui/core/Icon';
import SelectAutoComplete from '../../functions/SelectAutoComplete';
import { ConfirmDialog } from '.././ConfirmDialog';

export class RelatedRecommendationAddOns extends React.Component {
    constructor(context) {
        super();
        this.state = {
            source: {},
            related: [],
            addonsList: [],
            selectRelatedUuid: '',
            delete_uuid: '',
            open: false
        };
        this.context = context;
        this.onSelectAddon = this.onSelectAddon.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.setData = this.setData.bind(this);
        this.setSource = this.setSource.bind(this);
        this.setAddonsList = this.setAddonsList.bind(this);
        this.onAddButtonClick = this.onAddButtonClick.bind(this);
        this.onRelatedAddonAdded = this.onRelatedAddonAdded.bind(this);
        this.onRelatedAddonDeteled = this.onRelatedAddonDeteled.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.delete = this.delete.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 3;

        fetch(`/api/${this.props.source}/${this.props.uuid}`,
            {
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, false))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.setSource(response))
            .catch(error => console.log(error));
    }

    async setSource(source) {
        this.setState({ source: source });

        this.setData();
    }

    setData() {
        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 3;

        fetch(`/api/collections/${this.state.source.collection_uuid}/addons`,
            {
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, false))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.setAddonsList(response))
            .catch(error => console.log(error));
    }

    setAddonsList(addons) {
        var self = this;
        addons = addons.filter(function (obj) {
            var isSelf = obj.uuid === self.props.uuid;
            if (isSelf) {
                return false;
            }

            var findFunction = (element) => {
                return element.uuid === obj.uuid;
            }
            var findAddons = self.props.target === 'addons' ? [...self.state.source.addons] :
                [...self.state.source.requirements, ...self.state.source.incompatibilities];

            var isRelated = findAddons.find((elem) => findFunction(elem));

            return !isRelated;
        });

        addons = addons.map(addon => ({
            value: addon.uuid,
            label: addon.name
        }));

        self.setState({ addonsList: addons });
    }

    onSelectAddon(uuid) {
        this.setState({ selectRelatedUuid: uuid });
    }

    onAddButtonClick() {
        if (this.state.selectRelatedUuid) {
            this.props.handlerLoading(true);
            const accessToken = this.props.auth.getAccessToken();
            var setStatusOn200 = 0;

            fetch(`/api/${this.props.source}/${this.props.uuid}/${this.props.target}/${this.state.selectRelatedUuid}`,
                {
                    method: 'POST',
                    headers: new Headers({
                        "Accept": "application/json",
                        "Authorization": `Bearer ${accessToken}`
                    })
                })
                .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, false))
                .then(response => !response.ok ? response.json() : '')
                .then(response => response.diagnosis
                    ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading)
                    : (this.onRelatedAddonAdded(), this.props.handlerLoading(false)))
                .catch(error => console.log(error));
        }
    }

    delete = (uuid) => {
        this.props.handlerLoading(true);
        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 2;

        fetch(`/api/${this.props.source}/${this.props.uuid}/${this.props.target}/${this.state.delete_uuid}`,
            {
                method: 'DELETE',
                headers: new Headers({
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, false))
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : (this.onRelatedAddonDeteled(), this.props.handlerLoading(false)))
            .catch(error => console.log(error));
    }

    onRelatedAddonDeteled() {
        this.setState({ delete_uuid: '' });
        this.fetchData();
    }

    onRelatedAddonAdded() {
        this.setState({ selectRelatedUuid: '' });
        this.fetchData();
    }

    renderCheckColum(isCheck) {
        if (isCheck) {
            return <Icon color="primary" style={{ width: '20px' }}>done</Icon>
        }
    }

    handleDelete = (uuid) => {
        this.setState({ delete_uuid: uuid, open: true });
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    render() {
        return (
            <Fragment>
                <div class="row pt-3">
                    <div class="col col-5">
                        <SelectAutoComplete uuid={this.state.selectRelatedUuid} suggestions={this.state.addonsList} onSelectSuggestion={this.onSelectAddon} placeholder={`Seleccionar AddOn ${this.props.targetText}`} label={`Seleccionar AddOn ${this.props.targetText}`} />
                    </div>
                    <div class="col col-1">
                        <Button variant="contained" color="primary" onClick={this.onAddButtonClick} className="selectbutton">
                            Añadir
                        </Button>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12">
                        <MaterialTable
                            onRowClick={this.onTableRowClick}
                            title=""
                            columns={[
                                { title: 'Nombre', field: 'name' },
                                { title: 'Activo', field: 'is_active', cellStyle: { width: "5%", borderRight: '0px', borderBottomStyle: 'none' }, searchable: false, render: rowData => this.renderCheckColum(rowData.is_active) }
                            ]}
                            data={this.state.source[this.props.target]}
                            options={{
                                sorting: true,
                                pageSize: 20,
                                pageSizeOptions: [10, 20, 50, 100],
                                padding: 'dense',
                                emptyRowsWhenPaging: false,
                                actionsColumnIndex: -1,
                                searchFieldAlignment: "left",
                                actionsCellStyle: { borderRight: '0px', borderBottomStyle: 'none' },
                                headerStyle: {
                                    fontWeight: 'bold'
                                },
                                cellStyle: { borderRight: '0px', borderBottomStyle: 'none' }
                            }}
                            actions={[
                                rowData => ({
                                    icon: 'delete',
                                    tooltip: `Quitar AddOn ${this.props.targetText}`,
                                    onClick: (event, row) => this.handleDelete(row.uuid)
                                })
                            ]}
                            localization={{
                                header: {
                                    actions: "Acciones"
                                },
                                body: {
                                    emptyDataSourceMessage: 'No hay datos que mostrar'
                                },
                                toolbar: {
                                    searchTooltip: 'Buscar',
                                    searchPlaceholder: 'Buscar'
                                },
                                pagination: {
                                    labelRowsSelect: 'filas',
                                    labelDisplayedRows: ' {from}-{to} de {count}',
                                    firstTooltip: 'Primera',
                                    previousTooltip: 'Anterior',
                                    nextTooltip: 'Siguiente',
                                    lastTooltip: 'Última'
                                }
                            }}
                        />
                    </div>
                </div>
                <ConfirmDialog
                    open={this.state.open}
                    onAgree={() => this.delete(this.state.delete_uuid)}
                    title="¿Deseas continuar?"
                    text={`Se va a quitar el AddOn ${this.props.targetText}, esta acción es irreversible, ¿desea continuar?`}
                    handleClose={this.handleClose}
                />
            </Fragment>
        )
    }
}