import React, { Fragment } from 'react';
import handleResponse from '../.././functions/handleResponse'
import handleDiagnosis from '../.././functions/handleDiagnosis'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '../Button';
import Grid from '@material-ui/core/Grid';
import { formatQuery } from "react-querybuilder";
import SelectAutoComplete from '../../functions/SelectAutoComplete';
import { RulesQueryBuilder } from '.././RulesQueryBuilder';
import * as queryBuilderFunctions from '.././RulesQueryBuilder';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { CreateDialog } from '../Data/CreateDialog';
import AddIcon from '@material-ui/icons/Add';
import recommendedSizeImage from '../../images/275-120.png';
import DeleteIcon from '@material-ui/icons/Delete';
import Box from '@material-ui/core/Box';

const imputTextStyle = {
    width: '90%'
};

const labelStyle = {
    marginTop: 15
};

export class CreateSageNewsForm extends React.Component {
    constructor(props) {
        super(props);
        this.fetchData = this.fetchData.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onAdded = this.onAdded.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.onSelectCollection = this.onSelectCollection.bind(this);
        this.handleSegmentationQueryChange = this.handleSegmentationQueryChange.bind(true);
        this.handleAttributeQueryChange = this.handleAttributeQueryChange.bind(true);
        this.handleOpen = this.handleOpen.bind(true);
        this.handleClose = this.handleClose.bind(true);
        this.handleChangeImage = this.handleChangeImage.bind(this);
        this.handleIconFileUploadClick = this.handleIconFileUploadClick.bind(this);
        this.removeIcon = this.removeIcon.bind(this);

        this.state = {
            sagenews: {
                uuid: '',
                title: '',
                description: '',
                url: '',
                collection_uuid: '',
                segmentation_rule: '',
                segmentation_rule_query: '',
                attribute_rule: '',
                attribute_rule_query: '',
                valid_from: new Date(new Date().setSeconds(0,0)),
                valid_to: new Date(new Date().setSeconds(0,0)),
                segmentation_query: null,
                attribute_query: null,
                image: '',
                image_path: ''
            },
            collections: [],
            segmentationAttributes: [],
            recommendationAttributes: [],
            attributeTypesList: [],
            open: false
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        this.props.handlerLoading(true);
        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 3;

        fetch("/api/collections",
            {
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, false))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.setCollectionsData(response))
            .catch(error => console.log(error));
    }

    setCollectionsData = (products) => {
        var collectionsList = products.map(product => ({
            value: product.uuid,
            label: product.name
        }));
        this.setState({ collections: collectionsList });

        this.fetchAttributes();
    }

    fetchAttributes() {
        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 3;

        fetch("/api/attributes",
            {
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, false))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.setAttributesData(response))
            .catch(error => console.log(error));
    }

    setAttributesData = (attributes) => {

        var attributesList = attributes.map(attribute => ({
            name: attribute.name,
            label: attribute.name,
            id: attribute.uuid,
            attribute_type_uuid: attribute.attribute_type_uuid,
            scope: attribute.scope
        }));

        var segmentationAttributes = attributesList.filter((attr) => attr.scope === "segmentation");
        var recommendationAttributes = attributesList.filter((attr) => attr.scope === "recommendation");

        this.setState({ segmentationAttributes: segmentationAttributes, recommendationAttributes: recommendationAttributes });

        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 3;

        fetch("/api/attributetypes",
            {
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, false))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.setAttributeTypesData(response))
            .catch(error => console.log(error));
    }

    setAttributeTypesData = (attributeTypes) => {
        this.setState({ attributeTypesList: attributeTypes });
        this.fetchSageNewsData();
    }

    fetchSageNewsData = () => {
        if (this.props.uuid) {
            const accessToken = this.props.auth.getAccessToken();
            var setStatusOn200 = 3;

            fetch("/api/sagenews/" + this.props.uuid,
                {
                    headers: new Headers({
                        "Accept": "application/json",
                        "Authorization": `Bearer ${accessToken}`
                    })
                })
                .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, true))
                .then(response => response.json())
                .then(response => response.diagnosis
                    ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading)
                    : this.setData(response))
                .catch(error => console.log(error));
        }
        else
            this.props.handlerLoading(false);
    }

    setData = (sagenews) => {
        sagenews.valid_from = sagenews.valid_from != null ? new Date(new Date(sagenews.valid_from).setSeconds(0,0)) : null;
        sagenews.valid_to = sagenews.valid_to != null ? new Date(new Date(sagenews.valid_to).setSeconds(0,0)) : null;

        if (sagenews.segmentation_rule_query && sagenews.segmentation_rule_query !== null && sagenews.segmentation_rule_query !== "") {
            sagenews.segmentation_query = JSON.parse(sagenews.segmentation_rule_query);
        } else {
            sagenews.segmentation_query = null;
        }

        if (sagenews.attribute_rule_query && sagenews.attribute_rule_query !== null && sagenews.attribute_rule_query !== "") {
            sagenews.attribute_query = JSON.parse(sagenews.attribute_rule_query);
        } else {
            sagenews.attribute_query = null;
        }

        if (!sagenews.segmentation_rule) {
            sagenews.segmentation_rule = "";
        }

        if (!sagenews.url) {
            sagenews.url = "";
        }

        if (!sagenews.image) {
            sagenews.image = '';
        }

        this.setState({ sagenews: sagenews });
    }

    handleSubmit = (event) => {
        this.props.handlerLoading(true);
        const accessToken = this.props.auth.getAccessToken();
        const self = this;

        if (!this.isValidDate(self.state.sagenews.valid_from, true) ||
            !this.isValidDate(self.state.sagenews.valid_to, true)) {
            this.props.handlerLoading(false);
            return false;
        }

        if (self.state.sagenews.collection_uuid === '') {
            this.props.handlerLoading(false);
            return false;
        }

        if (self.state.sagenews.segmentation_rule) {
            if (self.state.sagenews.segmentation_rule === "()") {
                let sagenews = self.state.sagenews;
                sagenews.segmentation_rule = "";
                sagenews.segmentation_rule_query = null;
                this.setState({ sagenews: sagenews });
            }
            else if (self.state.sagenews.segmentation_rule.includes("()")) {
                this.props.setStatus("error", 'No puede haber grupos sin reglas en las reglas de segmentación.');
                this.props.handlerLoading(false);
                return false;
            }
            else if (self.state.sagenews.segmentation_rule.includes(" \"\"") || self.state.sagenews.segmentation_rule.includes("== &&") || self.state.sagenews.segmentation_rule.includes("== ||") || self.state.sagenews.segmentation_rule.includes("==)")) {
                this.props.setStatus("error", 'Todas las reglas de segmentación deben tener un valor.');
                this.props.handlerLoading(false);
                return false;
            }
        }

        if (self.state.sagenews.attribute_rule) {
            if (self.state.sagenews.attribute_rule === "()") {
                let sagenews = self.state.sagenews;
                sagenews.attribute_rule = "";
                sagenews.attribute_rule_query = null;
                this.setState({ sagenews: sagenews });
            }
            else if (self.state.sagenews.attribute_rule.includes("()")) {
                this.props.setStatus("error", 'No puede haber grupos sin reglas en las reglas de atributos de uso.');
                this.props.handlerLoading(false);
                return false;
            }
            else if (self.state.sagenews.attribute_rule.includes(" \"\"") || self.state.sagenews.attribute_rule.includes("== &&") || self.state.sagenews.attribute_rule.includes("== ||") || self.state.sagenews.attribute_rule.includes("==)")) {
                this.props.setStatus("error", 'Todas las reglas de atributos de uso deben tener un valor.');
                this.props.handlerLoading(false);
                return false;
            }
        }

        var method = 'POST';
        var url = '/api/sagenews';
        var setStatusOn200 = 0;

        if (self.state.sagenews.uuid && self.state.sagenews.uuid !== '' && self.state.sagenews.uuid !== null) {
            method = 'PUT';
            url = '/api/sagenews/' + self.state.sagenews.uuid;
            setStatusOn200 = 1;
        }

        fetch(url,
            {
                method: method,
                headers: new Headers({
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                }),
                body: JSON.stringify(self.state.sagenews)
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, false))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : (this.onAdded(response), this.props.handlerLoading(false)))
            .catch(error => console.log(error));
    }

    isValidDate = (d, validateNull) => {
        if (!validateNull) {
            return d == null || (d instanceof Date && !isNaN(d));
        } else {
            return d instanceof Date && !isNaN(d);
        }
    }

    onAdded = (sagenews) => {
        this.props.history.push('/sagenews');
    }

    handleChange = (event) => {
        let sagenews = this.state.sagenews;
        var newValue = event.target.value;
        sagenews[event.target.name] = newValue;
        this.setState({ sagenews: sagenews });
    }

    handleDateChange = (date, field) => {
        let sagenews = this.state.sagenews;
        var newValue = date == null ? null : date._d;

        if (newValue === null)
            this.setState({ [field + '_error']: 'Este campo es obligatorio' });
        else if (!this.isValidDate(newValue, false))
            this.setState({ [field + '_error']: 'Formato de fecha no válido' });
        else
            this.setState({ [field + '_error']: '' });

        sagenews[field] = newValue;
        this.setState({ sagenews: sagenews });
}

    handleSegmentationQueryChange = (query) => {
        let sagenews = this.state.sagenews;

        sagenews.segmentation_query = query;

        const valueProcessor = queryBuilderFunctions.default(this.state.attributeTypesList, this.state.segmentationAttributes);

        var formatedQuery = this.state.sagenews.segmentation_query != null ? formatQuery(this.state.sagenews.segmentation_query, { format: 'sql', valueProcessor }) : '';
        var formatedQueryRaw = this.state.sagenews.segmentation_query != null ? formatQuery(this.state.sagenews.segmentation_query, { format: 'JSON' }) : '';

        sagenews.segmentation_rule = formatedQuery;
        sagenews.segmentation_rule_query = formatedQueryRaw;
        this.setState({ sagenews: sagenews });
    };

    handleAttributeQueryChange = (query) => {
        let sagenews = this.state.sagenews;

        sagenews.attribute_query = query;

        const valueProcessor = queryBuilderFunctions.default(this.state.attributeTypesList, this.state.recommendationAttributes);

        var formatedQuery = this.state.sagenews.attribute_query != null ? formatQuery(this.state.sagenews.attribute_query, { format: 'sql', valueProcessor }) : '';
        var formatedQueryRaw = this.state.sagenews.attribute_query != null ? formatQuery(this.state.sagenews.attribute_query, { format: 'JSON' }) : '';

        sagenews.attribute_rule = formatedQuery;
        sagenews.attribute_rule_query = formatedQueryRaw;
        this.setState({ sagenews: sagenews });
    };

    onSelectCollection(collectionUuid) {
        var sagenews = this.state.sagenews;
        sagenews.collection_uuid = collectionUuid;
        this.setState({ sagenews: sagenews });
    }

    handleOpen = () => {
        this.setState({ open: true });
    }

    handleClose = (response) => {

        if (response) {
            var newCollection =
            {
                value: response.uuid,
                label: response.name
            }
            let collections = this.state.collections;
            let sagenews = this.state.sagenews;
            collections.push(newCollection);
            sagenews.collection_uuid = response.uuid;
            this.setState({ collections: collections, sagenews: sagenews });
        }
        this.setState({ open: false });
    }

    removeIcon = () => {
        let sagenews = this.state.sagenews;
        sagenews.image = "";
        sagenews.image_path = "";
        this.setState({ sagenews: sagenews });
    };

    handleChangeImage(evt, imageType) {
        var self = this;
        var reader = new FileReader();
        var file = evt.target.files[0];

        if (!file.type.includes("image")) {
            this.props.setStatus("error", 'El archivo debe ser una imagen');
            return false;
        }

        if (file.size > 524288) {
            this.props.setStatus("error", 'La imagen debe pesar un máximo de 512KB');
            return false;
        }

        reader.onload = function (upload) {
            let sagenews = self.state.sagenews;
            sagenews[imageType] = upload.target.result;
            self.setState({ sagenews: sagenews });
        };
        reader.readAsDataURL(file);
    }

    handleIconFileUploadClick(e) {
        this.refs.file.click();
    }

    render() {
        return (
            <Fragment>
                <div className="content">
                    <CreateDialog
                        open={this.state.open}
                        handleClose={this.handleClose}
                        handleOnAdded={this.handleClose}
                        entity={{ name: 'colección', type: 'collections' }}
                        handlerLoading={this.props.handlerLoading}
                        setStatus={this.props.setStatus}
                        auth={this.props.auth}
                    >
                    </CreateDialog>
                    <ValidatorForm
                        ref="form"
                        onSubmit={this.handleSubmit}
                        onError={errors => console.log(errors)}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={2} wrap="nowrap">
                                <label style={labelStyle}>Título</label>
                            </Grid>
                            <Grid item xs={10}>
                                <TextValidator
                                    style={imputTextStyle}
                                    margin="dense"
                                    variant="outlined"
                                    label="Título"
                                    name="title"
                                    value={this.state.sagenews.title}
                                    onChange={this.handleChange}
                                    validators={['required', 'maxStringLength: 128', 'trim']}
                                    errorMessages={['Este campo es obligatorio', 'La longitud máxima de este campo es de 128 caracteres', 'Este campo es obligatorio']}
                                />
                            </Grid>
                        </Grid>                        
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <label style={labelStyle}>Descripción</label>
                            </Grid>
                            <Grid item xs={10}>
                                <TextValidator
                                    style={imputTextStyle}
                                    margin="dense"
                                    variant="outlined"
                                    label="Descripción"
                                    name="description"
                                    value={this.state.sagenews.description}
                                    onChange={this.handleChange}
                                    validators={['required', 'maxStringLength: 256', 'trim']}
                                    errorMessages={['Este campo es obligatorio', 'La longitud máxima de este campo es de 256 caracteres', 'Este campo es obligatorio']}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <label style={labelStyle}>Url</label>
                            </Grid>
                            <Grid item xs={10}>
                                <TextValidator
                                    style={imputTextStyle}
                                    margin="dense"
                                    variant="outlined"
                                    label="Url"
                                    name="url"
                                    value={this.state.sagenews.url}
                                    onChange={this.handleChange}
                                    validators={['maxStringLength: 512']}
                                    errorMessages={['La longitud máxima de este campo es de 512 caracteres']}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} style={{ paddingBottom: '25px' }}>
                            <Grid item xs={2}>
                                <label style={labelStyle}>Colección</label>
                            </Grid>
                            <Grid item xs={5}>
                                <SelectAutoComplete
                                    uuid={this.state.sagenews.collection_uuid}
                                    suggestions={this.state.collections}
                                    onSelectSuggestion={this.onSelectCollection}
                                    placeholder="Colección"
                                    label="Colección" />
                            </Grid>
                            <Grid item xs={1}>
                                <Button onClick={this.handleOpen} variant="contained" className="selectbutton">
                                    <AddIcon />
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <label style={labelStyle}>Imagen</label>
                            </Grid>
                            <Grid item xs="auto">
                                <Box width="200px">
                                    {this.state.sagenews.image_path !== "" && (
                                        <img src={this.state.sagenews.image_path} class="img-responsive" alt="imagen sagenews" />
                                    )}
                                    {this.state.sagenews.image_path === "" && this.state.sagenews.image === "" && (
                                        <img src={recommendedSizeImage} class="img-responsive" alt="imagen sagenews" />
                                    )}
                                    {this.state.sagenews.image_path === "" && this.state.sagenews.image !== "" && (
                                        <img src={this.state.sagenews.image} class="img-responsive" alt="imagen sagenews" />
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs="auto">
                                {this.state.sagenews.image === "" && this.state.sagenews.image_path === "" && (
                                    <Button variant="contained" onClick={this.handleIconFileUploadClick}>
                                        Subir imagen
                                    < input ref="file" type="file" name="file"
                                            className="upload-file"
                                            id="file"
                                            onChange={(event) => this.handleChangeImage(event, "image")}
                                            encType="multipart/form-data"
                                            style={{ display: "none" }}
                                            accept="image/*"
                                        />
                                    </Button>
                                )}
                                {(this.state.sagenews.image !== "" || this.state.sagenews.image_path !== "") && (
                                    <DeleteIcon cursor="pointer" style={labelStyle} onClick={this.removeIcon} />
                                )}
                            </Grid>
                        </Grid>
                        <Fragment>
                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <label style={labelStyle}>Valida desde: </label>
                                </Grid>
                                <Grid item xs={10}>
                                    <KeyboardDateTimePicker
                                        variant="outlined"
                                        ampm={false}
                                        label="Válido desde"
                                        value={this.state.sagenews.valid_from}
                                        onChange={date => this.handleDateChange(date, "valid_from")}
                                        format="DD/MM/YYYY HH:mm"
                                        error={this.state.valid_from_error}
                                        helperText={this.state.valid_from_error !== '' ? this.state.valid_from_error : ''}
                                        invalidDateMessage="Formato de fecha no válido"

                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <label style={labelStyle}>Valida hasta: </label>
                                </Grid>
                                <Grid item xs={10}>
                                    <KeyboardDateTimePicker
                                        variant="outlined"
                                        ampm={false}
                                        label="Válido hasta"
                                        value={this.state.sagenews.valid_to}
                                        onChange={date => this.handleDateChange(date, "valid_to")}
                                        format="DD/MM/YYYY HH:mm"
                                        error={this.state.valid_to_error}
                                        helperText={this.state.valid_to_error !== '' ? this.state.valid_to_error : ''}
                                        invalidDateMessage="Formato de fecha no válido"

                                    />
                                </Grid>
                            </Grid>
                            <Grid className="rules-title" container spacing={1}>
                                <Grid item xs={12} wrap="nowrap">
                                    <h5><strong>Reglas para mostrar Sage Contigo</strong>: aplicadas sobre los Atributos del fichero  de licencia</h5>
                                    Si no se define ninguna regla el contenido de Sage Contigo no se mostrará en el cliente
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={11}>
                                    <RulesQueryBuilder
                                        query={this.state.sagenews.segmentation_query}
                                        onQueryChange={this.handleSegmentationQueryChange}
                                        fields={this.state.segmentationAttributes}
                                        fieldTypes={this.state.attributeTypesList}
                                    />
                                </Grid>
                            </Grid>
                            <Grid className="rules-title" container spacing={1}>
                                <Grid item xs={12} wrap="nowrap">
                                    <h5><strong>Reglas para mostrar Sage Contigo</strong>: aplicadas sobre los Atributos de datos del producto</h5>
                                    Si no se define ninguna regla el contenido de Sage Contigo no se mostrará en el cliente
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={11}>
                                    <RulesQueryBuilder
                                        query={this.state.sagenews.attribute_query}
                                        onQueryChange={this.handleAttributeQueryChange}
                                        fields={this.state.recommendationAttributes}
                                        fieldTypes={this.state.attributeTypesList}
                                    />
                                </Grid>
                            </Grid>
                        </Fragment>
                        <Fragment>
                            <Grid container spacing={1}>
                                <Grid item xs={11} className="text-right">
                                    <Button type="submit" variant="contained" color="primary"> Guardar</Button>
                                </Grid>
                            </Grid>
                        </Fragment >
                    </ValidatorForm>
                </div>
            </Fragment >
        );
    }
}