import React, { Fragment } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import Button from '../Button';
import handleResponse from '../.././functions/handleResponse';
import handleDiagnosis from '../.././functions/handleDiagnosis';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import { PageHeader } from '../PageHeader';

export class AgreementsListPage extends React.Component {
    constructor(context) {
        super();
        this.state = { agreementsList: [], delete_uuid: 0, open: false };
        this.context = context;
        this.fetchData = this.fetchData.bind(this);
        this.onTableRowClick = this.onTableRowClick.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.delete = this.delete.bind(this);
        this.handleClose = this.handleClose.bind(true);
        this.renderCheckColum = this.renderCheckColum.bind(true);
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        this.props.handlerLoading(true);
        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 3;

        fetch("/api/agreements",
            {
                headers: new Headers({
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, true))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.setData(response))
            .catch(error => console.log(error));
    }

    setData(data) {
        this.setState({ agreementsList: data });
    }

    onTableRowClick(event, rowData) {
        this.props.history.push('/agreements/update/' + rowData.uuid);
    }

    handleDelete = (uuid) => {
        this.setState({ delete_uuid: uuid, open: true });
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    delete = (uuid) => {
        this.props.handlerLoading(true);
        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 2;

        fetch('/api/agreements/' + uuid,
            {
                method: 'DELETE',
                headers: new Headers({
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, true))
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.fetchData(response))
            .catch(error => console.log(error));
    }

    renderCheckColum(isCheck) {
        if (isCheck) {
            return <Icon color="primary" style={{ width: '20px' }}>done</Icon>
        }
    }

    render() {
        return (
            <Fragment>
                <PageHeader title="Acuerdos de cliente" text="Esta página muestra los acuerdos de cliente creados" />
                <div className="content">
                    <div class="row">
                        <div class="col col-12">
                            <MaterialTable
                                title=""
                                onRowClick={this.onTableRowClick}
                                columns={[
                                    { title: 'Título', field: 'title', cellStyle: { width: "80%", borderRight: '0px', borderBottomStyle: 'none' } },
                                    {
                                        title: 'Activo',
                                        field: 'enabled',
                                        searchable: false,
                                        render: rowData => this.renderCheckColum(rowData.enabled),
                                        cellStyle: { width: "1%", borderRight: '0px', borderBottomStyle: 'none' }
                                    },
                                    {
                                        title: 'Fecha de creación',
                                        field: 'created_at',
                                        render: rowData => moment(rowData.created_at).local().format('DD-MM-YYYY HH:mm'),
                                        cellStyle: { width: "19%", borderRight: '0px', borderBottomStyle: 'none' }
                                    }
                                ]}
                                data={this.state.agreementsList}
                                options={{
                                    sorting: true,
                                    pageSize: 20,
                                    pageSizeOptions: [10, 20, 50, 100],
                                    toolbar: { searchPlaceholder: 'Buscar', searchTooltip: 'Buscar' },
                                    padding: 'dense',
                                    emptyRowsWhenPaging: false,
                                    actionsColumnIndex: -1,
                                    searchFieldAlignment: "left",
                                    actionsCellStyle: { borderRight: '0px', borderBottomStyle: 'none' },
                                    headerStyle: {
                                        fontWeight: 'bold'
                                    },
                                    cellStyle: { borderRight: '0px', borderBottomStyle: 'none' }
                                }}
                                localization={{
                                    body: {
                                        emptyDataSourceMessage: 'No hay datos que mostrar'
                                    },
                                    toolbar: {
                                        searchTooltip: 'Buscar',
                                        searchPlaceholder: 'Buscar'
                                    },
                                    pagination: {
                                        labelRowsSelect: 'filas',
                                        labelDisplayedRows: ' {from}-{to} de {count}',
                                        firstTooltip: 'Primera',
                                        previousTooltip: 'Anterior',
                                        nextTooltip: 'Siguiente',
                                        lastTooltip: 'Última'
                                    }
                                }}
                                components={{
                                    Toolbar: props => (
                                        <div className="row">
                                            <div className="col col-1-auto">
                                                <MTableToolbar {...props} style={{ paddingLeft: 0 }} />
                                            </div>
                                            <div style={{ marginTop: 12, marginRight: 17 }}>
                                                <Button variant="contained" color="primary" onClick={() => { this.props.history.push('/agreements/add'); }}>
                                                    Añadir
                                                </Button>
                                            </div>
                                        </div>
                                    ),
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}