import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import StepConnector from '@material-ui/core/StepConnector';
import { CreateAddOnForm } from './CreateAddOnForm';
import { RelatedAddOns } from './RelatedAddOns';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    button: {
        marginRight: theme.spacing(1)
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

const useQontoStepIconStyles = makeStyles({
    root: {
        color: 'rgba(0, 0, 0, 0.55)',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        cursor: 'pointer',
        fontSize: 16,
    },
    active: {
        color: '#008200',
        fontWeight: 'bold'
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        color: 'rgb(0,176,0)',
        zIndex: 1
    },
});

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundColor: '#668592'
        },
    },
    completed: {
        '& $line': {
            backgroundColor: '#668592'
        },
    },
    line: {
        height: 1,
        border: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.20)',
        margin: '0px 16px'
    },
})(StepConnector);

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed
            })}
        >
            {completed ? <Check className={classes.completed} /> : <text>{props.icon}</text>}
        </div>
    );
}

function getSteps() {
    return ['AddOn', 'Requeridos', 'Incompatibles'];
}

export default function CreateAddonStepper(props) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [firstStepCompleted, setFirstStepCompleted] = React.useState(props.uuid);
    const [uuid, setUuid] = React.useState(props.uuid);
    const steps = getSteps();

    function handleNext(stepUuid) {
        setFirstStepCompleted(true);
        setUuid(stepUuid);
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    }

    const handleStep = step => () => {
        if (firstStepCompleted)
            setActiveStep(step);
    };

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} connector={<ColorlibConnector />} >
                {steps.map((label, index) => (
                    <Step key={label} onClick={handleStep(index)} style={{ cursor: 'pointer' }}>
                        <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div className="content">
                {activeStep === 0 && (
                    <CreateAddOnForm uuid={uuid} auth={props.auth} history={props.history} handlerLoading={props.handlerLoading} setStatus={props.setStatus} submitCallback={handleNext}></CreateAddOnForm>
                )}
                {activeStep === 1 && (
                    <RelatedAddOns uuid={uuid} source={'addons'} target={'requirements'} targetText={'requerido'} auth={props.auth} history={props.history} handlerLoading={props.handlerLoading} setStatus={props.setStatus} submitCallback={handleNext}>}</RelatedAddOns>
                )}
                {activeStep === 2 && (
                    <RelatedAddOns uuid={uuid} source={'addons'} target={'incompatibilities'} targetText={'incompatible'} auth={props.auth} history={props.history} handlerLoading={props.handlerLoading} setStatus={props.setStatus} submitCallback={handleNext}>}</RelatedAddOns>
                )}
            </div>
        </div>

    );
}