import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import ie11EventPolyfill from './functions/ie11EventPolyfill';
import reportWebVitals from './reportWebVitals';

const theme = createMuiTheme({
    typography: {
        fontFamily: 'opensans-regular, adellesanssage, Helvetica, Arial, sans-serif',
        h1: {
            fontFamily: 'adellesanssage, opensans-regular, Helvetica, Arial, sans-serif'
        },
        h2: {
            fontFamily: 'adellesanssage, opensans-regular, Helvetica, Arial, sans-serif'
        },
        h3: {
            fontFamily: 'adellesanssage, opensans-regular, Helvetica, Arial, sans-serif'
        },
        h4: {
            fontFamily: 'adellesanssage, opensans-regular, Helvetica, Arial, sans-serif'
        },
        h5: {
            fontFamily: 'adellesanssage, opensans-regular, Helvetica, Arial, sans-serif'
        }
    },
    palette: {
        common: {
            black: "rgba(0, 0, 0, 0.9)",
            white: "#fff"
        },
        background: {
            paper: "#fff",
            default: "#fafafa"
        },
        primary: {
            light: "#000",
            main: "#000",
            dark: "#000",
            contrastText: "#fff"
        },
        secondary: {
            light: "#ff4081",
            main: "#f50057",
            dark: "#c51162",
            contrastText: "#fff"
        },
        error: {
            light: "#e57373",
            main: "rgba(199, 56, 79, 1)",
            dark: "#d32f2f",
            contrastText: "#fff"
        },
        text: {
            primary: "rgba(0, 0, 0, 0.9)",
            secondary: "rgba(0, 0, 0, 0.7)",
            disabled: "rgba(0, 0, 0, 0.38)",
            hint: "rgba(0, 0, 0, 0.38)"
        }
    }
});

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <BrowserRouter basename={baseUrl}>
                    <App />
                </BrowserRouter>,
                </MuiPickersUtilsProvider>
        </ThemeProvider>        
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
