import React, { Fragment } from 'react';
import { CreateSimulationForm } from './CreateSimulationForm';
import { PageHeader } from '../PageHeader';

export class CreateSimulationPage extends React.Component {

    render() {
        return (
            <Fragment>
                <PageHeader title="Crear simulación" text="Esta página permite crear una simulación" />
                <p>
                    <CreateSimulationForm auth={this.props.auth} handlerLoading={this.props.handlerLoading} setStatus={this.props.setStatus} history={this.props.history}></CreateSimulationForm>
                </p>
            </Fragment>
        );
    }
}