import React, { Fragment } from 'react';
import { CreateRecommendationForm } from './CreateRecommendationForm';
import { PageHeader } from '../PageHeader';

export class CreateRecommendationPage extends React.Component {

    render() {
        return (
            <Fragment>
            <PageHeader title="Crear recomendación" text="Esta página permite crear una recomendación" />
                <p>
                    <CreateRecommendationForm auth={this.props.auth} handlerLoading={this.props.handlerLoading} setStatus={this.props.setStatus} history={this.props.history}></CreateRecommendationForm>
                </p>
            </Fragment>
        );
    }
}