import React, { Fragment, useState, useEffect } from 'react';
import { matchPath } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '../Button';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import moment from 'moment';
import handleResponse from '../.././functions/handleResponse';
import handleDiagnosis from '../.././functions/handleDiagnosis';
import { PageHeader } from '../PageHeader';


const inputTextStyle = {
	width: '90%'
};

const labelStyle = {
    marginTop: 15
};

export class InstallationDetail extends React.Component {

	installationUuid = null;

	toolbarOptions = {
        options: ['inline', 'fontSize', 'list', 'textAlign', 'link', 'history'],
        inline: {
            options: ['bold', 'italic', 'underline']
        },
        list: {
            options: ['unordered', 'ordered']
        }
    }

	getParams = pathname => {
        const matchProfile = matchPath(pathname, {
            path: `/installations/detail/:installationUuid`,
        });
        return (matchProfile && matchProfile.params) || {};
	}

	constructor(props) {

		super();
		
		let params = this.getParams(props.history.location.pathname);

		this.installationUuid = (params && params.installationUuid) ? params.installationUuid : undefined;

		this.state = {
			editorState: EditorState.createEmpty(),
			installation: {
				installation_id: '',
				consent_id: '',
				collection_name: '',
				agreement_signed: false,
				info_required: '',
				updated_at: ''
			}
		};

		this.onEditorStateChange = (editorState) => {
            this.setState({ editorState: editorState });
        }
	}

	onContentStateChange = (contentState) => {
        let info_required = draftToHtml(contentState);
        let installation = this.state.installation;
        installation.info_required = encodeURIComponent(info_required);
        this.setState({ installation: installation });
	};
	
	handlePastedText = (text, html) => {
        return false;
    }

	loadInstallation = response => {
		this.setState({ installation: response });

		const str_info_required = JSON.stringify(this.state.installation.info_required, null, '\t');
		const contentState = ContentState.createFromText(str_info_required);
		const newEditorState = EditorState.createWithContent(contentState);
		this.setState({ editorState: newEditorState });

		this.props.handlerLoading(false);
	}

	componentDidMount() {
		
		this.props.handlerLoading(true);
        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 3;

        fetch("/api/installations/" + this.installationUuid,
            {
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, false))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.loadInstallation(response))
            .catch(error => console.log(error));

    }

	render() {
		return (
			<Fragment>
				<PageHeader title="Detalle de instalación" text="Esta página permite consultar los detalles de la instalación" />
				<ValidatorForm
					ref="form"
					onSubmit={this.handleSubmit}
					onError={errors => console.log(errors)}
				>
					<div className="content">
						<Grid container spacing={1}>
							<Grid item xs={2} wrap="nowrap">
								<label style={labelStyle}>Installation Id</label>
							</Grid>
							<Grid item xs={10}>
								<TextValidator
									style={ inputTextStyle }
									margin="dense"
									variant="outlined"
									label="Installation Id"
									name="installation_id"
									value={this.state.installation.installation_id}
									InputProps={{
										readOnly: true,
									}}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={1}>
							<Grid item xs={2} wrap="nowrap">
								<label style={labelStyle}>Consent Id</label>
							</Grid>
							<Grid item xs={10}>
								<TextValidator
									style={ inputTextStyle }
									margin="dense"
									variant="outlined"
									label="Consent Id"
									name="consent_id"
									value={this.state.installation.consent_id}
									InputProps={{
										readOnly: true,
									}}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={1}>
							<Grid item xs={2} wrap="nowrap">
								<label style={labelStyle}>Colección</label>
							</Grid>
							<Grid item xs={10}>
								<TextValidator
									style={ inputTextStyle }
									margin="dense"
									variant="outlined"
									label="Colección"
									name="collection_name"
									value={this.state.installation.collection_name}
									InputProps={{
										readOnly: true,
									}}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={1}>
							<Grid item xs={2} wrap="nowrap">
								<label style={labelStyle}>Acuerdo firmado</label>
							</Grid>
							<Grid item xs={10}>
								<Checkbox
									checked={this.state.installation.agreement_signed}
									value="agreement_signed"
									color="primary"
									InputProps={{
										readOnly: true,
									}}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={1}>
                        <Grid style={{ fontWeight: 'bold' }} item xs={12}>
							<label style={labelStyle}>Última telemetría recibida: {moment(this.state.installation.updated_at).local().format('DD-MM-YYYY HH:mm:ss')}</label>
                        </Grid>
						</Grid>
                        <Grid item xs={11} className="jsonTextAreaContainer">
                            <Editor
								editorState={this.state.editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={this.onEditorStateChange}
                                onContentStateChange={this.onContentStateChange}
                                toolbar={this.toolbarOptions}
								handlePastedText={this.handlePastedText}
								toolbarHidden
                                readOnly
                            />
                        </Grid>
						<Grid item xs={11} style={{ marginTop: '5px', textAlign: 'right' }} >
							<Button type="submit" variant="contained" color="primary" onClick={()=>this.props.history.goBack()}>Volver</Button>
						</Grid>
					</div>
				</ValidatorForm>
			</Fragment>
		);
	}

}