function handleResponse(response, handlerLoading, setStatus, auth, setStatusOn200, finishLoading) {
    if (!response.ok) {
        switch (response.status) {
            case 401:
                auth.logout();
                break;
            case 403:
                setStatus("error", "No tiene permisos para acceder al recurso solicitado");
                break;
            default:
                break;
        }
    } else {
        switch (response.status) {
            case 200:
                if (setStatusOn200 !== 'undefined')
                    GetMessage(setStatus, setStatusOn200);
                break;
            case 201:
                setStatus("success", "Añadido con éxito");
                break;
            case 204:
                if (setStatusOn200 !== 'undefined')
                    GetMessage(setStatus, setStatusOn200);
                else
                    setStatus("success", "Guardado con éxito");
                break;
            default:
                break;
        }
    }

    if (finishLoading === undefined || finishLoading === true) {
        handlerLoading(false);
    }
    
    return response;
}

function GetMessage(setStatus, setStatusOn200) {
    //Values: 0 -> POST, 1 -> PUT, 2 -> DELETE, 3 -> GET
    switch (setStatusOn200) {
        case 1:
            setStatus("success", "Modificado con éxito");
            break;
        case 2:
            setStatus("success", "Borrado con éxito");
            break;
        default:
            break;
    }
}

export default handleResponse;
