import React, { Component, Fragment } from 'react';
import AuthService from './AuthService';
import { Switch, Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import { AttributesListPage } from './components/Attributes/AttributesListPage';
import { AttributeTypesListPage } from './components/AttributeTypes/AttributeTypesListPage';
import { AttributeTypeAddPage } from './components/AttributeTypes/AttributeTypeAddPage';
import { UpdateAttributeTypePage } from './components/AttributeTypes/UpdateAttributeTypePage';
import { AddOnsListPage } from './components/AddOns/AddOnsListPage';
import { CreateAddOnPage } from './components/AddOns/CreateAddOnPage';
import { UpdateAddOnPage } from './components/AddOns/UpdateAddOnPage';
import { CreateAttributePage } from './components/Attributes/CreateAttributePage';
import { DataListPage } from './components/Data/DataListPage';
import { AgreementsListPage } from './components/Agreements/AgreementsListPage';
import { CreateAgreementPage } from './components/Agreements/CreateAgreementPage';
import { UpdateAgreementPage } from './components/Agreements/UpdateAgreementPage';
import { RecommendationsListPage } from './components/Recommendations/RecommendationsListPage';
import { CreateRecommendationPage } from './components/Recommendations/CreateRecommendationPage';
import { UpdateRecommendationPage } from './components/Recommendations/UpdateRecommendationPage';
import { LegalNewsListPage } from './components/LegalNews/LegalNewsListPage';
import { CreateLegalNewsPage } from './components/LegalNews/CreateLegalNewsPage';
import { UpdateLegalNewsPage } from './components/LegalNews/UpdateLegalNewsPage';
import { SageNewsListPage } from './components/SageNews/SageNewsListPage';
import { CreateSageNewsPage } from './components/SageNews/CreateSageNewsPage';
import { UpdateSageNewsPage } from './components/SageNews/UpdateSageNewsPage';
import { ScreensListPage } from './components/Screens/ScreensListPage';
import { CreateScreenPage } from './components/Screens/CreateScreenPage';
import { UpdateScreenPage } from './components/Screens/UpdateScreenPage';
import { SimulationsListPage } from './components/Simulations/SimulationsListPage';
import { CreateSimulationPage } from './components/Simulations/CreateSimulationPage';
import { UpdateSimulationPage } from './components/Simulations/UpdateSimulationPage';
import { EmptyCachePage } from './components/Cache/EmptyCachePage';
import { InstallationsListPage } from './components/Installations/InstallationsListPage';
import { InstallationDetail } from './components/Installations/InstallationDetail';
import Notification from './components/Notification';
import './App.css';
import logo from './images/sage-group-logo-vector.svg';
import LoadingOverlay from 'react-loading-overlay';
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { LogoutButton } from './components/LogoutButton';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';

class App extends Component {
    constructor() {
        super();
        this.authService = new AuthService();
        this.state = { loading: false, status: null, notifications: [], newErrors: 0, drawerOpen: false, loadings: 0 };
        this.handlerLoading = this.handlerLoading.bind(this);
        this.handlerStatus = this.handlerStatus.bind(this);
        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.handlerClearNotifications = this.handlerClearNotifications.bind(this);
    }

    handlerLoading(loading) {
        var self = this;
        var loadings = this.state.loadings;

        if (loading) {
            loadings = loadings + 1;
        } else {
            loadings = loadings - 1;
        }

        var isLoading = loadings > 0;

        if (!isLoading) {
            setTimeout(function () {
                self.setState({ loading: isLoading, loadings: loadings });
            },
                800);
        } else {
            this.setState({ loading: isLoading, loadings: loadings });
        }
    }

    handlerClearNotifications() {
        this.setState({ notifications: [] });
    }

    handlerStatus(status, msg) {
        var newStatus = { status: status, msg: msg, date: new Date() };
        var notifications = this.state.notifications;
        var newErrors = this.state.newErrors;

        if (status !== 'success') {
            notifications.push(newStatus);
            newErrors = newErrors + 1;

        }

        this.setState({ status: newStatus, notifications: notifications, newErrors: newErrors });

        if (status !== 'success' && msg.toLowerCase().includes('no tiene permisos')) {
            this.handlerLoading(true);
        }
    }

    renderHome(history) {
        let resultComponent = <AddOnsListPage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />
        if (!this.authService.isAuthenticated()) {
            this.authService.handleAuthentication(history);
        }

        if (this.authService.getUserRole() === 'PO') {
            resultComponent = <LegalNewsListPage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />
        }

        if (this.authService.getUserRole() === 'SC') {
            resultComponent = <SageNewsListPage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />
        }

        if (this.authService.getUserRole() === '') {
            resultComponent = <div><p><h4>Comprobando sus permisos.</h4></p></div>
        }
        return resultComponent;

    }

    toggleDrawer() {
        this.setState({
            drawerOpen: !this.state.drawerOpen,
            newErrors: 0
        });
    };

    render() {
        return (
            <LoadingOverlay
                active={this.state.loading}
                spinner={<p><Typography className="overlay-spinner rotating"></Typography></p>}
                text='&nbsp;Cargando . . .'
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(250, 250, 250, 0.5)',
                        color: '#000',
                        fontSize: '150%',
                        height: '100%',
                        position: 'fixed'
                    })
                }}
            >
                <div className="App">
                    <header className="App-header">
                        <div className="logo-container">
                            <div className="div-logo">
                                <svg height="24" className="logo" alt="sage" /><label className="App-title">Sage 200c Store Backoffice</label>
                            </div>
                            {this.authService.isAuthenticated() && (
                                <div className="logout">
                                    <IconButton variant="contained" size="medium" onClick={this.toggleDrawer}>
                                        <Badge
                                            color="secondary"
                                            badgeContent={this.state.newErrors}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right'
                                            }}
                                        >
                                            <NotificationsIcon style={{ color: '#FFF' }} />
                                        </Badge>
                                    </IconButton>
                                    <LogoutButton auth={this.authService} />
                                </div>
                            )}
                        </div>
                    </header>
                    <Layout auth={this.authService} status={this.state} toggleDrawer={this.toggleDrawer}>
                        <Switch>
                            <Route path="/swagger" render={() => <SwaggerUI url="/swagger/v1/swagger.json" docExpansion="list" />} />
                            <Route exact path="/" render={({ history }) => this.renderHome(history)} />
                            <Route path="/addons/add" render={({ history }) => <CreateAddOnPage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />} />
                            <Route path="/addons/update/:addonUuid" render={({ history }) => <UpdateAddOnPage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />} />
                            <Route path="/addons" render={({ history }) => <AddOnsListPage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />} />
                            <Route path="/enums/add" render={({ history }) => <AttributeTypeAddPage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />} />
                            <Route path="/enums/update/:typeUuid" render={({ history }) => <UpdateAttributeTypePage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />} />
                            <Route path="/enums" render={({ history }) => <AttributeTypesListPage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />} />
                            <Route path="/attributes/add" render={({ history }) => <CreateAttributePage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />} />
                            <Route path="/attributes" render={({ history }) => <AttributesListPage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />} />
                            <Route path="/data" render={({ history }) => <DataListPage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />} />
                            <Route path="/agreements/add" render={({ history }) => <CreateAgreementPage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />} />
                            <Route path="/agreements/update/:agreementUuid" render={({ history }) => <UpdateAgreementPage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />} />
                            <Route path="/agreements" render={({ history }) => <AgreementsListPage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />} />
                            <Route path="/recommendations/add" render={({ history }) => <CreateRecommendationPage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />} />
                            <Route path="/recommendations/update/:recommendationUuid" render={({ history }) => <UpdateRecommendationPage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />} />
                            <Route path="/recommendations" render={({ history }) => <RecommendationsListPage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />} />
                            <Route path="/legalnews/add" render={({ history }) => <CreateLegalNewsPage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />} />
                            <Route path="/legalnews/update/:legalNewsUuid" render={({ history }) => <UpdateLegalNewsPage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />} />
                            <Route path="/legalnews" render={({ history }) => <LegalNewsListPage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />} />
                            <Route path="/sagenews/add" render={({ history }) => <CreateSageNewsPage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />} />
                            <Route path="/sagenews/update/:sageNewsUuid" render={({ history }) => <UpdateSageNewsPage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />} />
                            <Route path="/sagenews" render={({ history }) => <SageNewsListPage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />} />
                            <Route path="/screens/add" render={({ history }) => <CreateScreenPage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />} />
                            <Route path="/screens/update/:screenUuid" render={({ history }) => <UpdateScreenPage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />} />
                            <Route path="/screens" render={({ history }) => <ScreensListPage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />} />
                            <Route path="/simulations/add" render={({ history }) => <CreateSimulationPage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />} />
                            <Route path="/simulations/update/:simulationUuid" render={({ history }) => <UpdateSimulationPage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />} />
                            <Route path="/simulations" render={({ history }) => <SimulationsListPage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />} />
                            <Route path="/simulations/add" render={({ history }) => <CreateSimulationPage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />} />
                            <Route path="/cache" render={({ history }) => <EmptyCachePage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />} />
                            <Route path="/installations/detail/:installationUuid" render={({ history }) => <InstallationDetail history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />} />
                            <Route path="/installations" render={({ history }) => <InstallationsListPage history={history} auth={this.authService} handlerLoading={this.handlerLoading} setStatus={this.handlerStatus} />} />
                        </Switch>
                    </Layout>

                    <Drawer anchor="right" open={this.state.drawerOpen} onClose={this.toggleDrawer}>
                        <div
                            style={{ width: 250 }}
                            role="presentation"
                        >
                            {this.state.notifications.length !== 0 &&
                                <div style={{ textAlign: 'right' }}>
                                    <Link
                                        component="button"
                                        variant="body2"
                                        onClick={this.handlerClearNotifications}
                                    >
                                        Borrar todas
                                        </Link>
                                </div>
                            }
                            <List>
                                {this.state.notifications.map((notification, index) => (
                                    <Fragment>
                                        <ListItem key={notification.msg}>
                                            <ListItemText primary={notification.msg}></ListItemText>
                                        </ListItem>
                                        <Divider></Divider>
                                    </Fragment>
                                ))}
                                {this.state.notifications.length === 0 &&
                                    <ListItem key="No hay notificaciones">
                                        <ListItemText primary="No hay notificaciones"></ListItemText>
                                    </ListItem>
                                }
                            </List>
                        </div>
                    </Drawer>



                    {this.state.status ? <Notification key={this.state.status.date} status={this.state.status.status} msg={this.state.status.msg} /> : null}
                </div>
            </LoadingOverlay>
        );
    }
}

export default App;