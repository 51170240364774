import React, { Fragment } from 'react';
import Divider from '@material-ui/core/Divider';
import { DividerPadding } from './DividerPadding';

export class PageHeader extends React.Component {

    render() {
        return (
            <Fragment>
                <DividerPadding top="15" />
                <div>
                    <h3>
                        {this.props.title}
                    </h3>
                </div>
                <div>
                    <label>{this.props.text}</label>
                </div>
                <DividerPadding top="15" />
                <Divider />
                <DividerPadding top="15" />
            </Fragment>
        )
    }
}