import * as AuthenticationContext from 'adal-vanilla/lib/adal'

// store the ADAL config:
window.adalConfig = {
    clientId: process.env.REACT_APP_AAD_CLIENTID,
    tenant: process.env.REACT_APP_AAD_TENANT,
    cacheLocation: 'sessionStorage',
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    extraQueryParameter: 'prompt=select_account',
    popUp: false
};

export default class AuthService {
    authContext = new AuthenticationContext(window.adalConfig);

    handleAuthentication(history) {
        if (this.authContext.isCallback(window.location.hash)) {
            // this handles the redirect back from the AAD sign-in page.
            // it extracts the hash and processes the AAD token (or error) received.
            this.authContext.handleWindowCallback();
        }

        var user = this.authContext.getCachedUser();

        if (user) {
            let clientId = window.adalConfig.clientId;
            this.authContext.acquireToken(clientId, function (errorDesc, token, error) {
                if (error) {
                    // acquire token failure
                    // In this case the callback passed in the Authentication request constructor will be called.
                    this.authContext.acquireTokenRedirect(clientId, null, null);
                }
                else {
                    //acquired token successfully
                    sessionStorage.setItem('access_token', token);
                    history.push("/");
                }
            });
        }
        else {
            // Initiate login
            this.authContext.login();
        }
    }

    isAuthenticated() {
        var user = this.getProfile()
        if (user && this.getAccessToken()) {
            return true;
        }
        return false;
    }

    getProfile() {
        return this.authContext.getCachedUser();
    }

    getUserRole() {
        var user = this.authContext.getCachedUser();
        if (!user || !user.profile || !user.profile.roles || user.profile.roles.length === 0) {
            return '';
        }
        else {
            return user.profile.roles[0];
        }
    }

    logout() {
        sessionStorage.removeItem('access_token');
        this.authContext.logOut();
    }

    getAccessToken() {
        return sessionStorage.getItem('access_token');
    }
}