import React, { Fragment } from 'react';
import Button from '../Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import handleResponse from '../.././functions/handleResponse';
import handleDiagnosis from '../.././functions/handleDiagnosis';
import AddIcon from '@material-ui/icons/Add';
import Divider from '@material-ui/core/Divider';
import { ConfirmDialog } from '.././ConfirmDialog';

const labelStyle = {
    marginTop: 15
};
const imputTextStyle = {
    width: '100%'
};


export class AttributeTypeValuesForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            values: [],
            open: false,
            newValue: {
                uuid: '',
                value: '',
                type_uuid: this.props.typeUuid
            },
            delete_uuid: ''
        }

        this.fetchData = this.fetchData.bind(this);
        this.setData = this.setData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onAdded = this.onAdded.bind(this);
        this.onDeleteValueClick = this.onDeleteValueClick.bind(this);
        this.delete = this.delete.bind(this);
        this.handleClose = this.handleClose.bind(true);
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        this.props.handlerLoading(true);

        if (this.props.typeUuid && this.props.typeUuid !== '' && this.props.typeUuid !== null) {
            const accessToken = this.props.auth.getAccessToken();
            var setStatusOn200 = 3;

            fetch("/api/attributetypes/" + this.props.typeUuid + "/values",
                {
                    headers: new Headers({
                        "Accept": "application/json",
                        "Authorization": `Bearer ${accessToken}`
                    })
                })
                .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, true))
                .then(response => response.json())
                .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.setData(response))
                .catch(error => console.log(error));
        }
        else
            this.props.handlerLoading(false);
    }

    setData(values) {
        this.setState({ values: values });
    }

    handleChange = (event) => {
        const value = event.target.value;
        this.setState({
            values: this.state.values,
            newValue: {
                uuid: '',
                value: value,
                type_uuid: this.props.typeUuid
            }
        });
    }

    handleSubmit = (event) => {
        this.props.handlerLoading(true);

        event.preventDefault();
        const accessToken = this.props.auth.getAccessToken();
        const self = this;
        var setStatusOn200 = 0;

        fetch("/api/attributetypes/" + this.props.typeUuid + "/values",
            {
                method: 'POST',
                headers: new Headers({
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                }),
                body: JSON.stringify(self.state.newValue)
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, true))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.onAdded(response))
            .catch(error => console.log(error));
    }

    onAdded(attributeTypeValue) {
        var values = this.state.values;
        values.push(attributeTypeValue);
        this.setState({
            values: values,
            newValue: {
                uuid: '',
                value: '',
                type_uuid: this.props.typeUuid
            }
        });
    }

    onDeleteValueClick(uuid, index) {
        this.setState({ delete_uuid: uuid, open: true });
    }

    delete = (uuid) => {
        this.props.handlerLoading(true);
        const accessToken = this.props.auth.getAccessToken();
        const self = this;
        var setStatusOn200 = 2;

        fetch("/api/attributetypevalues/" + self.state.delete_uuid,
            {
                method: 'DELETE',
                headers: new Headers({
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, self.props.handlerLoading, self.props.setStatus, self.props.auth, setStatusOn200, true))
            .then(response => !response.ok ? response.json() : '')
            .then(response => response.diagnosis ? handleDiagnosis(response, self.props.setStatus, self.props.handlerLoading) : self.fetchData())
            .catch(err => {
                console.error(err);
            });
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    render() {
        return (
            <Fragment>
                {!this.props.readOnly && (
                    <ValidatorForm
                        ref="form"
                        onSubmit={this.handleSubmit}
                        onError={errors => console.log(errors)}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={1} wrap="nowrap">
                                <label style={labelStyle}>Valor</label>
                            </Grid>
                            <Grid item xs={5} wrap="nowrap">
                                <TextValidator
                                    style={imputTextStyle}
                                    label="Valor"
                                    margin="dense"
                                    variant="outlined"
                                    onChange={this.handleChange}
                                    name="valor"
                                    value={this.state.newValue.value}
                                    validators={['required', 'maxStringLength: 64', 'trim']}
                                    errorMessages={['Este campo es obligatorio', 'La longitud máxima de este campo es de 64 caracteres', 'Este campo es obligatorio']}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Button type="submit" variant="contained" color="primary" size="medium" style={{ marginTop: '9px' }}>
                                    <AddIcon />
                                </Button>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                )}
                <div><br /></div>
                <Grid item xs={12} md={3}>
                    {this.state.values.length > 0 && (
                        <Typography variant="h6">
                            Valores de enumerado
                    </Typography>
                    )}
                    <div>
                        <List dense="true">
                            {this.state.values.map((value, i) => {
                                return (<Fragment><ListItem>
                                    <ListItemText
                                        primary={value.value}
                                    />
                                    {!this.props.readOnly && (
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" aria-label="delete" onClick={() => this.onDeleteValueClick(value.uuid, i)} >
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    )}
                                </ListItem> <Divider /></Fragment>)
                            })}
                        </List>
                    </div>
                </Grid>
                <ConfirmDialog
                    open={this.state.open}
                    onAgree={() => this.delete(this.state.delete_uuid)}
                    title="¿Deseas continuar?"
                    text="Se va a eliminar el valor. ¿deseas continuar?"
                    handleClose={this.handleClose}
                />
            </Fragment>
        );
    }
}