import React, { Fragment } from 'react';
import handleResponse from '../.././functions/handleResponse'
import handleDiagnosis from '../.././functions/handleDiagnosis'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '../Button';
import Grid from '@material-ui/core/Grid';
import { formatQuery } from "react-querybuilder";
import SelectAutoComplete from '../../functions/SelectAutoComplete';
import { AttributesQueryBuilder } from '.././AttributesQueryBuilder';
import * as queryBuilderFunctions from '.././AttributesQueryBuilder';
import { CreateDialog } from '../Data/CreateDialog';
import AddIcon from '@material-ui/icons/Add';

const imputTextStyle = {
    width: '90%'
};

const labelStyle = {
    marginTop: 15
};

export class CreateSimulationForm extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onAdded = this.onAdded.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onSelectCollection = this.onSelectCollection.bind(this);
        this.handleQueryChange = this.handleQueryChange.bind(true);
        this.handleOpen = this.handleOpen.bind(true);
        this.handleClose = this.handleClose.bind(true);

        this.state = {
            simulation: {
                uuid: '',
                collection_uuid: '',
                name: '',
                acquired_addons: '',
                query: null
            },
            newPayload: {
                AddOns: [],
                SegmentationData: {}
            },
            collections: [],
            attributesList: [],
            attributeTypesList: [],
            open: false
        }
    }

    componentDidMount() {
        this.fetchCollectionsData();
    }

    fetchCollectionsData() {
        this.props.handlerLoading(true);
        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 3;

        fetch("/api/collections",
            {
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, false))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.setCollectionsData(response))
            .catch(error => console.log(error));
    }

    setCollectionsData = (simulations) => {
        var collectionsList = simulations.map(simulations => ({
            value: simulations.uuid,
            label: simulations.name
        }));

        this.setState({ collections: collectionsList });
        this.fetchAttributes();
    }

    fetchAttributes() {
        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 3;

        fetch("/api/attributes?scope=segmentation",
            {
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, false))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.setAttributesData(response))
            .catch(error => console.log(error));
    }

    setAttributesData = (attributes) => {

        var attributesList = attributes.map(attribute => ({
            name: attribute.name,
            label: attribute.name,
            id: attribute.uuid,
            attribute_type_uuid: attribute.attribute_type_uuid
        }));

        this.setState({ attributesList: attributesList });

        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 3;

        fetch("/api/attributetypes",
            {
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, false))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.setAttributeTypesData(response))
            .catch(error => console.log(error));
    }

    setAttributeTypesData = (attributeTypes) => {
        this.setState({ attributeTypesList: attributeTypes });
        this.fetchSimulationsData();
    }

    fetchSimulationsData = () => {
        if (this.props.uuid) {
            const accessToken = this.props.auth.getAccessToken();
            var setStatusOn200 = 3;

            fetch("/api/simulations/" + this.props.uuid,
                {
                    headers: new Headers({
                        "Accept": "application/json",
                        "Authorization": `Bearer ${accessToken}`
                    })
                })
                .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, true))
                .then(response => response.json())
                .then(response => response.diagnosis
                    ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading)
                    : this.setData(response))
                .catch(error => console.log(error));
        }
        else {
            this.props.handlerLoading(false);
        }
    }

    setData = (simulation) => {
        if (simulation.simulation_attribute_query && simulation.simulation_attribute_query !== null && simulation.simulation_attribute_query !== "") {
            simulation.query = JSON.parse(simulation.simulation_attribute_query);
        } else {
            simulation.query = null;
        }

        if (simulation.payload.AddOns && simulation.payload.AddOns.length > 0)
            simulation.acquired_addons = simulation.payload.AddOns.toString();

        this.setState({ simulation: simulation });
    }

    handleSubmit = (event) => {
        this.props.handlerLoading(true);

        const accessToken = this.props.auth.getAccessToken();
        const self = this;
        let simulation = self.state.simulation;

        var newPayload = self.state.newPayload;

        if (simulation.simulation_attribute) {
            if (simulation.simulation_attribute === "()") {
                simulation.simulation_attribute = null;
                simulation.simulation_attribute_query = null;
                this.setState({ simulation: simulation });
            } else if (simulation.simulation_attribute.includes(" \"\"") ||
                simulation.simulation_attribute.includes("== &&") ||
                simulation.simulation_attribute.includes("== ||") ||
                simulation.simulation_attribute.includes("==)")) {
                this.props.setStatus("error", 'Todas las reglas deben tener un valor.');
                this.props.handlerLoading(false);
                return false;
            }

            if (simulation.simulation_attribute !== '' && simulation.simulation_attribute !== null) {
                var attributes = simulation.simulation_attribute
                    .replace(/\s+/g, '')
                    .replace(/[^a-zA-Z0-9&&==]/g, "").split('&&');

                for (var i = 0; i < attributes.length; i++) {
                    var values = attributes[i].split('==');

                    newPayload.SegmentationData[values[0]] = values[1];
                }
            }
        }

        if (simulation.acquired_addons !== undefined && simulation.acquired_addons !== '')
            newPayload.AddOns = simulation.acquired_addons
                .split(',')
                .map(function (item) {
                    return item.trim();
                });

        simulation.payload = newPayload;

        var method = 'POST';
        var url = '/api/simulations';
        var setStatusOn200 = 0;

        if (simulation.uuid && simulation.uuid !== '' && simulation.uuid !== null) {
            method = 'PUT';
            url = '/api/simulations/' + simulation.uuid;
            setStatusOn200 = 1;
        }

        fetch(url,
            {
                method: method,
                headers: new Headers({
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                }),
                body: JSON.stringify(simulation)
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, false))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : (this.onAdded(response), this.props.handlerLoading(false)))
            .catch(error => console.log(error));
    }

    myFunction(item, index) {
        document.getElementById("demo").innerHTML += index + ":" + item + "<br>";
    }

    onAdded = (simulation) => {
        this.props.history.push('/simulations');
    }

    handleChange = (event) => {
        let simulation = this.state.simulation;
        var newValue = event.target.value;
        simulation[event.target.name] = newValue;
        this.setState({ simulation: simulation });
    }

    onSelectCollection(collectionUuid) {
        var simulation = this.state.simulation;
        simulation.collection_uuid = collectionUuid;
        this.setState({ simulation: simulation });
    }

    handleQueryChange = (query) => {
        let simulation = this.state.simulation;
        simulation.query = query;

        const valueProcessor = queryBuilderFunctions.default(this.state.attributeTypesList, this.state.attributesList);

        var formatedQuery = this.state.simulation.query != null ? formatQuery(this.state.simulation.query, { format: 'sql', valueProcessor }) : '';
        var formatedQueryRaw = this.state.simulation.query != null ? formatQuery(this.state.simulation.query, { format: 'JSON' }) : '';

        simulation.simulation_attribute = formatedQuery;
        simulation.simulation_attribute_query = formatedQueryRaw;
        this.setState({ simulation: simulation });
    };

    handleOpen = () => {
        this.setState({ open: true });
    }

    handleClose = (response) => {

        if (response) {
            var newCollection =
            {
                value: response.uuid,
                label: response.name
            }
            let collections = this.state.collections;
            let simulation = this.state.simulation;
            collections.push(newCollection);
            simulation.collection_uuid = response.uuid;
            this.setState({ collections: collections, simulation: simulation });
        }
        this.setState({ open: false });
    }

    render() {
        return (
            <Fragment>
                <div className="content">
                    <CreateDialog
                        open={this.state.open}
                        handleClose={this.handleClose}
                        handleOnAdded={this.handleClose}
                        entity={{ name: 'colección', type: 'collections' }}
                        handlerLoading={this.props.handlerLoading}
                        setStatus={this.props.setStatus}
                        auth={this.props.auth}
                    >
                    </CreateDialog>
                    <ValidatorForm
                        ref="form"
                        onSubmit={this.handleSubmit}
                        onError={errors => console.log(errors)}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={2} wrap="nowrap">
                                <label style={labelStyle}>Nombre</label>
                            </Grid>
                            <Grid item xs={10}>
                                <TextValidator
                                    style={imputTextStyle}
                                    margin="dense"
                                    variant="outlined"
                                    label="Nombre"
                                    name="name"
                                    value={this.state.simulation.name}
                                    onChange={this.handleChange}
                                    validators={['required', 'maxStringLength: 32', 'trim']}
                                    errorMessages={['Este campo es obligatorio', 'La longitud máxima de este campo es de 32 caracteres', 'Este campo es obligatorio']}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} style={{ paddingBottom: '25px' }}>
                            <Grid item xs={2}>
                                <label style={labelStyle}>Colección</label>
                            </Grid>
                            <Grid item xs="auto">
                                <SelectAutoComplete uuid={this.state.simulation.collection_uuid} suggestions={this.state.collections} onSelectSuggestion={this.onSelectCollection} placeholder="Colección" label="Colección" />
                            </Grid>
                            <Grid item xs="auto">
                                <Button onClick={this.handleOpen} variant="contained" color="primary" className="selectbutton">
                                    <AddIcon />
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <label style={labelStyle}>Addons adquiridos</label>
                            </Grid>
                            <Grid item xs={10}>
                                <TextValidator
                                    style={imputTextStyle}
                                    margin="dense"
                                    variant="outlined"
                                    label="Addons adquiridos"
                                    name="acquired_addons"
                                    value={this.state.simulation.acquired_addons}
                                    onChange={this.handleChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid className="rules-title" container spacing={1}>
                            <Grid item xs={12} wrap="nowrap">
                                <h5><strong>Reglas para la simulación</strong>: aplicadas sobre los Atributos del fichero de licencia</h5>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={11}>
                                <AttributesQueryBuilder
                                    query={this.state.simulation.query}
                                    onQueryChange={this.handleQueryChange}
                                    fields={this.state.attributesList}
                                    fieldTypes={this.state.attributeTypesList}
                                />
                            </Grid>
                        </Grid>
                        <Fragment>
                            <Grid container spacing={1}>
                                <Grid item xs={11} className="text-right">
                                    <Button type="submit" variant="contained" color="primary"> Guardar</Button>
                                </Grid>
                            </Grid>
                        </Fragment >
                    </ValidatorForm>
                </div>
            </Fragment >
        );
    }
}