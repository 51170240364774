import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

function RoundedButton(props) {
    const useStyles = makeStyles({
        roundedButton: {
            alignItems: "center",
            borderStyle: "solid",
            borderWidth: "2px",
            boxSizing: "border-box",
            boxShadow: "none",
            cursor: "pointer",
            display: "inline-flex",
            fontFamily: "'Sage Text', Arial, sans-serif",
            fontWeight: "500",
            justifyContent: "center",
            lineHeight: "1.4em",
            letterSpacing: "normal",
            margin: "0px",
            outline: "none",
            overflow: "visible",
            position: "relative",
            textAlign: "center",
            textDecoration: "none",            
            userSelect: "none",
            padding: "0px 24px",
            borderRadius: "48px",
            height: "35px",
            minWidth: "110px",
            backgroundColor: props.color == "primary" ? "rgb(0, 0, 0)" : "rgb(255, 255, 255)",
            borderColor: "rgb(0, 0, 0)",
            color: props.color == "primary" ? "rgb(255, 255, 255)" : "rgb(0, 0, 0)",
            fontSize: "16px",
            width: "auto",
            minWidth: "auto",
            "&:hover": {
                boxShadow: "none",
                transition: "background-color 0.15s linear 0s, border 0.15s linear 0s, color 0.15s linear 0s, box-shadow 0.15s linear 0s",
                backgroundColor: props.color == "primary" ? "#333333" : "rgba(0,0,0,0.1)",
                color: props.color == "primary" ? "rgb(255, 255, 255)" : "rgb(0, 0, 0)",
            },
            marginTop: props.className == "selectbutton" ? "16px" : "0px",
            textTransform: "none",
        }
    });
    const classes = useStyles();

    return (
        <Button variant={props.variant} color={props.color} onClick={props.onClick} className={classes.roundedButton} href={props.href} target={props.target} disabled={props.disabled} type={props.type}>
            {props.children}
        </Button>
    );
}

export default RoundedButton;