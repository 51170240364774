import React, { Fragment } from 'react';
import handleResponse from '../.././functions/handleResponse';
import handleDiagnosis from '../.././functions/handleDiagnosis';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '../Button';
import Grid from '@material-ui/core/Grid';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Box from '@material-ui/core/Box';
import DeleteIcon from '@material-ui/icons/Delete';
import recommendedSizeImage from '../../images/4-3.png';
import recommendedSizeSpotlightImage from '../../images/21-9.png';
import SelectAutoComplete from '../../functions/SelectAutoComplete';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import Slider from '@material-ui/core/Slider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { ConfirmDialog } from '.././ConfirmDialog';
import { formatQuery } from "react-querybuilder";
import { RulesQueryBuilder } from '.././RulesQueryBuilder';
import * as queryBuilderFunctions from '.././RulesQueryBuilder';
import { CreateDialog } from '../Data/CreateDialog';
import AddIcon from '@material-ui/icons/Add';
import CardDialog from './CardDialog';

const imputTextStyle = {
    width: '90%'
};

const labelStyle = {
    marginTop: 15
};

export class CreateAddOnForm extends React.Component {

    constructor(props) {
        super(props);
        this.handleChangeImageIcon = this.handleChangeImageIcon.bind(this);
        this.handleIconFileUploadClick = this.handleIconFileUploadClick.bind(this);
        this.handleSpotlightIconFileUploadClick = this.handleSpotlightIconFileUploadClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.removeIcon = this.removeIcon.bind(this);
        this.removeSpotlightIcon = this.removeSpotlightIcon.bind(this);
        this.onSelectCategory = this.onSelectCategory.bind(this);
        this.onSelectCollection = this.onSelectCollection.bind(this);
        this.onSelectProvider = this.onSelectProvider.bind(this);
        this.handleWeightChange = this.handleWeightChange.bind(this);
        this.onAdded = this.onAdded.bind(this);
        this.onContentStateChange = this.onContentStateChange.bind(this);
        this.handleChangeCheckedRemove = this.handleChangeCheckedRemove.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.delete = this.delete.bind(this);
        this.handleClose = this.handleClose.bind(true);
        this.handleQueryChange = this.handleQueryChange.bind(true);
        this.setAttributesData = this.setAttributesData.bind(true);
        this.setAttributeTypesData = this.setAttributeTypesData.bind(true);
        this.handleOpenCreate = this.handleOpenCreate.bind(true);
        this.handleCloseCreate = this.handleCloseCreate.bind(true);
        this.handlePrevToogle = this.handlePrevToogle.bind(true);
        this.handleCardDiagButtonClick = this.handleCardDiagButtonClick.bind(this);
        this.handleCardDiagButtonClose = this.handleCardDiagButtonClose.bind(this);
        this.tryToDecodeURIComponent = this.tryToDecodeURIComponent.bind(true);
        this.handleAddAffButtonClick = this.handleAddAffButtonClick.bind(this);
        this.state = {
            addon: {
                uuid: '',
                name: '',
                description: '',
                image: '',
                landing_url: 'https://',
                weight: 1,
                segmentation_rule: '',
                new_to: new Date(new Date().setDate(new Date().getDate() + 20)),
                valid_from: new Date(new Date().setSeconds(0, 0)),
                valid_to: new Date(new Date(new Date(new Date().setFullYear(new Date().getFullYear() + 1))).setSeconds(0, 0)),
                promoted_from: null,
                promoted_to: null,
                spotlight_from: null,
                spotlight_to: null,
                provider: '',
                provider_uuid: '',
                category: '',
                category_uuid: '',
                collection: '',
                collection_uuid: '',
                image_path: '',
                query: null,
                segmentation_rule_query: '',
                image_spotlight: '',
                image_spotlight_path: '',
                cod_active: true,
                sage_licensing_module: '',
            },
            editorState: EditorState.createEmpty(),
            categories: [],
            collections: [],
            providers: [],
            new_to_error: '',
            checkedRemove: false,
            openDeleteDialog: false,
            openCreateDialog: false,
            attributesList: [],
            attributeTypesList: [],
            createDialogEntity: { name: 'colección', type: 'collections', uuid_field: 'collection_uuid' },
            openPrev: false,
            categoryName: '',
            cardDiagOpen: false,
            cardDiagAnchorEl: null
        }

        this.onEditorStateChange = (editorState) => {
            this.setState({ editorState: editorState });
        }
    }

    componentDidMount() {
        this.fetchSelectsData();
    }

    fetchSelectsData() {
        this.props.handlerLoading(true);
        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 3;

        fetch("/api/categories",
            {
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, false))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.setCategoriesData(response))
            .catch(error => console.log(error));
    }

    setCategoriesData = (categories) => {
        var categoriesList = categories.map(category => ({
            value: category.uuid,
            label: category.name
        }));
        this.setState({ categories: categoriesList });
        this.fetchProducts();
    }

    fetchProducts() {
        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 3;

        fetch("/api/collections",
            {
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, false))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.setCollectionsData(response))
            .catch(error => console.log(error));
    }

    setCollectionsData = (products) => {
        var collectionsList = products.map(product => ({
            value: product.uuid,
            label: product.name
        }));
        this.setState({ collections: collectionsList });
        this.fetchProviders();
    }

    fetchProviders() {
        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 3;

        fetch("/api/providers",
            {
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, false))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.setProvidersData(response))
            .catch(error => console.log(error));
    }

    setProvidersData = (providers) => {
        var providersList = providers.map(provider => ({
            value: provider.uuid,
            label: provider.name
        }));
        this.setState({ providers: providersList });

        this.fetchAttributes();
    }

    fetchAttributes() {
        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 3;

        fetch("/api/attributes?scope=segmentation",
            {
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, false))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.setAttributesData(response))
            .catch(error => console.log(error));
    }

    setAttributesData = (attributes) => {

        var attributesList = attributes.map(attribute => ({
            name: attribute.name,
            label: attribute.name,
            id: attribute.uuid,
            attribute_type_uuid: attribute.attribute_type_uuid
        }));

        this.setState({ attributesList: attributesList });

        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 3;

        fetch("/api/attributetypes",
            {
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, false))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.setAttributeTypesData(response))
            .catch(error => console.log(error));
    }

    setAttributeTypesData = (attributeTypes) => {
        this.setState({ attributeTypesList: attributeTypes });

        if (this.props.uuid) {
            const accessToken = this.props.auth.getAccessToken();
            var setStatusOn200 = 3;

            fetch("/api/addons/" + this.props.uuid,
                {
                    headers: new Headers({
                        "Accept": "application/json",
                        "Authorization": `Bearer ${accessToken}`
                    })
                })
                .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, true))
                .then(response => response.json())
                .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.setAddon(response))
                .catch(error => console.log(error));
        }
        else
            this.props.handlerLoading(false);
    }

    setAddon = (addon) => {
        addon.valid_from = addon.valid_from != null ? new Date(new Date(addon.valid_from).setSeconds(0, 0)) : null;
        addon.valid_to = addon.valid_to != null ? new Date(new Date(addon.valid_to).setSeconds(0, 0)) : null;
        addon.promoted_from = addon.promoted_from != null ? new Date(new Date(addon.promoted_from).setSeconds(0, 0)) : null;
        addon.promoted_to = addon.promoted_to != null ? new Date(new Date(addon.promoted_to).setSeconds(0, 0)) : null;
        addon.spotlight_from = addon.spotlight_from != null ? new Date(new Date(addon.spotlight_from).setSeconds(0, 0)) : null;
        addon.spotlight_to = addon.spotlight_to != null ? new Date(new Date(addon.spotlight_to).setSeconds(0, 0)) : null;
        addon.new_to = new Date(new Date(addon.new_to).setSeconds(0, 0));

        const htmlDesc = this.tryToDecodeURIComponent(addon.description);
        const contentBlock = htmlToDraft(htmlDesc);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.setState({ editorState: editorState });
        }

        if (addon.segmentation_rule_query && addon.segmentation_rule_query !== null && addon.segmentation_rule_query !== "") {
            addon.query = JSON.parse(addon.segmentation_rule_query);
        } else {
            addon.query = null;
        }

        if (!addon.image_spotlight) {
            addon.image_spotlight = '';
        }

        if (!addon.image_spotlight_path) {
            addon.image_spotlight_path = '';
        }

        if (!addon.image) {
            addon.image = '';
        }

        if (!addon.segmentation_rule_query) {
            addon.segmentation_rule_query = '';
        }

        if (!addon.segmentation_rule) {
            addon.segmentation_rule = '';
        }

        this.setState({ addon: addon });
    }

    tryToDecodeURIComponent = (field) => {
        try {
            return decodeURIComponent(field);
        }
        catch (error) {
            return '';
        }
    }

    handleQueryChange = (query) => {
        let addon = this.state.addon;
        addon.query = query;

        const valueProcessor = queryBuilderFunctions.default(this.state.attributeTypesList, this.state.attributesList);

        var formatedQuery = query != null ? formatQuery(query, { format: 'sql', valueProcessor }) : '';
        var formatedQueryRaw = query != null ? formatQuery(query, { format: 'JSON' }) : '';

        addon.segmentation_rule = formatedQuery;
        addon.segmentation_rule_query = formatedQueryRaw;
        this.setState({ addon: addon });
    };

    handleSubmit = (event) => {
        this.props.handlerLoading(true);
        const accessToken = this.props.auth.getAccessToken();
        const self = this;

        let addon = self.state.addon;

        if (!self.isValidDate(addon.valid_from) ||
            !self.isValidDate(addon.valid_to) ||
            !self.isValidDate(addon.promoted_from) ||
            !self.isValidDate(addon.promoted_to) ||
            !self.isValidDate(addon.spotlight_from) ||
            !self.isValidDate(addon.spotlight_to) ||
            !self.isValidDate(addon.new_to, true)) {
            this.props.handlerLoading(false);
            return false;
        }

        if (addon.description === '') {
            self.props.setStatus("error", 'Es necesario añadir una descripción');
            this.props.handlerLoading(false);
            return false;
        }

        if (addon.image_path === '' && addon.image === '') {
            self.props.setStatus("error", 'Es necesario subir una imagen');
            this.props.handlerLoading(false);
            return false;
        }

        if ((addon.image_spotlight_path === '' && addon.image_spotlight === '') && (self.isValidDate(addon.spotlight_from, true) || self.isValidDate(addon.spotlight_to, true))) {
            self.props.setStatus("error", 'Es necesario subir una imagen destacado si hay definidas fechas destacado');
            this.props.handlerLoading(false);
            return false;
        }

        if (addon.category_uuid === '') {
            self.props.setStatus("error", 'Es necesario seleccionar una categoría');
            this.props.handlerLoading(false);
            return false;
        }

        if (addon.collection_uuid === '') {
            self.props.setStatus("error", 'Es necesario seleccionar una colección');
            this.props.handlerLoading(false);
            return false;
        }

        if (addon.provider_uuid === '') {
            self.props.setStatus("error", 'Es necesario seleccionar un proveedor');
            this.props.handlerLoading(false);
            return false;
        }

        if (addon.segmentation_rule) {
            if (addon.segmentation_rule === "()") {
                addon.segmentation_rule = "";
                addon.segmentation_rule_query = null;
                self.setState({ addon: addon });
            }
            else if (addon.segmentation_rule.includes("()")) {
                this.props.setStatus("error", 'No puede haber grupos sin reglas.');
                this.props.handlerLoading(false);
                return false;
            }
            else if (addon.segmentation_rule.includes(" \"\"") || addon.segmentation_rule.includes("== &&") || addon.segmentation_rule.includes("== ||") || addon.segmentation_rule.includes("==)")) {
                self.props.setStatus("error", 'Todas las reglas deben tener un valor.');
                this.props.handlerLoading(false);
                return false;
            }
        }

        var method = 'POST';
        var url = '/api/addons';
        var setStatusOn200 = 0;

        if (addon.uuid && addon.uuid !== '' && addon.uuid !== null) {
            method = 'PUT';
            url = '/api/addons/' + addon.uuid;
            setStatusOn200 = 1;
        }

        fetch(url,
            {
                method: method,
                headers: new Headers({
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                }),
                body: JSON.stringify(addon)
            })
            .then(response => handleResponse(response, self.props.handlerLoading, self.props.setStatus, self.props.auth, setStatusOn200, false))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, self.props.setStatus, self.props.handlerLoading) : (self.onAdded(response), this.props.handlerLoading(false)))
            .catch(error => console.log(error));
    }

    onAdded = (addon) => {
        this.props.submitCallback(addon.uuid);
    }

    isValidDate = (d, validateNull) => {
        if (!validateNull) {
            return d == null || (d instanceof Date && !isNaN(d));
        } else {
            return d instanceof Date && !isNaN(d);
        }
    }

    handleChange = (event) => {
        let addon = this.state.addon;
        var newValue;

        if (event.target.name === "cod_active") {
            newValue = event.target.checked;
        } else {
            newValue = event.target.value;
        }

        addon[event.target.name] = newValue;
        this.setState({ addon: addon });
    }

    handleChangeCheckedRemove = (event) => {
        var newValue = event.target.checked;
        this.setState({ checkedRemove: newValue });
    }

    handleWeightChange = (event, value) => {
        let addon = this.state.addon;
        addon.weight = value;
        this.setState({ addon: addon });
    }

    handleDateChange = (date, field) => {
        let addon = this.state.addon;
        var newValue = date == null ? null : date._d;

        if (field === "new_to") {
            if (newValue === null)
                this.setState({ new_to_error: 'Este campo es obligatorio' });
            else if (!this.isValidDate(newValue, false))
                this.setState({ new_to_error: 'Formato de fecha no válido' });
            else
                this.setState({ new_to_error: '' });
        }

        addon[field] = newValue;
        this.setState({ addon: addon });
    }

    onContentStateChange = (contentState) => {
        var description = draftToHtml(contentState);
        var addon = this.state.addon;
        addon.description = encodeURIComponent(description);
        this.setState({ addon: addon });
    };

    removeIcon = () => {
        let addon = this.state.addon;
        addon.image = "";
        addon.image_path = "";
        this.setState({ addon: addon });
    };

    removeSpotlightIcon = () => {
        let addon = this.state.addon;
        addon.image_spotlight = "";
        addon.image_spotlight_path = "";
        this.setState({ addon: addon });
    }

    toolbarOptions = {
        options: ['inline', 'fontSize', 'list', 'textAlign', 'link', 'history'],
        inline: {
            options: ['bold', 'italic', 'underline']
        },
        list: {
            options: ['unordered', 'ordered']
        }
    }

    handleChangeImageIcon(evt, imageType) {
        var self = this;
        var reader = new FileReader();
        var file = evt.target.files[0];

        if (!file.type.includes("image")) {
            this.props.setStatus("error", 'El archivo debe ser una imagen');
            return false;
        }

        if (file.size > 524288) {
            this.props.setStatus("error", 'La imagen debe pesar un máximo de 512KB');
            return false;
        }

        reader.onload = function (upload) {
            let addon = self.state.addon;
            addon[imageType] = upload.target.result;
            self.setState({ addon: addon });
        };
        reader.readAsDataURL(file);
    }

    handleIconFileUploadClick(e) {
        this.refs.file.click();
    }

    handleSpotlightIconFileUploadClick(e) {
        this.refs.file_spotlight.click();
    }

    onSelectCategory(categoryUuid) {
        let addon = this.state.addon;
        addon.category_uuid = categoryUuid;

        let categoryName = this.state.categories.filter((c) => (c.value === categoryUuid))[0].label;

        this.setState({ addon: addon, categoryName: categoryName });
    }

    onSelectCollection(collectionUuid) {
        var addon = this.state.addon;
        addon.collection_uuid = collectionUuid;
        this.setState({ addon: addon });
    }

    onSelectProvider(providerUuid) {
        var addon = this.state.addon;
        addon.provider_uuid = providerUuid;
        this.setState({ addon: addon });
    }

    handleDelete = () => {
        this.setState({ openDeleteDialog: true });
    }

    delete = () => {
        this.props.handlerLoading(true);
        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 2;

        fetch('/api/addons/' + this.state.addon.uuid,
            {
                method: 'DELETE',
                headers: new Headers({
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, true))
            .then(response => !response.ok ? response.json() : response)
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.props.history.push("/addons"))
            .catch(error => console.log(error));
    }

    handleClose = () => {
        this.setState({ openDeleteDialog: false });
    }

    handleOpenCreate = (createDialogEntity) => {
        this.setState({ openCreateDialog: true, createDialogEntity: createDialogEntity });
    }

    handleCloseCreate = (response) => {

        if (response) {
            let newEntity =
            {
                value: response.uuid,
                label: response.name
            }
            let entity = this.state.createDialogEntity.type;
            let field = this.state.createDialogEntity.uuid_field;
            let addon = this.state.addon;
            let entityList = this.state[entity];

            entityList.push(newEntity);
            addon[field] = response.uuid;
            this.setState({ addon: addon, [entity]: entityList });
        }
        this.setState({ openCreateDialog: false });
    }

    handlePrevToogle = () => {
        this.setState({ openPrev: !this.state.openPrev });
    }

    handleCardDiagButtonClick = (event) => {
        this.setState({ cardDiagOpen: !this.state.cardDiagOpen, cardDiagAnchorEl: document.getElementById("cardHidden") });
    };

    handleCardDiagButtonClose = (event) => {
        this.setState({ cardDiagOpen: false, cardDiagAnchorEl: event.currentTarget });
    };

    handleAddAffButtonClick = (event) => {
        var addon = this.state.addon;
        addon.landing_url = this.state.addon.landing_url + "?aff=";
        this.setState({ addon: addon });
    };

    handlePastedText = (text, html) => {
        return false;
    }

    render() {
        function valuetext(value) {
            return `${value}`;
        }

        const marks = [
            {
                value: 0,
                label: '0'
            },
            {
                value: 10,
                label: '10'
            },
            {
                value: 25,
                label: '20'
            },
            {
                value: 50,
                label: '50'
            },
            {
                value: 75,
                label: '75'
            },
            {
                value: 100,
                label: '100'
            }
        ];

        return (
            <Fragment>
                <CreateDialog
                    open={this.state.openCreateDialog}
                    handleClose={this.handleCloseCreate}
                    handleOnAdded={this.handleCloseCreate}
                    entity={this.state.createDialogEntity}
                    handlerLoading={this.props.handlerLoading}
                    setStatus={this.props.setStatus}
                    auth={this.props.auth}
                >
                </CreateDialog>
                <CardDialog
                    open={this.state.cardDiagOpen}
                    cardDiagAnchorEl={this.state.cardDiagAnchorEl}
                    imagePath={this.state.addon.image_path !== "" ? this.state.addon.image_path : this.state.addon.image}
                    name={this.state.addon.name}
                    category={this.state.addon.category_name}
                    description={this.state.addon.description}
                    url={this.state.addon.landingUrl}
                    handleClose={this.handleCardDiagButtonClose}
                >
                </CardDialog>
                <ValidatorForm
                    ref="form"
                    onSubmit={this.handleSubmit}
                    onError={errors => console.log(errors)}
                >
                    <Grid container spacing={1} style={{ paddingBottom: '25px' }}>
                        <Grid item xs={11} wrap="nowrap">
                            <h5>Información básica</h5>
                        </Grid>
                        <Grid item xs={1} wrap="nowrap">
                            <input type="hidden" id="cardHidden" />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={2} wrap="nowrap">
                            <label style={labelStyle}>Nombre</label>
                        </Grid>
                        <Grid item xs={10}>
                            <TextValidator
                                style={imputTextStyle}
                                margin="dense"
                                variant="outlined"
                                label="Nombre"
                                name="name"
                                value={this.state.addon.name}
                                onChange={this.handleChange}
                                validators={['required', 'maxStringLength: 32', 'trim']}
                                errorMessages={['Este campo es obligatorio', 'La longitud máxima de este campo es de 32 caracteres', 'Este campo es obligatorio']}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={2}>
                            <label style={labelStyle}>Descripción</label>
                        </Grid>
                        <Grid item xs={9}>
                            <Editor
                                editorState={this.state.editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={this.onEditorStateChange}
                                onContentStateChange={this.onContentStateChange}
                                toolbar={this.toolbarOptions}
                                handlePastedText={this.handlePastedText}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={2}>
                            <label style={labelStyle}>Url</label>
                        </Grid>
                        <Grid item xs={10}>
                            <TextValidator
                                style={imputTextStyle}
                                margin="dense"
                                variant="outlined"
                                label="https://"
                                name="landing_url"
                                value={this.state.addon.landing_url}
                                onChange={this.handleChange}
                                validators={['required', 'maxStringLength: 512']}
                                errorMessages={['Este campo es obligatorio', 'La longitud máxima de este campo es de 512 caracteres']}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={2}>                           
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="contained" type="button" href={this.state.addon.landing_url} target="_blank">
                                Probar URL
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="contained" type="button" onClick={this.handleAddAffButtonClick}>
                                Añadir AFF
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.addon.cod_active}
                                        onChange={this.handleChange}
                                        value="cod_active"
                                        name="cod_active"
                                        color="primary"
                                    />
                                }
                                label="Añadir COD a la URL"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ paddingBottom: '25px' }}>
                        <Grid item lg={2} xs={2}>
                            <label style={labelStyle}>Categoría</label>
                        </Grid>
                        <Grid item lg={3} xs={9}>
                            <SelectAutoComplete uuid={this.state.addon.category_uuid} suggestions={this.state.categories} onSelectSuggestion={this.onSelectCategory} error="true" placeholder="Selecciona una categoría" label="Categoría" />
                        </Grid>
                        <Grid item lg={1} xs={1}>
                            <Button onClick={() => this.handleOpenCreate({ name: 'categoría', type: 'categories', uuid_field: 'category_uuid' })} variant="contained" className="selectbutton">
                                <AddIcon />
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={2}>
                            <label style={labelStyle}>Imagen</label>
                        </Grid>
                        <Grid item xs="auto">
                            <Box width="200px">
                                {this.state.addon.image_path !== "" && (
                                    <img src={this.state.addon.image_path} class="img-responsive" alt="imagen AddOn" />
                                )}
                                {this.state.addon.image_path === "" && this.state.addon.image === "" && (
                                    <img src={recommendedSizeImage} class="img-responsive" alt="imagen AddOn" />
                                )}
                                {this.state.addon.image_path === "" && this.state.addon.image !== "" && (
                                    <img src={this.state.addon.image} class="img-responsive" alt="imagen AddOn" />
                                )}
                            </Box>
                        </Grid>
                        <Grid item xs="auto">
                            {this.state.addon.image === "" && this.state.addon.image_path === "" && (
                                <Button variant="contained" onClick={this.handleIconFileUploadClick}>
                                    Subir imagen
                                    < input ref="file" type="file" name="file"
                                        className="upload-file"
                                        id="file"
                                        onChange={(event) => this.handleChangeImageIcon(event, "image")}
                                        encType="multipart/form-data"
                                        style={{ display: "none" }}
                                        accept="image/*"
                                    />
                                </Button>
                            )}
                            {(this.state.addon.image !== "" || this.state.addon.image_path !== "") && (
                                <DeleteIcon cursor="pointer" style={labelStyle} onClick={this.removeIcon} />
                            )}
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={2}>
                            <label style={labelStyle}>Imagen Destacado</label>
                        </Grid>
                        <Grid item xs="auto">
                            <Box width="200px">
                                {this.state.addon.image_spotlight_path !== "" && (typeof this.state.addon.image_spotlight_path !== 'undefined') && (
                                    <img src={this.state.addon.image_spotlight_path} class="img-responsive" alt="imagen AddOn" />
                                )}
                                {(this.state.addon.image_spotlight_path === "" && this.state.addon.image_spotlight === "") && (
                                    <img src={recommendedSizeSpotlightImage} class="img-responsive" alt="imagen AddOn" />
                                )}
                                {this.state.addon.image_spotlight_path === "" && this.state.addon.image_spotlight !== "" && (
                                    <img src={this.state.addon.image_spotlight} class="img-responsive" alt="imagen AddOn" />
                                )}
                            </Box>
                        </Grid>
                        <Grid item xs="auto">
                            {this.state.addon.image_spotlight === "" && this.state.addon.image_spotlight_path === "" && (
                                <Button variant="contained" onClick={this.handleSpotlightIconFileUploadClick}>
                                    Subir imagen
                                    < input ref="file_spotlight" type="file" name="file_spotlight"
                                        className="upload-file"
                                        id="file_spotlight"
                                        onChange={(event) => this.handleChangeImageIcon(event, "image_spotlight")}
                                        encType="multipart/form-data"
                                        style={{ display: "none" }}
                                        accept="image/*"
                                    />
                                </Button>
                            )}
                            {(this.state.addon.image_spotlight !== "" || this.state.addon.image_spotlight_path !== "") && (
                                <DeleteIcon cursor="pointer" style={labelStyle} onClick={this.removeSpotlightIcon} />
                            )}
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={2}>

                        </Grid>
                        <Grid item xs={10}>
                            <Button aria-describedby="simple-popper" variant="contained" type="button" onClick={this.handleCardDiagButtonClick}>
                                Previsualizar addon
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ paddingTop: '25px', paddingBottom: '25px' }}>
                        <Grid item xs={12} wrap="nowrap">
                            <h5>Detalle del módulo</h5>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={2}>
                            <label style={labelStyle}>Módulo Sage Lic.</label>
                        </Grid>
                        <Grid item xs={10}>
                            <TextValidator
                                InputLabelProps={{ shrink: true }}
                                style={imputTextStyle}
                                margin="dense"
                                variant="outlined"
                                label="Módulo Sage Lic."
                                name="sage_licensing_module"
                                value={this.state.addon.sage_licensing_module}
                                onChange={this.handleChange}
                                validators={['required', 'maxStringLength: 64', 'trim', 'matchRegexp:^[a-zA-Z0-9]*$']}
                                errorMessages={['Este campo es obligatorio', 'La longitud máxima de este campo es de 64 caracteres', 'Este campo es obligatorio', 'Solo están permitidos caracteres alfanuméricos']}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ paddingBottom: '10px' }}>
                        <Grid item lg={2} xs={2}>
                            <label style={labelStyle}>Colección</label>
                        </Grid>
                        <Grid item lg={3} xs={9}>
                            <SelectAutoComplete uuid={this.state.addon.collection_uuid} suggestions={this.state.collections} onSelectSuggestion={this.onSelectCollection} placeholder="Colección" label="Colección" isDisabled={(this.state.addon.is_required || this.state.addon.is_incompatible || this.state.addon.is_recommended)} />
                        </Grid>
                        <Grid item lg={1} xs={1}>
                            <Button onClick={() => this.handleOpenCreate({ name: 'colección', type: 'collections', uuid_field: 'collection_uuid' })} variant="contained" className="selectbutton">
                                <AddIcon />
                            </Button>
                        </Grid>
                        <Grid item lg={1} xs={2}>
                            <label style={labelStyle}>Proveedor</label>
                        </Grid>
                        <Grid item lg={3} xs={9}>
                            <SelectAutoComplete uuid={this.state.addon.provider_uuid} suggestions={this.state.providers} onSelectSuggestion={this.onSelectProvider} placeholder="Selecciona un proveedor" error="true" label="Selecciona un proveedor" />
                        </Grid>
                        <Grid item lg={1} xs={1}>
                            <Button onClick={() => this.handleOpenCreate({ name: 'proveedor', type: 'providers', uuid_field: 'provider_uuid' })} variant="contained" className="selectbutton">
                                <AddIcon />
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} style={{ paddingTop: '25px', paddingBottom: '25px' }}>
                        <Grid item xs={12} wrap="nowrap">
                            <h5>Información de publicación</h5>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={2}>
                            <label style={labelStyle}>Peso (en el orden)</label>
                        </Grid>
                        <Grid item xs={9}>
                            <Slider
                                value={this.state.addon.weight}
                                getAriaValueText={valuetext}
                                aria-labelledby="discrete-slider-always"
                                step={1}
                                marks={marks}
                                valueLabelDisplay="auto"
                                min={0}
                                max={100}
                                onChange={this.handleWeightChange}
                            />
                        </Grid>
                    </Grid>
                    <Fragment>
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <label style={labelStyle}>Nuevo hasta: </label>
                            </Grid>
                            <Grid item xs={10}>
                                <KeyboardDateTimePicker
                                    variant="outlined"
                                    ampm={false}
                                    label="Nuevo hasta"
                                    value={this.state.addon.new_to}
                                    onChange={date => this.handleDateChange(date, "new_to")}
                                    format="DD/MM/YYYY HH:mm"
                                    error={this.state.new_to_error}
                                    helperText={this.state.new_to_error !== '' ? this.state.new_to_error : ''}
                                    invalidDateMessage="Formato de fecha no válido"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item lg={2} xs={2} >
                                <label style={labelStyle}>Válido desde: </label>
                            </Grid>
                            <Grid item lg={3} xs={10}>
                                <KeyboardDateTimePicker
                                    variant="outlined"
                                    ampm={false}
                                    label="Válido desde"
                                    value={this.state.addon.valid_from}
                                    onChange={date => this.handleDateChange(date, "valid_from")}
                                    format="DD/MM/YYYY HH:mm"
                                    invalidDateMessage="Formato de fecha no válido"
                                />
                            </Grid>
                            <Grid item lg={2} xs={2}>
                                <label style={labelStyle}>Válido hasta: </label>
                            </Grid>
                            <Grid item lg={5} xs={10}>
                                <KeyboardDateTimePicker
                                    variant="outlined"
                                    ampm={false}
                                    label="Válido hasta"
                                    value={this.state.addon.valid_to}
                                    onChange={date => this.handleDateChange(date, "valid_to")}
                                    format="DD/MM/YYYY HH:mm"
                                    invalidDateMessage="Formato de fecha no válido"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item lg={2} xs={2}>
                                <label style={labelStyle}>Promocionado desde: </label>
                            </Grid>
                            <Grid item lg={3} xs={10}>
                                <KeyboardDateTimePicker
                                    variant="outlined"
                                    ampm={false}
                                    label="Promocionado desde"
                                    value={this.state.addon.promoted_from}
                                    onChange={date => this.handleDateChange(date, "promoted_from")}
                                    format="DD/MM/YYYY HH:mm"
                                    invalidDateMessage="Formato de fecha no válido"
                                />
                            </Grid>
                            <Grid item lg={2} xs={2}>
                                <label style={labelStyle}>Promocionado hasta: </label>
                            </Grid>
                            <Grid item lg={5} xs={10}>
                                <KeyboardDateTimePicker
                                    variant="outlined"
                                    ampm={false}
                                    label="Promocionado hasta"
                                    value={this.state.addon.promoted_to}
                                    onChange={date => this.handleDateChange(date, "promoted_to")}
                                    format="DD/MM/YYYY HH:mm"
                                    invalidDateMessage="Formato de fecha no válido"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item lg={2} xs={2}>
                                <label style={labelStyle}>Destacado desde: </label>
                            </Grid>
                            <Grid item lg={3} xs={10}>
                                <KeyboardDateTimePicker
                                    variant="outlined"
                                    ampm={false}
                                    label="Destacado desde"
                                    value={this.state.addon.spotlight_from}
                                    onChange={date => this.handleDateChange(date, "spotlight_from")}
                                    format="DD/MM/YYYY HH:mm"
                                    invalidDateMessage="Formato de fecha no válido"
                                />
                            </Grid>
                            <Grid item lg={2} xs={2}>
                                <label style={labelStyle}>Destacado hasta: </label>
                            </Grid>
                            <Grid item lg={5} xs={10}>
                                <KeyboardDateTimePicker
                                    variant="outlined"
                                    ampm={false}
                                    label="Destacado hasta"
                                    value={this.state.addon.spotlight_to}
                                    onChange={date => this.handleDateChange(date, "spotlight_to")}
                                    format="DD/MM/YYYY HH:mm"
                                    invalidDateMessage="Formato de fecha no válido"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} style={{ paddingTop: '25px', paddingBottom: '25px' }}>
                            <Grid item xs={12} wrap="nowrap">
                                <h5><strong>Reglas para mostrar el AddOn</strong>: aplicadas sobre los Atributos del fichero de licencia</h5>
                                Si no se define ninguna regla este módulo se mostrará a todos los clientes.
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <RulesQueryBuilder
                                    query={this.state.addon.query}
                                    onQueryChange={this.handleQueryChange}
                                    fields={this.state.attributesList}
                                    fieldTypes={this.state.attributeTypesList}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <label></label>
                            </Grid>
                        </Grid>
                        {this.state.addon.uuid !== '' && (
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.checkedRemove}
                                                onChange={this.handleChangeCheckedRemove}
                                                value="checkedRemove"
                                                color="primary"
                                            />
                                        }
                                        label="Sé lo que estoy haciendo, habilitar el botón de eliminar"
                                    />
                                </Grid>
                            </Grid>
                        )}
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                {this.state.addon.uuid !== '' && (
                                    <Button onClick={this.handleDelete} variant="contained" disabled={!this.state.checkedRemove}> Eliminar</Button>
                                )}
                            </Grid>
                            <Grid item xs={8}>
                            </Grid>
                            <Grid item xs={1}>
                                <Button type="submit" variant="contained" color="primary"> Guardar</Button>
                            </Grid>
                        </Grid>
                    </Fragment >
                </ValidatorForm>
                <ConfirmDialog
                    open={this.state.openDeleteDialog}
                    onAgree={this.delete}
                    title="¿Deseas continuar?"
                    text="Se va a eliminar el AddOn. Esta acción es irreversible ¿deseas continuar?"
                    handleClose={this.handleClose}
                />
            </Fragment >
        );
    }

}