import React, { Fragment } from 'react';
import { AttributeTypeValuesForm } from './AttributeTypeValuesForm'
import handleResponse from '../.././functions/handleResponse';
import handleDiagnosis from '../.././functions/handleDiagnosis';
import Grid from '@material-ui/core/Grid';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '../Button';
import Divider from '@material-ui/core/Divider';
import { DividerPadding } from '../DividerPadding';

const imputTextStyle = {
    width: '100%'
};

const labelStyle = {
    marginTop: 15
};

export class CreateAttributeTypeForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            attributeType: {
                uuid: this.props.uuid,
                name: '',
                is_enum: true,
                read_only: false
            }
        };
        this.fetchData = this.fetchData.bind(this);
        this.setData = this.setData.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        this.props.handlerLoading(true);

        if (this.state.attributeType.uuid !== '') {
            const accessToken = this.props.auth.getAccessToken();
            var setStatusOn200 = 3;

            fetch("/api/attributetypes/" + this.state.attributeType.uuid,
                {
                    headers: new Headers({
                        "Accept": "application/json",
                        "Authorization": `Bearer ${accessToken}`
                    })
                })
                .then(response => handleResponse(response,
                    this.props.handlerLoading,
                    this.props.setStatus,
                    this.props.auth,
                    setStatusOn200, true))
                .then(response => response.json())
                .then(response => response.diagnosis
                    ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading)
                    : this.setData(response))
                .catch(error => console.log(error));
        }
        else
            this.props.handlerLoading(false);
    }

    setData(attributeType) {
        this.setState({ attributeType: attributeType });
    }

    handleChange = (event) => {
        let attributeType = this.state.attributeType;
        var newValue = event.target.value;
        attributeType[event.target.name] = newValue;
        this.setState({ attributeType: attributeType });
    }

    handleSubmit = (event) => {
        this.props.handlerLoading(true);
        event.preventDefault();
        
        const accessToken = this.props.auth.getAccessToken();
        const self = this;

        var method = 'POST';
        var url = '/api/attributetypes';
        var setStatusOn200 = 0;

        if (self.state.attributeType.uuid && self.state.attributeType.uuid !== '' && self.state.attributeType.uuid !== null) {
            method = 'PUT';
            url = '/api/attributetypes/' + self.state.attributeType.uuid;
            setStatusOn200 = 1;
        }

        fetch(url,
            {
                method: method,
                headers: new Headers({
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                }),
                body: JSON.stringify(self.state.attributeType)
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, true))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.onSaved(response))
            .catch(error => console.log(error));
    }

    onSaved = (attributeType) => {
        if (this.state.attributeType.uuid === '') {
            this.props.history.push('/enums/update/' + attributeType.uuid);
        } else {
            this.fetchData();
        }
    }

    render() {

        return (
            <Fragment>
                <div className="content">
                    <ValidatorForm
                        ref="form"
                        onSubmit={this.handleSubmit}
                        onError={errors => console.log(errors)}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={1} wrap="nowrap">
                                <label style={labelStyle}>Nombre</label>
                            </Grid>
                            <Grid item xs={5}>
                                <TextValidator
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: this.state.attributeType.read_only, disabled: this.state.attributeType.read_only }}
                                    style={imputTextStyle}
                                    margin="dense"
                                    variant="outlined"
                                    label="Nombre"
                                    name="name"
                                    value={this.state.attributeType.name}
                                    onChange={this.handleChange}
                                    validators={['required', 'maxStringLength: 64', 'trim']}
                                    errorMessages={['Este campo es obligatorio', 'La longitud máxima de este campo es de 64 caracteres', 'Este campo es obligatorio']}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                {!this.state.attributeType.read_only && (
                                    <Button type="submit" variant="contained" color="primary" size="medium" style={{ marginTop: '9px' }}> Guardar</Button>
                                )}
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                    <DividerPadding top="30" />
                    <Divider />
                    <DividerPadding top="30" />
                    <Grid container spacing={1}>
                        <Grid item xs={12} wrap="nowrap">
                        </Grid>
                    </Grid>
                    {this.state.attributeType.uuid !== '' && this.state.attributeType.is_enum && (
                        <Fragment>
                            <h6>Añadir nuevo valor de enumerado</h6>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <AttributeTypeValuesForm auth={this.props.auth} readOnly={this.state.attributeType.read_only} handlerLoading={this.props.handlerLoading} typeUuid={this.state.attributeType.uuid} setStatus={this.props.setStatus}></AttributeTypeValuesForm>
                                </Grid>
                            </Grid>
                        </Fragment>
                    )}
                </div>
            </Fragment>
        );
    }
}