import React, { Fragment } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import Button from '../Button';
import handleResponse from '../.././functions/handleResponse';
import handleDiagnosis from '../.././functions/handleDiagnosis';
import { ConfirmDialog } from '.././ConfirmDialog';
import TextField from '@material-ui/core/TextField';
import SelectAutoComplete from '../../functions/SelectAutoComplete';
import Grid from '@material-ui/core/Grid';
import { PageHeader } from '../PageHeader';

export class AttributesListPage extends React.Component {
    constructor(context) {
        super();

        this.state = {
            attributesList: [], delete_uuid: '', open: false,
            selectedValue: {
                attribute_type_uuid: '',
                scope: ''
            },
            attributeTypesList: [],
            scopesList: [{ value: "segmentation", label: "Fichero de licencia" }, { value: "recommendation", label: "Datos de la aplicación" }]
        };
        this.context = context;
        this.fetchData = this.fetchData.bind(this);
        this.setData = this.setData.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.delete = this.delete.bind(this);
        this.handleClose = this.handleClose.bind(true);
        this.onRowUpdate = this.onRowUpdate.bind(true);
        this.setAttributeTypesData = this.setAttributeTypesData.bind(true);
        this.onSelectAttributeType = this.onSelectAttributeType.bind(this);
        this.onSelectScope = this.onSelectScope.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
    }

    componentDidMount() {
        this.fetchData();

    }

    fetchData() {
        this.props.handlerLoading(true);
        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 3;

        fetch("/api/attributetypes",
            {
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, false))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.setAttributeTypesData(response))
            .catch(error => console.log(error));

        fetch("/api/attributes",
            {
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, true))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading, true) : this.setData(response))
            .catch(error => console.log(error));
    }

    setAttributeTypesData = (attributeTypesData) => {
        var attributeTypesList = attributeTypesData.map(attributeType => ({
            value: attributeType.uuid,
            label: attributeType.name
        }));
        
        this.setState({ attributeTypesList: attributeTypesList });
    }

    setData(attributes) {
        this.setState({ attributesList: attributes });
    }

    handleDelete = (uuid) => {
        this.setState({ delete_uuid: uuid, open: true });
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    delete = (uuid) => {
        this.props.handlerLoading(true);
        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 2;

        fetch('/api/attributes/' + uuid,
            {
                method: 'DELETE',
                headers: new Headers({
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, true))
            .then(response => !response.ok ? response.json() : '')
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.fetchData(response))
            .catch(error => console.log(error));
    }

    onRowUpdate = (newData, oldData) => {
        this.props.handlerLoading(true);
        const accessToken = this.props.auth.getAccessToken();
        const self = this;
        var setStatusOn200 = 1;

        var object = {
            name: newData.name,
            attribute_type_uuid: this.state.selectedValue.attribute_type_uuid !== '' ? this.state.selectedValue.attribute_type_uuid : newData.attribute_type_uuid,
            scope: this.state.selectedValue.scope !== '' ? this.state.selectedValue.scope : newData.scope
        }

        return fetch(`/api/attributes/${newData.uuid}`,
            {
                method: 'PUT',
                headers: new Headers({
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                }),
                body: JSON.stringify(object)
            }).then(response => handleResponse(response, self.props.handlerLoading, self.props.setStatus, self.props.auth, setStatusOn200, true))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, self.props.setStatus, self.props.handlerLoading) : self.onUpdate())
            .catch(error => console.log(error));
    }

    onUpdate() {
        var selectedValue = {
            attribute_type_uuid: '',
            scope: ''
        }
        this.setState({ selectedValue: selectedValue }, this.fetchData);
    }

    onSelectAttributeType(attributeTypeUuid) {
        var selectedValue = this.state.selectedValue;
        selectedValue.attribute_type_uuid = attributeTypeUuid;
        this.setState({ selectedValue: selectedValue });
    }

    onSelectScope(scope) {
        var selectedValue = this.state.selectedValue;
        selectedValue.scope = scope;
        this.setState({ selectedValue: selectedValue });
    }

    render() {
        return (
            <Fragment>
                <PageHeader title="Atributos" text="Esta página muestra los atributos creados" />
                <div className="content">
                    <div className="row">
                        <div className="col col-12">
                            <MaterialTable
                                title=""
                                columns={[
                                    {
                                        title: 'Nombre', field: 'name',
                                        editComponent: props => (
                                            <TextField
                                                inputProps={{
                                                    maxLength: '64'
                                                }}
                                                id="outlined-name"
                                                label="Nombre"
                                                value={props.value}
                                                margin="dense"
                                                variant="outlined"
                                                onChange={e => props.onChange(e.target.value)}
                                                style={{ width: '90%' }}
                                            />
                                        )
                                    },
                                    {
                                        title: 'Tipo', field: 'attribute_type_name',
                                        editComponent: props => (
                                            <Grid container spacing={1} style={{ marginTop: '-10px' }}>
                                                <Grid item xs={12}>
                                                    <SelectAutoComplete
                                                        uuid={this.state.selectedValue.attribute_type_uuid === '' ? props.rowData.attribute_type_uuid : this.state.selectedValue.attribute_type_uuid}
                                                        suggestions={this.state.attributeTypesList}
                                                        placeholder="Selecciona un tipo de atributo"
                                                        onSelectSuggestion={this.onSelectAttributeType}
                                                        label="Tipo de atributo"
                                                        paperPosition="relative" />
                                                </Grid>
                                            </Grid>
                                        )
                                    },
                                    {
                                        title: 'Origen', field: 'scope',
                                        render: rowData => rowData.scope === 'segmentation' ? 'Fichero de licencia' : 'Datos de la aplicación',
                                        editComponent: props => (
                                            <Grid container spacing={1} style={{ marginTop: '-10px' }}>
                                                <Grid item xs={12}>

                                                    <SelectAutoComplete
                                                        uuid={this.state.selectedValue.scope === '' ? props.rowData.scope : this.state.selectedValue.scope}
                                                        suggestions={this.state.scopesList}
                                                        placeholder="Selecciona un scope"
                                                        onSelectSuggestion={this.onSelectScope}
                                                        label="Origen"
                                                        paperPosition="relative" />
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                ]}
                                editable={{
                                    onRowUpdate: this.onRowUpdate
                                }}
                                data={this.state.attributesList}
                                options={{
                                    sorting: true,
                                    pageSize: 20,
                                    pageSizeOptions: [10, 20, 50, 100],
                                    padding: 'dense',
                                    emptyRowsWhenPaging: false,
                                    actionsColumnIndex: -1,
                                    searchFieldAlignment: "left",
                                    actionsCellStyle: { borderRight: '0px', borderBottomStyle: 'none' },
                                    headerStyle: {
                                        fontWeight: 'bold'
                                    },
                                    cellStyle: { borderRight: '0px', borderBottomStyle: 'none' }
                                }}
                                actions={[
                                    rowData => ({
                                        icon: 'delete',
                                        tooltip: 'Borrar atributo',
                                        onClick: (event, row) => this.handleDelete(row.uuid),
                                        disabled: rowData.is_removable !== undefined && !rowData.is_removable
                                    })
                                ]}
                                localization={{
                                    header: {
                                        actions: 'Acciones'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'No hay datos que mostrar',
                                        editTooltip: 'Editar',
                                        editRow: {
                                            saveTooltip: 'Guardar',
                                            cancelTooltip: 'Cancelar',
                                            deleteText: 'Eliminar'
                                        }
                                    },
                                    toolbar: {
                                        searchTooltip: 'Buscar',
                                        searchPlaceholder: 'Buscar'
                                    },
                                    pagination: {
                                        labelRowsSelect: 'filas',
                                        labelDisplayedRows: ' {from}-{to} de {count}',
                                        firstTooltip: 'Primera',
                                        previousTooltip: 'Anterior',
                                        nextTooltip: 'Siguiente',
                                        lastTooltip: 'Última'
                                    }
                                }}
                                components={{
                                    Toolbar: props => (
                                        <div className="row">
                                            <div className="col col-1-auto">
                                                <MTableToolbar {...props} style={{ paddingLeft: 0 }} />
                                            </div>
                                            <div style={{ marginTop: 12, marginRight: 17 }}>
                                                <Button variant="contained" color="primary" onClick={() => { this.props.history.push('/attributes/add'); }}>
                                                    Añadir
                                                </Button>
                                            </div>
                                        </div>
                                    ),
                                }}
                            />
                        </div>
                    </div>
                </div>
                <ConfirmDialog
                    open={this.state.open}
                    onAgree={() => this.delete(this.state.delete_uuid)}
                    title="¿Deseas continuar?"
                    text="Se va a eliminar el atributo. Esta acción es irreversible ¿deseas continuar?"
                    handleClose={this.handleClose}
                />
            </Fragment>
        )
    }
}