import React, { Component, Fragment } from 'react';
import { Collapse, Container, Navbar, NavbarToggler, NavItem, NavLink, Nav, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';


export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.toggleDropDown = this.toggleDropDown.bind(this);

        this.state = {
            collapsed: true,
            dropdownOpen: false
        };
    }

    toggleNavbar() {
        this.setState({ collapsed: !this.state.collapsed });
    }

    toggleDropDown() {
        this.setState({ dropdownOpen: !this.state.dropdownOpen });
    }

    getUserRole() {
        return this.props.auth.getUserRole();
    }

    render() {
        return (
            <header>

                <Navbar className="navbar-expand-xl ng-white border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-auto" expand="md" />
                        <Collapse isOpen={!this.state.collapsed} navbar>
                            {this.getUserRole() !== '' && this.getUserRole() !== null && this.getUserRole() !== 'error' && (
                                <Fragment>
                                    <Nav className="navbar-nav flex-grow mr-auto" navbar>
                                        <NavItem style={{ display: this.getUserRole() === "Admin" || this.getUserRole() === "PM" ? 'inline' : 'none' }}>
                                            <NavLink tag={Link} className={" text-dark " + (window.location.pathname === "/" || window.location.pathname.includes("/addons") ? 'active' : '')} to="/addons">AddOns</NavLink>
                                        </NavItem>
                                        <NavItem style={{ display: this.getUserRole() === "Admin" || this.getUserRole() === "PM" ? 'inline' : 'none' }}>
                                            <NavLink tag={Link} className={" text-dark " + (window.location.pathname.includes("/recommendations") ? 'active' : '')} to="/recommendations">Recomendaciones</NavLink>
                                        </NavItem>
                                        <NavItem style={{ display: this.getUserRole() === "Admin" || this.getUserRole() === "PO" ? 'inline' : 'none' }}>
                                            <NavLink tag={Link} className={" text-dark " + ((this.getUserRole() === "PO" && window.location.pathname === "/") || window.location.pathname.includes("/legalnews") ? 'active' : '')} to="/legalnews">Noticias legales</NavLink>
                                        </NavItem>
                                        <NavItem style={{ display: this.getUserRole() === "Admin" || this.getUserRole() === "PM" || this.getUserRole() === "SC" ? 'inline' : 'none' }}>
                                            <NavLink tag={Link} className={" text-dark " + ((this.getUserRole() === "SC" && window.location.pathname === "/") || window.location.pathname.includes("/sagenews") ? 'active' : '')} to="/sagenews">Sage contigo</NavLink>
                                        </NavItem>
                                        <NavItem style={{ display: this.getUserRole() === "Admin" || this.getUserRole() === "PO" ? 'inline' : 'none' }}>
                                            <NavLink tag={Link} className={" text-dark " + (window.location.pathname.includes("/screens") ? 'active' : '')} to="/screens">Pantallas cero</NavLink>
                                        </NavItem>
                                        <NavItem style={{ display: this.getUserRole() === "Admin" || this.getUserRole() === "PM" ? 'inline' : 'none' }}>
                                            <NavLink tag={Link} className={" text-dark " + (window.location.pathname.includes("/simulations") ? 'active' : '')} to="/simulations">Simulaciones</NavLink>
                                        </NavItem>
                                    </Nav>
                                    <Nav className="navbar-nav flex-grow ml-auto" navbar style={{ display: this.getUserRole() === "Admin" || this.getUserRole() === "PM" || this.getUserRole() === "PO" ? 'inline' : 'none' }}>
                                        <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggleDropDown}>
                                            <DropdownToggle caret className={" text-dark " + (window.location.pathname.includes("/enums") || window.location.pathname.includes("/attributes") || window.location.pathname.includes("/data") || window.location.pathname.includes("/agreements") || window.location.pathname.includes("/cache") || window.location.pathname.includes("/installations") ? 'active' : '')}>
                                                Configuraci&oacute;n
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem><NavLink tag={Link} style={{ display: this.getUserRole() === "Admin" || this.getUserRole() === "PM" || this.getUserRole() === "PO" ? 'inline' : 'none' }} className={" text-dark " + (window.location.pathname.includes("/enums") ? 'active' : '')} to="/enums">Lista de valores</NavLink></DropdownItem>
                                                <DropdownItem><NavLink tag={Link} style={{ display: this.getUserRole() === "Admin" || this.getUserRole() === "PM" || this.getUserRole() === "PO" ? 'inline' : 'none' }} className={" text-dark " + (window.location.pathname.includes("/attributes") ? 'active' : '')} to="/attributes">Atributos</NavLink></DropdownItem>
                                                <DropdownItem><NavLink tag={Link} style={{ display: this.getUserRole() === "Admin" || this.getUserRole() === "PM" ? 'inline' : 'none' }} className={" text-dark " + (window.location.pathname.includes("/data") ? 'active' : '')} to="/data">Tablas maestras</NavLink></DropdownItem>
                                                <DropdownItem><NavLink tag={Link} style={{ display: this.getUserRole() === "Admin" || this.getUserRole() === "PM" ? 'inline' : 'none' }} className={" text-dark " + (window.location.pathname.includes("/agreements") ? 'active' : '')} to="/agreements">Acuerdos de cliente</NavLink></DropdownItem>                                                
                                                <DropdownItem><NavLink tag={Link} style={{ display: this.getUserRole() === "Admin" ? 'inline' : 'none' }} className={" text-dark " + (window.location.pathname.includes("/cache") ? 'active' : '')} to="/cache">Gesti&oacute;n de cach&eacute;</NavLink></DropdownItem>
                                                <DropdownItem><NavLink tag={Link} style={{ display: this.getUserRole() === "Admin" ? 'inline' : 'none' }} className={" text-dark " + (window.location.pathname.includes("/installations") ? 'active' : '')} to="/installations">Instalaciones</NavLink></DropdownItem> 
                                            </DropdownMenu>
                                        </Dropdown>
                                    </Nav>
                                </Fragment>
                            )}
                        </Collapse>
                    </Container>
                </Navbar>

            </header>
        );
    }
}