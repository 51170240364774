import React, { Fragment } from 'react';
import { CreateAttributeTypeForm } from './CreateAttributeTypeForm'
import { matchPath } from 'react-router';
import { PageHeader } from '../PageHeader';

export class UpdateAttributeTypePage extends React.Component {

    getParams = pathname => {
        const matchProfile = matchPath(pathname, {
            path: `/enums/update/:typeUuid`
        });
        return (matchProfile && matchProfile.params) || {};
    };

    render() {
        var params = this.getParams(this.props.history.location.pathname);
        return (
            <Fragment>
                <PageHeader title="Modificar lista de valores" text="Esta página permite modificar una lista de valores" />
                <CreateAttributeTypeForm uuid={params.typeUuid} history={this.props.history} auth={this.props.auth} handlerLoading={this.props.handlerLoading} setStatus={this.props.setStatus}></CreateAttributeTypeForm>
            </Fragment>
        );
    }
}