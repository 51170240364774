import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import { Card } from '../Card'
import Button from '../Button';
import Draggable from 'react-draggable';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
    paper: {
        border: '1px solid',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function CardDialog(props) {
    const classes = useStyles();
    const [checkedPromoted, setCheckedPromoted] = React.useState(false);
    const [checkedNew, setCheckedNew] = React.useState(false);

    const handleChangeCheckedRemove = event => {
        setCheckedPromoted(!checkedPromoted);
    };

    const handleChangeCheckedNew = event => {
        setCheckedNew(!checkedNew);
    };

    const id = props.open ? 'simple-popper' : undefined;

    return (
        <div>
            <Popper id={id} open={props.open} anchorEl={props.cardDiagAnchorEl}
            >
                <Draggable>
                    <div className={classes.paper} style={{ cursor: 'move' }}>
                        <Card
                            imagePath={props.imagePath}
                            name={props.name}
                            category={props.category}
                            description={props.description}
                            url={props.landingUrl}
                            isPromoted={checkedPromoted}
                            isNew={checkedNew} >
                        </Card>
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checkedPromoted}
                                        onChange={handleChangeCheckedRemove}
                                        color="primary"
                                    />
                                }
                                label="Previsualizar con marca de promoción"
                            />
                        </div>
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checkedNew}
                                        onChange={handleChangeCheckedNew}
                                        color="primary"
                                    />
                                }
                                label="Previsualizar con marca de novedad"
                            />
                        </div>
                        <Grid container spacing={1}>
                            <Grid item xs={8}>
                            </Grid>
                            <Grid item xs={1}>
                                <Button variant="contained" aria-describedby={id} type="button" onClick={props.handleClose}>
                                    Cerrar
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Draggable>
            </Popper>
        </div>
    );
}