import React, { Component, Fragment } from 'react';
import QueryBuilder from "react-querybuilder";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { AlertDialog } from './AlertDialog';

export class RulesQueryBuilder extends Component {

    constructor(props) {
        super(props);
        this.state = { openAttributesAlert: false };
        this.getAddRuleAction = this.getAddRuleAction.bind(this);
        this.handleCloseAlert = this.handleCloseAlert.bind(this);
    }

    getValueEditorType = (field, operator) => {
        var attribute = this.props.fields.find(function (element) {
            return element.name === field;
        });

        if (attribute) {
            var attributeType = this.props.fieldTypes.find(function (element) {
                return element.uuid === attribute.attribute_type_uuid;
            });

            if (attributeType) {
                if (attributeType.is_enum) {
                    return 'select';
                }

                if (attributeType.name === 'Booleano') {
                    return 'checkbox';
                }
            }
        }

        return 'text';
    }

    getInputType = (field, operator) => {
        var attribute = this.props.fields.find(function (element) {
            return element.name === field;
        });

        if (attribute) {
            var attributeType = this.props.fieldTypes.find(function (element) {
                return element.uuid === attribute.attribute_type_uuid;
            });

            if (attributeType && attributeType.name === 'Número entero') {
                return 'number';
            }
        }

        return 'text';
    }

    getValues = (field, operator) => {
        var attribute = this.props.fields.find(function (element) {
            return element.name === field;
        });

        if (attribute) {
            var attributeType = this.props.fieldTypes.find(function (element) {
                return element.uuid === attribute.attribute_type_uuid;
            });

            if (attributeType) {
                if (attributeType.is_enum) {
                    return attributeType.values.map(value => ({
                        name: value.value,
                        label: value.value,
                        value: value.value
                    }));
                }
            }
        }
        return [];
    };

    getValueEditor = ({ operator, value, handleOnChange, title, className, type, inputType, values }) => {

        if (operator === 'null' || operator === 'notNull') {
            return null;
        }

        switch (type) {
            case 'select':
                return (
                    <TextField
                        style={{ minWidth: 270 }}
                        select
                        variant="outlined"
                        margin="dense"
                        value={value}
                        onChange={(e) => handleOnChange(e.target.value)}
                    >
                        {values.map((v, index) => (
                            <MenuItem value={v.name}>{v.label}</MenuItem>
                        ))}
                    </TextField>
                );

            case 'checkbox':
                return (
                    <Checkbox
                        style={{ paddingRight: 237 }}
                        checked={!!value}
                        onChange={(e) => handleOnChange(e.target.checked)}
                        color="primary"
                        inputProps={{
                            'aria-label': 'secondary checkbox'
                        }}
                    />
                );

            case 'radio':
                return (
                    <span className={className} style={{ minWidth: 270 }}>
                        {values.map((v) => (
                            <label key={v.name}>
                                <input
                                    type="radio"
                                    value={v.name}
                                    checked={value === v.name}
                                    onChange={(e) => handleOnChange(e.target.value)}
                                />
                                {v.label}
                            </label>
                        ))}
                    </span>
                );

            default:
                if (inputType === "number") {
                    return (
                        <TextField
                            style={{ minWidth: 270 }}
                            type="number"
                            inputProps={{ min: "0", max: "9999999999999" }}
                            variant="outlined"
                            margin="dense"
                            value={value}
                            onChange={(e) => handleOnChange(e.target.value)}
                            reg
                        >
                        </TextField>
                    );
                }
                return (
                    <TextField
                        style={{ minWidth: 270 }}
                        type={inputType || 'text'}
                        variant="outlined"
                        margin="dense"
                        value={value}
                        onChange={(e) => handleOnChange(e.target.value)}
                        reg
                    >
                    </TextField>
                );
        }
    };

    getOperatorSelector = ({ options, value, className, handleOnChange }) => {
        return (
            <TextField
                select
                style={{ paddingRight: 5, minWidth: 120 }}
                variant="outlined"
                margin="dense"
                value={value}
                onChange={(e) => handleOnChange(e.target.value)}
            >
                {options.map((v, index) => (
                    <MenuItem value={v.name}>{v.label}</MenuItem>
                ))}
            </TextField>
        );
    }

    getCombinatorSelector = ({ options, value, className, handleOnChange, rules, level }) => {
        options = options.filter((opt) => (level === 0 && opt.name === "&&") || (level === 1 && opt.name === "||"));
        value = options[0].name;

        return (

            options.map((v, index) => (
                <span className="spanCombinator">{v.label}</span>
            ))

        );
    }

    getFieldSelector = ({ options, value, className, handleOnChange }) => {

        var error = this.props.fields.find(function (element) {
            return element.name === value;
        }) ===
            undefined;

        return (
            <TextField
                style={{ paddingRight: 5, minWidth: 300 }}
                select
                variant="outlined"
                margin="dense"
                value={value}
                error={error}
                helperText={error ? `El atributo ${value} ha sido borrado o cambiado de scope, elimine esta regla o cambie de atributo.` : ``}
                onChange={(e) => handleOnChange(e.target.value)}
            >
                {options.map((v, index) => (
                    <MenuItem value={v.name}>{v.label}</MenuItem>
                ))}
            </TextField>
        );
    }

    getRemoveRuleAction = ({ label, className, handleOnClick }) => {
        return (
            <IconButton edge="end" aria-label="delete" onClick={handleOnClick} >
                <DeleteIcon />
            </IconButton>
        );
    }

    getOperators = (field) => {
        var attribute = this.props.fields.find(function (element) {
            return element.name === field;
        });

        if (attribute) {
            var attributeType = this.props.fieldTypes.find(function (element) {
                return element.uuid === attribute.attribute_type_uuid;
            });

            if (attributeType) {
                if (attributeType.name === "Número entero") {
                    return [
                        { name: '==', label: 'Es igual' }, { name: '!=', label: 'No es igual' }, { name: '>', label: 'Es mayor' }, { name: '<', label: 'Es menor' }, { name: '>=', label: 'Es mayor o igual' }, { name: '<=', label: 'Es menor o igual' }
                    ];
                } else if (attributeType.name === "Booleano") {
                    return [{ name: '==', label: 'Es igual' }];
                }
            }
        }

        return [{ name: '==', label: 'Es igual' }, { name: '!=', label: 'No es igual' }];
    }

    getAddRuleAction = ({ label, className, handleOnClick, rules, level }) => {

        var action = (e) => {
            if (!this.props.fields || this.props.fields.length <= 0) {
                this.setState({ openAttributesAlert: true });
                return;
            }

            handleOnClick(e);
        }

        return (
            <Fragment>
                <button className={className} onClick={action}>
                    {label}
                </button>
                <AlertDialog
                    open={this.state.openAttributesAlert}
                    title="Formulario de reglas no disponible"
                    text="No se pueden crear reglas porque no hay atributos disponibles."
                    handleClose={this.handleCloseAlert}
                />
            </Fragment>
        )
    }

    handleCloseAlert = () => {
        this.setState({ openAttributesAlert: false });
    }

    render() {
        return (
            <QueryBuilder
                controlElements={{
                    addRuleAction: this.getAddRuleAction,
                    addGroupAction: this.getAddRuleAction,
                    valueEditor: this.getValueEditor,
                    operatorSelector: this.getOperatorSelector,
                    fieldSelector: this.getFieldSelector,
                    removeRuleAction: this.getRemoveRuleAction,
                    removeGroupAction: this.getRemoveRuleAction,
                    combinatorSelector: this.getCombinatorSelector,

                }}
                query={this.props.query}
                onQueryChange={this.props.onQueryChange}
                fields={this.props.fields}
                getOperators={this.getOperators}
                getValueEditorType={this.getValueEditorType}
                getInputType={this.getInputType}
                getValues={this.getValues}
                combinators={[{ name: '&&', label: 'Y' }, { name: '||', label: 'O' }]}
                showNotToggle={false}
                showCombinatorsBetweenRules={true}
                controlClassnames={
                    {
                        addRule: 'addrule roundedButton secondary',
                        removeRule: 'roundedButton secondary',
                        addGroup: 'roundedButton secondary',
                        combinators: 'display-none'
                    }
                }
                translations={
                    {
                        addRule: {
                            label: "+ Regla",
                            title: "Añadir regla"
                        },
                        removeRule: {
                            label: "x",
                            title: "Eliminar regla"
                        },
                        addGroup: {
                            label: "+ Grupo",
                            title: "Añadir grupo"
                        },
                        removeGroup: {
                            label: "x",
                            title: "Eliminar grupo"
                        }
                    }
                }
            />
        );
    }
}

export default function getValueProcessor(attributeTypesList, attributesList) {
    const valueProcessor = (field, operator, value) => {
        if (value === true) {
            return 'true';
        }
        if (value === false) {
            return 'false';
        }

        var attribute = attributesList.find(function (element) {
            return element.name === field;
        });

        var isNum = false;

        if (attribute) {
            var type = attributeTypesList.find(function (element) {
                return element.uuid === attribute.attribute_type_uuid;
            });

            if (type && type.name === 'Número entero') {
                isNum = true;
            }
        }

        if (operator === 'in') {
            // Assuming `value` is an array, such as from a multi-select
            return isNum ? `(${value.map((v) => `${v.trim()}`).join(',')})` : `(${value.map((v) => `"${v.trim()}"`).join(',')})`;
        } else {
            return isNum ? `${value.trim()}` : `"${value.trim()}"`;
        }
    };

    return valueProcessor;
}

export function queryContainsDuplicates(rules) {
    let counts = [];

    let i;
    for (i = 0; i < rules.length; i++) {
        if (rules[i].rules) {
            for (let j = 0; j < rules[i].rules.length; j++) {
                if (rules[i].rules[j].field) {
                    if (counts[rules[i].rules[j].field] === undefined) {
                        counts[rules[i].rules[j].field] = 1;
                    }
                }
            }
        }
    }

    for (i = 0; i < rules.length; i++) {
        if (rules[i].field) {
            if (counts[rules[i].field] === undefined) {
                counts[rules[i].field] = 1;
            } else {
                return true;
            }
        }
    }
    return false;
}

export function queryGroupsContainsDifferent(rules) {
    let counts = [];

    let i;
    for (i = 0; i < rules.length; i++) {
        if (rules[i].rules) {
            for (let j = 0; j < rules[i].rules.length; j++) {
                if (rules[i].rules[j].field) {
                    if (counts[i] === undefined) {
                        counts[i] = rules[i].rules[j].field;
                    }
                    else if (counts[i] !== rules[i].rules[j].field) {
                        return true;
                    }
                }
            }
        }
    }

    return false;
}