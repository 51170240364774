import React, { Fragment } from 'react';
import MaterialTable from 'material-table';
import Button from '../Button';
import handleResponse from '../.././functions/handleResponse';
import handleDiagnosis from '../.././functions/handleDiagnosis';
import { ConfirmDialog } from '.././ConfirmDialog';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Grid from '@material-ui/core/Grid';

const imputTextStyle = {
    width: '99%'
};

export class RelatedAddOns extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            source: { [props.target]: [] },
            selectRelatedSageLicensingModule: '',
            delete_sageLicensingModule: '',
            open: false
        };

        this.fetchData = this.fetchData.bind(this);
        this.setSource = this.setSource.bind(this);
        this.onAddButtonClick = this.onAddButtonClick.bind(this);
        this.onRelatedAddonDeteled = this.onRelatedAddonDeteled.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.delete = this.delete.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 3;

        fetch(`/api/${this.props.source}/${this.props.uuid}`,
            {
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, false))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.setSource(response))
            .catch(error => console.log(error));
    }

    setSource(source) {
        this.setState({ source: source });
    }

    onAddButtonClick() {
        if (this.state.selectRelatedSageLicensingModule) {
            this.props.handlerLoading(true);
            const accessToken = this.props.auth.getAccessToken();
            var setStatusOn200 = 0;

            fetch(`/api/${this.props.source}/${this.props.uuid}/${this.props.target}/${this.state.selectRelatedSageLicensingModule}`,
                {
                    method: 'POST',
                    headers: new Headers({
                        "Accept": "application/json",
                        "Authorization": `Bearer ${accessToken}`
                    })
                })
                .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, false))
                .then(response => !response.ok ? response.json() : '')
                .then(response => response.diagnosis
                    ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading)
                    : (this.fetchData(), this.props.handlerLoading(false)))
                .catch(error => console.log(error));
        }
    }

    delete = (sageLicensingModule) => {
        this.props.handlerLoading(true);
        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 2;

        fetch(`/api/${this.props.source}/${this.props.uuid}/${this.props.target}/${sageLicensingModule}`,
            {
                method: 'DELETE',
                headers: new Headers({
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, false))
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : (this.onRelatedAddonDeteled(), this.props.handlerLoading(false)))
            .catch(error => console.log(error));
    }

    onRelatedAddonDeteled() {
        this.setState({ delete_sageLicensingModule: '' });
        this.fetchData();
    }

    handleDelete = (uuid) => {
        this.setState({ delete_sageLicensingModule: uuid, open: true });
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    render() {
        return (
            <Fragment>
                <ValidatorForm
                    ref="form"
                    onSubmit={this.onAddButtonClick}
                    onError={errors => console.log(errors)}
                >
                    <Grid container spacing={1} style={{ paddingBottom: '10px' }}>
                        <Grid item xs={11} wrap="nowrap">
                            <TextValidator
                                style={imputTextStyle}
                                margin="dense"
                                variant="outlined"
                                label="Sage Lic."
                                name="selectRelatedSageLicensingModule"
                                value={this.state.selectRelatedSageLicensingModule}
                                onChange={this.handleChange}
                                validators={['required', 'maxStringLength: 64', 'trim', 'matchRegexp:^[a-zA-Z0-9]*$']}
                                errorMessages={['Este campo es obligatorio', 'La longitud máxima de este campo es de 64 caracteres', 'Este campo es obligatorio', 'Solo están permitidos caracteres alfanuméricos']}
                            />
                        </Grid>
                        <Grid item xs={1} wrap="nowrap">
                            <Button type="submit" variant="contained" color="primary" style={{ marginTop: '10px' }} className="selectbutton"> Añadir</Button>
                        </Grid>
                    </Grid>
                </ValidatorForm>
                <div class="row">
                    <div class="col col-12">
                        <MaterialTable
                            onRowClick={this.onTableRowClick}
                            title=""
                            columns={[
                                { title: 'Sage Lic.', field: 'module' }
                            ]}
                            data={this.state.source[this.props.target].map(module => ({
                                module: module
                            }))}
                            options={{
                                sorting: true,
                                pageSize: 20,
                                pageSizeOptions: [10, 20, 50, 100],
                                padding: 'dense',
                                emptyRowsWhenPaging: false,
                                actionsColumnIndex: -1,
                                searchFieldAlignment: "left",
                                actionsCellStyle: { borderRight: '0px', borderBottomStyle: 'none' },
                                headerStyle: {
                                    fontWeight: 'bold'
                                },
                                cellStyle: { borderRight: '0px', borderBottomStyle: 'none' }
                            }}
                            actions={[
                                rowData => ({
                                    icon: 'delete',
                                    tooltip: `Quitar AddOn ${this.props.targetText}`,
                                    onClick: (event, row) => this.handleDelete(row.module)
                                })
                            ]}
                            localization={{
                                header: {
                                    actions: "Acciones"
                                },
                                body: {
                                    emptyDataSourceMessage: 'No hay datos que mostrar'
                                },
                                toolbar: {
                                    searchTooltip: 'Buscar',
                                    searchPlaceholder: 'Buscar'
                                },
                                pagination: {
                                    labelRowsSelect: 'filas',
                                    labelDisplayedRows: ' {from}-{to} de {count}',
                                    firstTooltip: 'Primera',
                                    previousTooltip: 'Anterior',
                                    nextTooltip: 'Siguiente',
                                    lastTooltip: 'Última'
                                }
                            }}
                        />
                    </div>
                </div>
                <ConfirmDialog
                    open={this.state.open}
                    onAgree={() => this.delete(this.state.delete_sageLicensingModule)}
                    title="¿Deseas continuar?"
                    text={`Se va a quitar el AddOn ${this.props.targetText}, esta acción es irreversible, ¿desea continuar?`}
                    handleClose={this.handleClose}
                />
            </Fragment>
        )
    }
}