import React from 'react';
import Button from '../Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import handleResponse from '../.././functions/handleResponse';
import handleDiagnosis from '../.././functions/handleDiagnosis';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

export class CreateDialog extends React.Component {

    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.handleAgree = this.handleAgree.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.state =
            {
                newValue: {
                    name: ''
                }
            };
    }

    handleClose = () => {
        this.setState({ newValue: { name: '' } });
        this.props.handleClose();
    };

    handleAgree = () => {
        this.props.handlerLoading(true);
        this.props.handleClose();
        const accessToken = this.props.auth.getAccessToken();
        const self = this;
        var setStatusOn200 = 0;

        fetch(`/api/${this.props.entity.type}`,
            {
                method: 'POST',
                headers: new Headers({
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                }),
                body: JSON.stringify(self.state.newValue)
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, true))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.onAdded(response))
            .catch(error => console.log(error));
    };

    onAdded = (response) => {
        this.setState({ newValue: { name: '' } });
        this.props.handleOnAdded(response);
    };

    handleChange = (event) => {
        var newValue = {
            name: event.target.value
        }
        this.setState({ newValue: newValue });
    }

    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth="md"
                fullWidth={true}>
                <ValidatorForm
                    ref="form"
                    onSubmit={this.handleAgree}
                    onError={errors => console.log(errors)}
                >
                    <DialogContent>
                        <DialogContentText>
                            <h5>
                                Crear {this.props.entity.name}
                            </h5>
                        </DialogContentText>
                        <TextValidator
                            margin="dense"
                            variant="outlined"
                            label="Nombre"
                            name="name"
                            fullWidth={true}
                            value={this.state.newValue.name}
                            onChange={this.handleChange}
                            validators={['required', 'maxStringLength: 64', 'trim']}
                            errorMessages={['Este campo es obligatorio', 'La longitud máxima de este campo es de 64 caracteres', 'Este campo es obligatorio']}
                        />
                    </DialogContent>
                    <DialogActions>                        
                        <Button onClick={this.handleClose} variant="contained">
                            Cancelar
                        </Button>
                        <Button type="submit" variant="contained" color="primary">
                            Aceptar
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        )
    }
}