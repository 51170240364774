import React, { Fragment } from 'react';
import MaterialTable from 'material-table';
import Button from '../Button';
import handleResponse from '../.././functions/handleResponse';
import handleDiagnosis from '../.././functions/handleDiagnosis';
import { ConfirmDialog } from '.././ConfirmDialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { PageHeader } from '../PageHeader';

const imputTextStyle = {
    width: '95%'
};

export class DataListPage extends React.Component {

    constructor(context) {
        super();

        this.state =
            {
                tableData: [],
                delete_uuid: '',
                open: false,
                entity: {
                    type: 'categories',
                    name: 'Categorías'
                },
                newValue: {
                    name: ''
                },
                tableRef: React.createRef()
            };
        this.context = context;
        this.fetchData = this.fetchData.bind(this);
        this.setData = this.setData.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.delete = this.delete.bind(this);
        this.handleClose = this.handleClose.bind(true);
        this.handleEntityTypeChange = this.handleEntityTypeChange.bind(true);
        this.onRowUpdate = this.onRowUpdate.bind(true);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);        
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        this.props.handlerLoading(true);
        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 3;

        fetch(`/api/${this.state.entity.type}`,
            {
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, true))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.setData(response))
            .catch(error => console.log(error));
    }

    setData(data) {
        this.setState({ tableData: data });
    }

    handleDelete = (uuid) => {
        this.setState({ delete_uuid: uuid, open: true });
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    delete = (uuid) => {
        this.props.handlerLoading(true);
        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 2;

        fetch(`/api/${this.state.entity.type}/${uuid}`,
            {
                method: 'DELETE',
                headers: new Headers({
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, true))
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.fetchData(response))
            .catch(error => console.log(error));
    }

    handleEntityTypeChange = (event) => {
        this.state.tableRef.current.onChangePage(null, 0);
        var lastEdittingRow = this.state.tableRef.current.state.lastEditingRow;

        if (lastEdittingRow === undefined) {
            this.setState({
                entity: {
                    type: event.target.value,
                    name: event.target.name
                }
            }, this.fetchData);
        }
        else {
            this.props.setStatus("error", 'No se puede cambiar de opción mientras editas un registro.');
        }
    };

    handleChange = (event) => {
        var newValue = {
            name: event.target.value
        }
        this.setState({ newValue: newValue });
    }

    handleSubmit = (event) => {
        this.props.handlerLoading(true);
        var lastEdittingRow = this.state.tableRef.current.state.lastEditingRow;

        if (lastEdittingRow === undefined) {
            const accessToken = this.props.auth.getAccessToken();
            const self = this;
            var setStatusOn200 = 0;

            fetch(`/api/${this.state.entity.type}`,
                {
                    method: 'POST',
                    headers: new Headers({
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${accessToken}`
                    }),
                    body: JSON.stringify(self.state.newValue)
                })
                .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, true))
                .then(response => response.json())
                .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : (this.clearData(), this.fetchData()))
                .catch(error => console.log(error));
        }
        else {
            this.props.setStatus("error", 'No se pueden añadir ' + this.getSelectedOption() + ' mientras editas un registro.');
            this.props.handlerLoading(false);
        }
    }

    getSelectedOption = () => {
        switch (this.state.entity.type) {
            case 'providers':
                return "'Proveedores'";
            case 'collections':
                return "'Colecciones'";
            default:
                return "'Categorías'";
        }
    }

    clearData = () => {
        let newValue = this.state.newValue;
        newValue.name = '';
        this.setState({ newValue: newValue });
        this.refs.form.resetValidations();
    }

    onRowUpdate = (newData, oldData) => {
        this.props.handlerLoading(true);
        const accessToken = this.props.auth.getAccessToken();
        const self = this;
        var setStatusOn200 = 1;

        var object = {
            name: newData.name
        }

        return fetch(`/api/${self.state.entity.type}/${newData.uuid}`,
            {
                method: 'PUT',
                headers: new Headers({
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                }),
                body: JSON.stringify(object)
            }).then(response => handleResponse(response, self.props.handlerLoading, self.props.setStatus, self.props.auth, setStatusOn200, true))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, self.props.setStatus, self.props.handlerLoading) : self.fetchData())
            .catch(error => console.log(error));
    }

    render() {
        return (
            <Fragment>
                <PageHeader title="Tablas maestras" text="Desde esta página se administran las categorías, proveedores y colecciones" />
                <div className="content">
                    <div class="row pt-3">
                        <div class="col col-12">
                            <FormControl component="fieldset">
                                <RadioGroup aria-label="Tipo de dato" name="entityType" value={this.state.entity.type} onChange={this.handleEntityTypeChange} row>
                                    <FormControlLabel value="categories" name="Categorías" control={<Radio color="primary" />} label="Categorías" />
                                    <FormControlLabel value="providers" name="Proveedores" control={<Radio color="primary" />} label="Proveedores" />
                                    <FormControlLabel value="collections" name="Colecciones" control={<Radio color="primary" />} label="Colecciones" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-12">
                            <h4>{this.state.entity.name}</h4>
                            <ValidatorForm
                                ref="form"
                                onSubmit={this.handleSubmit}
                                onError={errors => console.log(errors)}
                            >
                                <Grid container spacing={1}>
                                    <Grid item xs={5}>
                                        <TextValidator
                                            style={imputTextStyle}
                                            margin="dense"
                                            variant="outlined"
                                            label="Nombre"
                                            name="name"
                                            value={this.state.newValue.name}
                                            onChange={this.handleChange}
                                            validators={['required', 'maxStringLength: 64', 'trim']}
                                            errorMessages={['Este campo es obligatorio', 'La longitud máxima de este campo es de 64 caracteres', 'Este campo es obligatorio']}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Button type="submit" variant="contained" color="primary" style={{ marginTop: '9px' }}>
                                            <AddIcon />
                                        </Button>
                                    </Grid>

                                </Grid>
                            </ValidatorForm>
                            <MaterialTable
                                title=""
                                tableRef={this.state.tableRef}
                                columns={
                                    [
                                        {
                                            title: 'Nombre', field: 'name', cellStyle: { width: "95%", borderRight: '0px', borderBottomStyle: 'none' },
                                            editComponent: props => (
                                                <TextField
                                                    inputProps={{
                                                        maxLength: '64'
                                                    }}
                                                    id="outlined-name"
                                                    label="Nombre"
                                                    value={props.value}
                                                    margin="dense"
                                                    variant="outlined"
                                                    onChange={e => props.onChange(e.target.value)}
                                                    style={{ width: '90%' }}
                                                />
                                            )
                                        },
                                        {
                                            title: 'ID', field: 'uuid', cellStyle: { width: "5%", borderRight: '0px', borderBottomStyle: 'none' }, hidden: this.state.entity.type !== "collections", readOnly: true,
                                            editComponent: props => (
                                                <p>{props.value}</p>
                                            )
                                        }
                                    ]}
                                data={this.state.tableData}
                                options={{
                                    sorting: true,
                                    pageSize: 20,
                                    pageSizeOptions: [10, 20, 50, 100],
                                    padding: 'dense',
                                    emptyRowsWhenPaging: false,
                                    actionsColumnIndex: -1,
                                    searchFieldAlignment: "left",
                                    actionsCellStyle: { borderRight: '0px', borderBottomStyle: 'none' },
                                    headerStyle: {
                                        fontWeight: 'bold'
                                    },
                                    cellStyle: { borderRight: '0px', borderBottomStyle: 'none' }
                                }}
                                localization={{
                                    header: {
                                        actions: 'Acciones'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'No hay datos que mostrar',
                                        editTooltip: 'Editar',
                                        editRow: {
                                            saveTooltip: 'Guardar',
                                            cancelTooltip: 'Cancelar',
                                            deleteText: 'Eliminar'
                                        }
                                    },
                                    toolbar: {
                                        searchTooltip: 'Buscar',
                                        searchPlaceholder: 'Buscar'
                                    },
                                    pagination: {
                                        labelRowsSelect: 'filas',
                                        labelDisplayedRows: ' {from}-{to} de {count}',
                                        firstTooltip: 'Primera',
                                        previousTooltip: 'Anterior',
                                        nextTooltip: 'Siguiente',
                                        lastTooltip: 'Última'
                                    }
                                }}
                                editable={{
                                    onRowUpdate: this.onRowUpdate
                                }}
                                actions={[
                                    rowData => ({
                                        icon: 'delete',
                                        tooltip: 'Borrar',
                                        onClick: (event, row) => this.handleDelete(row.uuid),
                                        disabled: rowData.is_removable !== undefined && !rowData.is_removable
                                    })
                                ]}
                            />

                        </div>
                    </div>
                </div>
                <ConfirmDialog
                    open={this.state.open}
                    onAgree={() => this.delete(this.state.delete_uuid)}
                    title="¿Deseas continuar?"
                    text="Se va a eliminar el registro. Esta acción es irreversible ¿deseas continuar?"
                    handleClose={this.handleClose}
                />
            </Fragment>
        )
    }
}