import React, { Fragment } from 'react';
import handleResponse from '../.././functions/handleResponse'
import handleDiagnosis from '../.././functions/handleDiagnosis'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Button from '../Button';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const imputTextStyle = {
    width: '90%'
};

const labelStyle = {
    marginTop: 15
};

export class CreateAgreementForm extends React.Component {

    constructor(props) {
        super(props);
        this.fetchData = this.fetchData.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onAdded = this.onAdded.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onContentStateChange = this.onContentStateChange.bind(this);

        this.state = {
            agreement: {
                uuid: '',
                title: '',
                text: '',
                days: 0,
                enabled: true
            },
            editorState: EditorState.createEmpty()
        }

        this.onEditorStateChange = (editorState) => {
            this.setState({ editorState: editorState });
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        this.props.handlerLoading(true);

        if (this.props.uuid) {
            const accessToken = this.props.auth.getAccessToken();
            
            var setStatusOn200 = 3;

            fetch("/api/agreements/" + this.props.uuid,
                {
                    headers: new Headers({
                        "Accept": "application/json",
                        "Authorization": `Bearer ${accessToken}`
                    })
                })
                .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, true))
                .then(response => response.json())
                .then(response => response.diagnosis
                    ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading)
                    : this.setData(response))
                .catch(error => console.log(error));
        }
        else
            this.props.handlerLoading(false);
    }

    setData = (agreement) => {
        const htmlDesc = decodeURIComponent(agreement.text);
        const contentBlock = htmlToDraft(htmlDesc);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);

            this.setState({ agreement: agreement, editorState: editorState });
        }

        this.setState({ agreement: agreement });        
    }

    handleSubmit = (event) => {
        this.props.handlerLoading(true);

        const accessToken = this.props.auth.getAccessToken();
        const self = this;

        if (self.state.editorState.getCurrentContent().getPlainText().trim().length === 0) {
            self.props.setStatus("error", 'Es necesario indicar el texto del acuerdo de cliente');
            this.props.handlerLoading(false);
            return false;
        }

        var method = 'POST';
        var url = '/api/agreements';
        var setStatusOn200 = 0;

        if (self.state.agreement.uuid && self.state.agreement.uuid !== '' && self.state.agreement.uuid !== null) {
            method = 'PUT';
            url = '/api/agreements/' + self.state.agreement.uuid;
            setStatusOn200 = 1;
        }

        fetch(url,
            {
                method: method,
                headers: new Headers({
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                }),
                body: JSON.stringify(self.state.agreement)
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, true))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.onAdded(response))
            .catch(error => console.log(error));
    }

    onAdded = (agreement) => {
        this.props.history.push('/agreements');
    }

    handleChange = (event) => {
        let agreement = this.state.agreement;
        var newValue;
        if (event.target.name === "enabled") {
            newValue = event.target.checked;
        } else {
            newValue = event.target.value;
        }


        agreement[event.target.name] = newValue;
        this.setState({ agreement: agreement });
    }

    onContentStateChange = (contentState) => {
        var text = draftToHtml(contentState);
        let agreement = this.state.agreement;
        agreement.text = encodeURIComponent(text);
        this.setState({ agreement: agreement });
    };

    handlePastedText = (text, html) => {
        return false;
    }

    toolbarOptions = {
        options: ['inline', 'fontSize', 'list', 'textAlign', 'link', 'history'],
        inline: {
            options: ['bold', 'italic', 'underline']
        },
        list: {
            options: ['unordered', 'ordered']
        }
    }

    render() {
        return (
            <Fragment>
                <div className="content">
                    <ValidatorForm
                        ref="form"
                        onSubmit={this.handleSubmit}
                        onError={errors => console.log(errors)}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={2} wrap="nowrap">
                                <label style={labelStyle}>Título</label>
                            </Grid>
                            <Grid item xs={10}>
                                <TextValidator
                                    style={imputTextStyle}
                                    margin="dense"
                                    variant="outlined"
                                    label="Título"
                                    name="title"
                                    value={this.state.agreement.title}
                                    onChange={this.handleChange}
                                    validators={['required', 'maxStringLength: 32', 'trim']}
                                    errorMessages={['Este campo es obligatorio', 'La longitud máxima de este campo es de 32 caracteres', 'Este campo es obligatorio']}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <label style={labelStyle}>Texto</label>
                            </Grid>
                            <Grid item xs={9}>
                                <Editor
                                    editorState={this.state.editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={this.onEditorStateChange}
                                    onContentStateChange={this.onContentStateChange}
                                    toolbar={this.toolbarOptions}
                                    handlePastedText={this.handlePastedText}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid container spacing={1}>
                                <Grid item xs={2} wrap="nowrap">
                                    <label style={labelStyle}>Activo</label>
                                </Grid>
                                <Grid item xs={10}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.agreement.enabled}
                                                onChange={this.handleChange}
                                                value="enabled"
                                                name="enabled"
                                                color="primary"
                                            />
                                        }
                                        label="Si esta casilla está marcada será un acuerdo de cliente activo"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Fragment>
                            <Grid container spacing={1}>
                                <Grid item xs={10}>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button type="submit" variant="contained" color="primary"> Guardar</Button>
                                </Grid>
                            </Grid>
                        </Fragment >
                    </ValidatorForm>
                </div>
            </Fragment >
        );
    }

}