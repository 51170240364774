import React, { Fragment } from 'react';
import { CreateScreenForm } from './CreateScreenForm';
import { matchPath } from 'react-router';
import { PageHeader } from '../PageHeader';

export class UpdateScreenPage extends React.Component {

    getParams = pathname => {
        const matchProfile = matchPath(pathname, {
            path: `/screens/update/:screenUuid`
        });
        return (matchProfile && matchProfile.params) || {};
    };

    render() {
        var params = this.getParams(this.props.history.location.pathname);
        return (
            <Fragment>
                <PageHeader title="Modificar pantalla cero" text="Esta página permite modificar una pantalla cero" />
                <p>
                    <CreateScreenForm uuid={params.screenUuid} auth={this.props.auth} handlerLoading={this.props.handlerLoading} setStatus={this.props.setStatus} history={this.props.history}></CreateScreenForm>
                </p>
            </Fragment>
        );
    }
}