import React, { Fragment } from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import Button from '../Button';
import handleResponse from '../.././functions/handleResponse';
import handleDiagnosis from '../.././functions/handleDiagnosis';
import { ConfirmDialog } from '.././ConfirmDialog';
import { PageHeader } from '../PageHeader';

export class SimulationsListPage extends React.Component {
    constructor(context) {
        super();

        this.state = {
            simulationsList: [],
            delete_uuid: '',
            open: false
        };
        this.context = context;
        this.fetchData = this.fetchData.bind(this);
        this.setData = this.setData.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.delete = this.delete.bind(this);
        this.handleClose = this.handleClose.bind(true);
        this.onTableRowClick = this.onTableRowClick.bind(this);
        this.handleLoadSimulation = this.handleLoadSimulation.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        this.props.handlerLoading(true);
        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 3;

        fetch("/api/simulations",
            {
                headers: new Headers({
                    "Accept": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, true))
            .then(response => response.json())
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.setData(response))
            .catch(error => console.log(error));
    }

    setData(simulations) {
        this.setState({ simulationsList: simulations });
    }

    handleDelete = (uuid) => {
        this.setState({ delete_uuid: uuid, open: true });
    }

    handleClose = () => {
        this.setState({ open: false });
    }

    handleLoadSimulation = (data) => {
        var simulationAddress = data.panel_base_address + '?simulationId=' + data.uuid

        this.openInNewTab(simulationAddress); 
    }

    openInNewTab(href) {
        Object.assign(document.createElement('a'), {
            target: '_blank',
            href,
        }).click();
    }

    delete = (uuid) => {
        this.props.handlerLoading(true);
        const accessToken = this.props.auth.getAccessToken();
        var setStatusOn200 = 2;

        fetch('/api/simulations/' + uuid,
            {
                method: 'DELETE',
                headers: new Headers({
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                })
            })
            .then(response => handleResponse(response, this.props.handlerLoading, this.props.setStatus, this.props.auth, setStatusOn200, true))
            .then(response => !response.ok ? response.json() : '')
            .then(response => response.diagnosis ? handleDiagnosis(response, this.props.setStatus, this.props.handlerLoading) : this.fetchData(response))
            .catch(error => console.log(error));
    }

    onTableRowClick(event, rowData) {
        this.props.history.push('/simulations/update/' + rowData.uuid);
    }

    render() {
        return <Fragment>
            <PageHeader title="Simulaciones" text="Esta página muestra las simulaciones creadas" />
            <div className="content">
                <div className="row">
                    <div className="col col-12">
                        <MaterialTable
                            onRowClick={this.onTableRowClick}
                            title=""
                            columns={[
                                {
                                    title: 'Nombre', field: 'name'
                                },
                            ]}
                            data={this.state.simulationsList}
                            options={{
                                sorting: true,
                                pageSize: 20,
                                pageSizeOptions: [10, 20, 50, 100],
                                padding: 'dense',
                                emptyRowsWhenPaging: false,
                                actionsColumnIndex: -1,
                                searchFieldAlignment: "left",
                                actionsCellStyle: { borderRight: '0px', borderBottomStyle: 'none' },
                                headerStyle: {
                                    fontWeight: 'bold'
                                },
                                cellStyle: { borderRight: '0px', borderBottomStyle: 'none' }
                            }}
                            actions={[
                                {
                                    icon: 'delete',
                                    tooltip: 'Borrar simulación',
                                    onClick: (event, rowData) => this.handleDelete(rowData.uuid)
                                },
                                {
                                    icon: 'visibility',
                                    tooltip: 'Comprobar simulación',
                                    onClick: (event, rowData) => this.handleLoadSimulation(rowData)
                                }
                            ]}
                            localization={{
                                header: {
                                    actions: 'Acciones'
                                },
                                body: {
                                    emptyDataSourceMessage: 'No hay datos que mostrar'
                                },
                                toolbar: {
                                    searchTooltip: 'Buscar',
                                    searchPlaceholder: 'Buscar'
                                },
                                pagination: {
                                    labelRowsSelect: 'filas',
                                    labelDisplayedRows: ' {from}-{to} de {count}',
                                    firstTooltip: 'Primera',
                                    previousTooltip: 'Anterior',
                                    nextTooltip: 'Siguiente',
                                    lastTooltip: 'Última'
                                }
                            }}
                            components={{
                                Toolbar: props => (
                                    <div className="row">
                                        <div className="col col-1-auto">
                                            <MTableToolbar {...props} style={{ paddingLeft: 0 }} />
                                        </div>
                                        <div style={{ marginTop: 12, marginRight: 17 }}>
                                            <Button variant="contained" color="primary" onClick={() => { this.props.history.push('/simulations/add'); }}>
                                                Añadir
                                            </Button>
                                        </div>
                                    </div>
                                ),
                            }}
                        />
                    </div>
                </div>
            </div>
            <ConfirmDialog
                open={this.state.open}
                onAgree={() => this.delete(this.state.delete_uuid)}
                title="¿Deseas continuar?"
                text="Se va a eliminar la simulación. Esta acción es irreversible ¿deseas continuar?"
                handleClose={this.handleClose}
            />
        </Fragment>
    }
}