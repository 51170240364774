import React, { Fragment } from 'react';
import IconButton from '@material-ui/core/IconButton';
import LogoutIcon from '@material-ui/icons/ExitToApp';

export class LogoutButton extends React.Component {
    render() {
        let button = null;

        if (this.props.auth.isAuthenticated()) {
            button =
                <Fragment>
                    <span style={{ marginLeft: '5px', marginRight: '5px' }}>{this.props.auth.getProfile().userName}</span>
                    <IconButton edge="end" aria-label="delete" onClick={() => this.props.auth.logout()} >
                        <LogoutIcon style={{ color: '#FFF' }} />
                    </IconButton>
                </Fragment>
        }
        return button;
    }
}